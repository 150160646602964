app.directive('simpleMarkdownToHtml', ['$sanitize', '$sce',
  function ($sanitize, $sce) {
    return {
      replace: true,
      restrict: 'A',
      link: function (scope, element, attrs) {

        scope.$watch('model', function (newValue) {
          var showdownHTML;
          if (typeof newValue !== 'string') {
            return;
          }

          function parseInside(txt, left, right) {
            return left + txt + right;
          }

          var text = angular.element('<div/>').text(newValue).html();

          text = text.replace(/___(\S[\s\S]*?)___/g, function (wm, m) {
            return (/\S$/.test(m)) ? '<u>' + m + '</u>' : wm;
          });
          text = text.replace(/__(\S[\s\S]*?)__/g, function (wm, m) {
            return (/\S$/.test(m)) ? '<u>' + m + '</u>' : wm;
          });

          text = text.replace(/___(\S[\s\S]*?)___/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<strong><em>', '</em></strong>') : wm;
          });
          text = text.replace(/__(\S[\s\S]*?)__/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<strong>', '</strong>') : wm;
          });
          text = text.replace(/_([^\s_][\s\S]*?)_/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<em>', '</em>') : wm;
          });

          text = text.replace(/\*\*\*(\S[\s\S]*?)\*\*\*/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<strong><em>', '</em></strong>') : wm;
          });
          text = text.replace(/\*\*(\S[\s\S]*?)\*\*/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<strong>', '</strong>') : wm;
          });
          text = text.replace(/\*([^\s*][\s\S]*?)\*/g, function (wm, m) {
            return (/\S$/.test(m)) ? parseInside(m, '<em>', '</em>') : wm;
          });

          text = text.replace(/\r/g, '').replace(/\n/g, '<br />');
          scope.trustedHtml = text;
        });
      },
      scope: {
        model: '=simpleMarkdownToHtml'
      },
      template: '<div ng-bind-html="trustedHtml"></div>'
    };
  }]
);

