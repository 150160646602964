app.service('creditCalculationsService', [ 'CONSTANTS', 'portalHelper',
  function (CONSTANTS, portalHelper) {
    var service = this;

    service.calculate_charges = function (fieldingCharges, waiveMinCharges) {
      var qdPercentage = 0.15;
      var bmPercentage = 0.10;
      var irPercentage = 0.25;

      var result = {
        min_charges: {
          question_development: CONSTANTS.minCharges.QUESTION_DEVELOPMENT,
          background_material: CONSTANTS.minCharges.BACKGROUND_MATERIAL_DEVELOPMENT,
          insight_report: CONSTANTS.minCharges.INSIGHT_REPORT
        },
        total_charges: {
          question_development: 0,
          background_material: 0,
          insight_report: 0,
          total: 0
        },
        remainder_charges: {
          question_development: 0
        }
      };

      if (waiveMinCharges == true) {
        result.min_charges.question_development = 0;
        result.min_charges.background_material = 0;
        result.min_charges.insight_report = 0;
      }

      result.total_charges.question_development =
        Math.max(
          portalHelper.bankersRounding(fieldingCharges * qdPercentage, 0),
          result.min_charges.question_development);

      result.remainder_charges.question_development =
        Math.max((result.total_charges.question_development - result.min_charges.question_development), 0);

      result.total_charges.background_material =
        Math.max(
          portalHelper.bankersRounding(fieldingCharges * bmPercentage, 0),
          result.min_charges.background_material);

      result.total_charges.insight_report =
        Math.max(
          portalHelper.bankersRounding(fieldingCharges * irPercentage, 0),
          result.min_charges.insight_report);

      result.total_charges.total = fieldingCharges +
        result.total_charges.question_development +
        result.total_charges.background_material +
        result.total_charges.insight_report;

      return result;
    };

    service.calculate_charges_based_on_flags = function (survey_details) {
      let result = service.calculate_charges(survey_details.fielding_charge, survey_details.waive_minimum_charges);

      let total = survey_details.fielding_charge;
      if (survey_details.question_development) {
        total += result.total_charges.question_development;
      }

      if (survey_details.prepare_background) {
        total += result.total_charges.background_material;
      }

      if (survey_details.share_report) {
        total += result.total_charges.insight_report;
      }

      return total;
    };
    service.calculate_additional_charges = function (manualAdjustments) {
      if (manualAdjustments == null || manualAdjustments.length == 0) {
        return 0;
      }

      return _.reduce(
        manualAdjustments,
        function(memo, charge) {
          return memo + charge.amount; }, 0);
    };

    service.calculate_delivered_charges = function (milestones) {
      var deliveredMilestone = _.findWhere(milestones,
        { code_name: CONSTANTS.surveyMilestones.DELIVERED });

      if (deliveredMilestone != undefined &&
        deliveredMilestone.charges != null &&
        deliveredMilestone.charges.length > 0) {

        return _.reduce(
          deliveredMilestone.charges,
          function (memo, charge) {
            return memo + charge.committed_amount;
          }, 0);
      }

      return 0;
    };
  }]);
