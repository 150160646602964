app.service('creditsHelper', ['CONSTANTS', '$ngConfirm', 'portalHelper', '$filter', 'apiHelper',
  'HttpAuth', function (CONSTANTS, $ngConfirm, portalHelper, $filter, apiHelper, HttpAuth) {
    var creditsHelper = this;

    function validate_transfer_credits($scope) {
      $scope.error_list = [];
      var res = true;

      if ($scope.after_transfer_from < 0) {
        $scope.error_list.push('Please check amount');
        res = false;
      }

      if (!$scope.selected.transfer_to) {
        $scope.error_list.push('Please select a user');
        res = false;
      }

      if ($scope.selected.transfer_amount === '') {
        $scope.error_list.push('Please enter amount to transfer');
        res = false;
      } else if (!angular.isDefined($scope.selected.transfer_amount)) {
        $scope.error_list.push(
          'Amount to transfer: Ensure this value is valid integer greater than or equal to 1');
        res = false;
      }

      return res;
    }

    creditsHelper.calc_after_transfer2 = function ($scope, user_from, user_to, amount) {
      $scope.after_transfer_from = null;
      $scope.after_transfer_to = null;

      if (!amount) {
        amount = 0;
      }

      var u1 = $filter('filter')($scope.users, { 'id': user_from }, true);
      var u2 = $filter('filter')($scope.users, { 'id': user_to }, true);

      if (u1 && u1.length) {
        $scope.after_transfer_from = parseInt(u1[0].available_credits) - parseInt(amount);
      }

      if (u2 && u2.length) {
        $scope.after_transfer_to = parseInt(u2[0].available_credits) + parseInt(amount);
      }
    };
  }]
);
