app.filter('truncateString', function () {
  return function (input, length) {
    if (!input) {
      return '';
    }

    if (input.length < length) {
      return input;
    }

    return input.substr(0, length) + '...';
  };
});
