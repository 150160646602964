app.directive('leadingZeroes', ['portalHelper', function (portalHelper) {
  return {
    require: 'ngModel',
    scope: {
      numDigits: '='
    },
    link: function (scope, element, attrs, modelCtrl) {
      var fixValue = function (viewValue) {

        if (!viewValue) {
          return '';
        }

        var fixed = portalHelper.fixLeadingZero(viewValue, scope.numDigits);

        modelCtrl.$setViewValue(fixed);
        modelCtrl.$render();

        return fixed;
      };

      element.bind('blur', function () {
        if (element.val()) {
          scope.$apply(function () {
            fixValue(element.val());
          });
        }
      });
      modelCtrl.$formatters.push(fixValue);
    }
  };
}]);
