app.controller('redirectController',
  ['$scope', '$route', '$window', '$timeout', '$routeParams', '$location', 'authHelper',
    function ($scope, $route, $window, $timeout, $routeParams, $location, authHelper) {

      $timeout(function () {
        var target = $route.current.targetURL;

        if (!target) {
          if ($location.path().indexOf('/Administration') === 0 ||
            $location.path().indexOf('/Admin') === 0) {
            target = 'admin/#' + $location.path();
          } else if ($location.path().indexOf('/Client') === 0 ||
            $location.path().indexOf('/SurveyDoesNotExist') === 0) {
            target = 'client/#' + $location.path();
          } else if ($location.path().indexOf('/Payers') === 0) {
            target = 'payer/#' + $location.path();
          } else if ($location.path().indexOf('/open_document') === 0) {
            target = 'document/#' + $location.path();
          } else if ($location.path().indexOf('/Login') === 0 ||
            $location.path() === '/' ||
            $location.path() === '/#' ||
            $location.path() === '' ||
            $location.path().indexOf('/Login/Return') === 0) {
            target = 'login/#' + $location.path();
          } else if ($location.path() === '/Help' || $location.path() === '/FAQs') {
            authHelper.getUserTypeName().then(
              function (result) {
                var user_type = result.data.user_type_name;
                target = user_type + '/#' + $location.path();
                if ($routeParams.returnUrl) {
                  target = target.replace(':returnUrl',
                    encodeURIComponent(encodeURIComponent(decodeURIComponent($routeParams.returnUrl))));
                }
                $window.location.href = target;
              }
            );
          }
        }

        $window.location.href = target;

      }, 500);
    }]
);
