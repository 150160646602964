app.directive('aboutRpr', ['portalHelper', function (portalHelper) {
  return {
    scope: {},
    templateUrl: portalHelper.getUrlRev('html_templates/directives/about_rpr.dce1f082.html'),
    controller: function ($scope) {
      $scope.environment = ENVIRONMENT;
      $scope.ui_version = UI_VERSION;
      $scope.ui_build = UI_BUILD;
      $scope.api_version = API_VERSION;
      $scope.api_build = API_BUILD;
      $scope.deploy_datetime = DEPLOY_DATETIME;
    }
  };
}]);
