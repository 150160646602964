app.directive('bigButton', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      desc: '@',
      faIcon: '@',
      title: '@',
      url: '@',
      iconBg: '@'
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/big_button.16712e98.html'),
    controller: function ($scope, $sce) {
      $scope.$watch('title', function (value) {
        $scope.titleAsHtml = $sce.trustAsHtml(value);
      });
      $scope.$watch('desc', function (value) {
        $scope.descAsHtml = $sce.trustAsHtml(value);
      });
      $scope.goToDataUrl = function (url) {
        location.href = url;
      };
    }
  };
}]);
