app.directive('surveyResponsesSidebar',
  ['$location', '$ngConfirm', 'apiHelper', 'portalHelper', 'CONSTANTS',
    function ($location, $ngConfirm, apiHelper, portalHelper, CONSTANTS) {
      return {
        scope: {
          surveyDetails: '='
        },
        templateUrl: portalHelper.getUrlRev(
          'html_templates/directives/survey_responses_sidebar.97ee5796.html'),
        controller: function ($scope) {

          $scope.date_only_format = portalHelper.getDateOnlyFormat();
          $scope.api_url = apiHelper.getApiUrl();
          $scope.surveyStatuses = CONSTANTS.surveyStatuses;
          $scope.surveyStatusNames = portalHelper.surveyStatusNames;
          $scope.CONST = CONSTANTS;

          $scope.download_report = function (report_id) {
            portalHelper.downloadFileWithUrl(
              '/api/client/survey/report/' + report_id + '.json'
            );
          };

          $scope.clone_survey = function () {
            $ngConfirm({
              title: 'Are you sure?',
              content: 'Are you sure you wish to copy this survey along with all of its questions and configuration?',
              type: 'orange',
              buttons: {
                Yes: {
                  btnClass: 'mat ui primary button',
                  action: function () {
                    $location.path('Client/CloneSurvey/' + $scope.surveyDetails.id);
                    $scope.$apply();
                  }
                },
                Close: {
                  btnClass: 'mat ui critical button'
                }
              }
            });
          };
        }
      };
    }]
);

