app.service('dataHelper', ['$timeout', 'apiHelper', 'HttpAuth',
  function ($timeout, apiHelper, HttpAuth) {
    var dataHelperService = this;

    dataHelperService.createAssignmentsForPayers = function (survey_id, payers) {
      var payersData = payers.map(
        function (payer) {
          return {
            payer_id: payer.id,
            respondent_type_id: payer.respondent_types.length === 1 ?
              payer.respondent_types[0].id : parseInt(payer.respondent_type)
          };
        }
      );
      var data = { 'survey_id': survey_id, 'payers': payersData };
      var url = apiHelper.getApiUrl() + '/api/admin/survey/assign_payers.json';
      return HttpAuth.post(url, data);
    };

    dataHelperService.getOpenSurveysPopup = function (payers, cb_success, cb_error) {
      var queryString = '?payers=' + payers[0];

      for (var i = 1; i < payers.length; i++) {
        queryString = queryString + '&payers=' + payers[i];
      }

      var result = {};
      var url = apiHelper.getApiUrl() + '/api/admin/survey/open.json' + queryString;

      HttpAuth.get(url).then(function (result) {
        if (cb_success) {
          cb_success(result);
        }
      }, function (result) {
        if (cb_error) {
          cb_error(result);
        }
      });
    };

    dataHelperService.getOpenSurveys = function (cb_success, cb_error) {
      var result = {};
      var url = apiHelper.getApiUrl() + '/api/admin/survey/open.json';

      HttpAuth.get(url).then(function (result) {
        if (cb_success) {
          cb_success(result);
        }
      }, function (result) {
        if (cb_error) {
          cb_error(result);
        }
      });
    };
  }]);
