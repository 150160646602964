app.service('layoutService', ['HttpAuth', 'portalHelper', 'apiHelper', '$q', 'CONSTANTS',
  function (HttpAuth, portalHelper, apiHelper, $q, CONSTANTS) {

    var layoutService = this;

    this.reorderElements = function (countryId, layoutElements) {
      var data = _.map(layoutElements, function (val, index) {
        return { 'id': val.id, 'element_order': index };
      });

      data = { 'elements': data };
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/reorder/' + countryId + '.json';
      return HttpAuth.put(url, data).then(
        function (result) {
          return result.data;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
          return result;
        }
      );
    };

    this.insertElement = function (countryId, existingElements, newElement, newPosition, callback) {
      for (var i = newPosition; i < existingElements.length; i++) {
        if (existingElements[i].id == null) {
          existingElements.splice(i, 1);
          i--;
        } else {
          existingElements[i].element_order = i + 1;
        }
      }

      var layoutElems = _.map(existingElements, function (val) {
        return { 'id': val.id, 'element_order': val.element_order };
      });

      var data = { 'elements': layoutElems };
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/reorder/' + countryId + '.json';

      HttpAuth.put(url, data)
        .then(function (result) {
          newElement.element_order = newPosition;
          newElement.survey_country = countryId;
          newElement.visible = true;

          url = apiHelper.getApiUrl() + '/api/authoring/survey/surveycountry/layout/element.json';
          HttpAuth.post(url, newElement).then(
            function (res) {
              if (callback != null && typeof (callback) === 'function') {
                callback(res);
              }
            },
            function (result) {
              portalHelper.showErrorCommon(result);
            }
          );
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        });
    };

    this.setElementVisibility = function (layoutElement, visibility) {
      var data = { 'visible': visibility };
      var url = apiHelper.getApiUrl() + '/api/authoring/survey/surveycountry/layout/element/visibility/' + layoutElement.id + '.json';
      return HttpAuth.put(url, data).then(
        function (result) {
          result.data;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    this.deleteLayoutElement = function (layoutElement) {
      if (layoutElement.disabled === true) {
        return;
      }

      layoutElement.disabled = true;

      var url = apiHelper.getApiUrl() + '/api/authoring/survey/surveycountry/layout/element/' +
        layoutElement.id + '.json';

      return HttpAuth.delete(url).then(
        function (result) {
          return result;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    this.updateLayoutElement = function (layoutElement) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey/surveycountry/layout/element/' +
        layoutElement.id + '.json';

      return HttpAuth.put(url, layoutElement).then(
        function (result) {
          result.data;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    this.getLayoutElement = function (id) {
      var url = '/api/authoring/survey_country/layout/element/' + id + '.json';
    };

    this.createLayoutElement = function (data) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey/surveycountry/layout/element.json';

      return HttpAuth.post(url, data).then(
        function (result) {
          return result.data;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    this.listLayoutElements = function (surveyCountryId) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/' + surveyCountryId + '.json';
      return HttpAuth.get(url);
    };

    this.getLayout = function (surveyId, surveyCountryId) {
      var promises = [
        HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/survey/' + surveyId + '/section.json'),
        HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/survey/' + surveyId + '/question.json'),
        HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/' + surveyCountryId + '.json')
      ];

      return $q.all(promises).then(
        function (result) {
          var sections = result[0].data;
          var questions = result[1].data;
          var layoutElements = result[2].data;
          layoutElements = layoutService.attachElements(layoutElements, questions, sections);
          layoutElements = layoutService.populateQuestionPositions(layoutElements);
          return layoutElements;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    this.listLayoutElementsForAdmin = function (surveyCountryId) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/' + surveyCountryId + '.json';
      return HttpAuth.get(url);
    };

    this.listLayoutElementsForPayers = function (surveyId) {
      var url = apiHelper.getApiUrl() + '/api/payer/survey_country/layout/' + surveyId + '.json';
      return HttpAuth.get(url);
    };

    this.cloneLayout = function (sourceCountryId, destCountryId) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/clone/' + destCountryId + '.json';
      var data = { survey_country_pattern_id: sourceCountryId };

      return HttpAuth.put(url, data);
    };

    this.cloneLayoutToAllCountries = function (sourceCountryId) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey_country/layout/clone/to_all.json';
      var data = { survey_country_pattern_id: sourceCountryId };

      return HttpAuth.post(url, data);
    };

    this.attachElements = function (elements, questions, sections) {
      for (var i = 0; i < questions.length; i++) {
        for (var j = 0; j < elements.length; j++) {
          if (elements[j].question === questions[i].id) {
            elements[j].element = questions[i];
            break;
          }
        }
      }

      for (i = 0; i < sections.length; i++) {
        for (j = 0; j < elements.length; j++) {
          if (elements[j].section === sections[i].id) {
            elements[j].element = sections[i];
            break;
          }
        }
      }

      return elements;
    };

    this.findHiddenLayoutElements = function (layoutElements, sections, questions) {
      var hiddenLayoutElements = [];

      for (var i = 0; i < layoutElements.length; i++) {
        if (layoutElements[i].section) {
          for (var j = 0; j < sections.length; j++) {
            if (sections[j].id === layoutElements[i].section) {
              sections[j].presentInLayout = true;
            }
          }
        }

        if (layoutElements[i].question) {
          for (var k = 0; k < questions.length; k++) {
            if (questions[k].id === layoutElements[i].question) {
              questions[k].presentInLayout = true;
            }
          }
        }
      }

      for (j = 0; j < sections.length; j++) {
        if (!sections[j].presentInLayout) {
          hiddenLayoutElements.push({
            'element': sections[j],
            'section': sections[j].id,
            'question': null
          });
        } else {
          delete sections[j].presentInLayout;
        }
      }

      for (j = 0; j < questions.length; j++) {
        if (!questions[j].presentInLayout) {
          hiddenLayoutElements.push({
            'element': questions[j],
            'question': questions[j].id,
            'section': null
          });
        } else {
          delete questions[j].presentInLayout;
        }
      }

      return hiddenLayoutElements;
    };

    this.checkMandatoryFields = function (country) {
      var questionFound = false;
      country.exclamation = false;

      for (var i = 0; i < country.layoutElements.length; i++) {
        var le = country.layoutElements[i];

        if (le.question) {
          questionFound = true;
          break;
        }
      }

      if (!questionFound || !country.description) {
        country.exclamation = true;
      }
    };

    this.findOtherCountryTags = function (layoutElements, hiddenLayoutElements) {
      var cur_country_tags = {};
      var other_country_tags = {};

      for (var i = 0; i < layoutElements.length; i++) {
        var le = layoutElements[i];
        if (le.question && le.element.tag) {
          cur_country_tags[le.element.tag] = 1;
        }
      }

      for (i = 0; i < hiddenLayoutElements.length; i++) {
        le = hiddenLayoutElements[i];
        if (le.question && le.element.tag) {
          other_country_tags[le.element.tag] = 1;
        }
      }

      cur_country_tags = Object.keys(cur_country_tags);
      other_country_tags = Object.keys(other_country_tags);

      return other_country_tags.filter(function (x) {
        return cur_country_tags.indexOf(x) < 0;
      });
    };

    this.populateQuestionPositions = function (layoutElements) {
      var arr = [].concat(layoutElements);

      var quesPos = 1;
      arr.forEach(function (value) {
        if (value.question != null) {
          value.element.question_position = quesPos;
          quesPos++;
        }
      });

      return arr;
    };

    this.countQuestionsInLayout = function (layout) {
      var count = 0;

      for (var i = 0; i < layout.length; i++) {
        if (layout[i].question) {
          count++;
        }
      }

      return count;
    };

    this.removeFromAllLayouts = function (layoutElement) {
      var url = apiHelper.getApiUrl() + '/api/authoring/survey/layout/element/remove.json';
      var data = {};

      if (layoutElement.question) {
        data = { 'question_id': layoutElement.question };
      } else if (layoutElement.section) {
        if (typeof layoutElement.section === 'object') {
          data = { 'section_id': layoutElement.section.id };
        } else {
          data = { 'section_id': layoutElement.section };
        }
      }

      return HttpAuth.post(url, data).then(
        function (result) {
          portalHelper.showToast(CONSTANTS.typeOfToasts.SUCCESS,
            'Element removed from all layouts successfully.',
            'Success');
          return result.data;
        },
        function (result) {
          portalHelper.showErrorCommon(result);
          return result;
        }
      );
    };
  }]);
