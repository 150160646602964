app.service('apiHelper', ['SETTINGS', function (SETTINGS) {
  var apiHelperService = this;

  apiHelperService.getApiUrl = function () {
    if ((typeof SETTINGS.API_DOMAIN) == 'string' && (
      SETTINGS.API_DOMAIN.indexOf('127.0.0.1') == 0 ||
      SETTINGS.API_DOMAIN.indexOf('backend') == 0 ||
      SETTINGS.API_DOMAIN.indexOf('localhost') == 0 ||
      SETTINGS.API_DOMAIN.indexOf('local-api') == 0)) {
      var testApiServer = window.localStorage.getItem('testApiServer');
      if (testApiServer) {
        return testApiServer;
      }
    }
    return SETTINGS.API_SCHEME + '://' + SETTINGS.API_DOMAIN;
  };
}]);
