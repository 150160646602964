app.service('payerHelper',
  ['portalHelper', 'CONSTANTS', '$timeout', '$filter', '$rootScope', '$ngConfirm', '$location', 'persistentService',
    function (portalHelper, CONSTANTS, $timeout, $filter, $rootScope, $ngConfirm, $location, persistentService) {
      var payerHelper = this;

      payerHelper.startPopupHeartBeat = function (surveyId) {
        setInterval(function () {
          var expiryDate = new Date(new Date().getTime() + 2000);
          persistentService.saveLocalStorageObject(true, 'surveyPopupOpen_' + surveyId, expiryDate);
        }, 1000);
      };

      payerHelper.showSurveyPopup = function (surveyId, loadData) {

        if (payerHelper.popup) {
          payerHelper.popup.focus();
          return;
        }

        if (persistentService.getLocalStorageObject('surveyPopupOpen_' + surveyId) === true) {
          if (!payerHelper.popup) {
            portalHelper.showToast(CONSTANTS.typeOfToasts.ERROR,
              'This survey is already open in a popup window');
          }
          return;
        }

        angular.element('html, body').css({
          overflow: 'hidden',
          height: '100%'
        });

        $('.navbar-nav li a').css('z-index', '2');
        $('.menu-container').css('z-index', '2');
        $('.overlay').css('display', 'block');

        var popupWidth = $(window).width() * 0.85;
        var popupHeight = $(window).height() * 0.90;
        var options = 'toolbars=0, toolbar=no, location=no, scrollbars=yes, resizable=yes, top=150, left=150, width='
          + popupWidth + ', height=' + popupHeight;

        payerHelper.popup = window.open(($location.absUrl()
          .split('#')[0] + '#/Payers/SurveyDescription/' + surveyId + '?insidePopup=true'),
        'surveyPopup', options);
        var popupBlocked = portalHelper.handleBlockedPopup(payerHelper.popup, surveyId);
        if (popupBlocked !== true) {
          var expiryDate = new Date(new Date().getTime() + 10000);
          persistentService.saveLocalStorageObject(true, 'surveyPopupOpen_' + surveyId, expiryDate);
        }

        return $timeout(function () {

          payerHelper.popup.onunload = function (event) {

            persistentService.clearLocalStorageObject('surveyPopupOpen_' + surveyId);
            if (loadData) {
              loadData(
                function () {
                  payerHelper.popup = null;
                  angular.element('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                  });

                  $('.navbar-nav li a').css('z-index', 'auto');
                  $('.menu-container').css('z-index', '10');
                  $('.overlay').css('display', 'none');
                }
              );
            } else {
              location.reload();
            }
          };
        }, 1000);
      };

      payerHelper.payerTypes = {
        CURRENT_PAYER: 'current_payer',
        EX_PAYER: 'ex_payer',
        PAYER_ADVISOR: 'payer_advisor',
        PROXY_STAKEHOLDER: 'proxy_stakeholder',
        HEALTHCARE_PROVIDER: 'healthcare_provider',
        OTHER: 'other'
      };

      payerHelper.payerTypeNamePairs = [
        [payerHelper.payerTypes.CURRENT_PAYER, 'Current Stakeholder/Payer'],
        [payerHelper.payerTypes.EX_PAYER, 'Former Stakeholder/Payer'],
        [payerHelper.payerTypes.PAYER_ADVISOR, 'Stakeholder Advisor'],
        [payerHelper.payerTypes.PROXY_STAKEHOLDER, 'Proxy Stakeholder'],
        [payerHelper.payerTypes.HEALTHCARE_PROVIDER, 'Healthcare Provider'],
        [payerHelper.payerTypes.OTHER, 'Other'],
        ['','']
      ];

      payerHelper.payerArcheTypeGroups = {
        NATIONAL_PAYER: 'national_payer',
        REGIONAL_PAYER: 'regional_payer',
        LOCAL_PAYER: 'local_payer',
        PRIVATE_PAYER: 'private_payer',
        NITAG: 'nitag',
        NA: 'na'
      };

      payerHelper.payerArcheTypeGroupNamePairs = [
        [payerHelper.payerArcheTypeGroups.NATIONAL_PAYER, 'National Payer'],
        [payerHelper.payerArcheTypeGroups.REGIONAL_PAYER, 'Regional Payer'],
        [payerHelper.payerArcheTypeGroups.LOCAL_PAYER, 'Local Payer'],
        [payerHelper.payerArcheTypeGroups.PRIVATE_PAYER, 'Private Payer'],
        [payerHelper.payerArcheTypeGroups.NITAG, 'NiTAG'],
        [payerHelper.payerArcheTypeGroups.NA, 'N/A']
      ];

      payerHelper.payerDirectorTypes = {
        PHARMACY_DIRECTOR: 'pharmacy director',
        MEDICAL_DIRECTOR: 'medical director',
        OTHER_US: 'other us',
        NOT_APPLICABLE: ''
      };

      payerHelper.payerDirectorTypeNamePairs = [
        [payerHelper.payerDirectorTypes.PHARMACY_DIRECTOR, 'Pharmacy Director'],
        [payerHelper.payerDirectorTypes.MEDICAL_DIRECTOR, 'Medical Director'],
        [payerHelper.payerDirectorTypes.OTHER_US, 'Other US'],
        [payerHelper.payerDirectorTypes.NOT_APPLICABLE, 'N/A']
      ];

      payerHelper.payerSourceTypes = {
        REFERRAL: 'referral',
        CONFERENCE_LIST: 'conference_list',
        PRIMARY_RESEARCH: 'primary_research',
        MAT_WEBSITE: 'mat_website',
        BLANK: ''
      };

      payerHelper.payerSourceTypeNamePairs = {
        referral: 'Referral',
        conference_list: 'Conference list',
        primary_research: 'Primary Research',
        mat_website: 'MAT Website',
        '': ''
      };

      const PayerTypesUS = [
        [payerHelper.payerTypes.CURRENT_PAYER, 'Current Stakeholder/Payer'],
        [payerHelper.payerTypes.EX_PAYER, 'Former Stakeholder/Payer'],
        [payerHelper.payerTypes.OTHER, 'Other'],
        ['','']
      ];

      const PayerTypesNonUS = [
        [payerHelper.payerTypes.CURRENT_PAYER, 'Current Stakeholder/Payer'],
        [payerHelper.payerTypes.EX_PAYER, 'Former Stakeholder/Payer'],
        [payerHelper.payerTypes.PAYER_ADVISOR, 'Stakeholder Advisor'],
        [payerHelper.payerTypes.PROXY_STAKEHOLDER, 'Proxy Stakeholder'],
        ['','']
      ];

      payerHelper.get_payer_types = function (respondentTypes, countryOfExpertise) {
        if (respondentTypes.length) {
          if (respondentTypes.length == 1 && respondentTypes[0].name == 'KOL') {
            return [
              [payerHelper.payerTypes.HEALTHCARE_PROVIDER, 'Healthcare Provider']
            ];
          }

          if (countryOfExpertise) {
            if (countryOfExpertise.country_code == 'US') {
              return PayerTypesUS;
            } else {
              return PayerTypesNonUS;
            }
          }
        }
        return payerHelper.payerTypeNamePairs;
      };

      payerHelper.profileValidate = function ($scope, is_payer_portal) {
        is_payer_portal = portalHelper.fix_undefined(is_payer_portal);
        const isNotPayerPortal = !is_payer_portal;
        var result = true;
        $scope.error_list = [];

        if ($scope.payer.email === '') {
          $scope.error_list.push('Please enter email address');
          result = false;
        } else if (!angular.isDefined($scope.payer.email)) {
          $scope.error_list.push('Enter a valid email address.');
          result = false;
        }
        if ($scope.payer.linkedin_url){
          const pattern = /^https?:\/\/([\w-]+\.)?linkedin\.com\/([^?#]+)(\?.*)?$/;
          if (!pattern.test($scope.payer.linkedin_url)){
            $scope.error_list.push('Enter a valid LinkedIn URL.');
            result = false;
          }
        }

        if (!angular.isDefined($scope.payer.alternate_email)) {
          $scope.error_list.push('Enter a valid alternate email address.');
          result = false;
        }

        if (!$scope.payer.username) {
          $scope.error_list.push('Please enter username');
          result = false;
        }

        if (!$scope.payer.first_name) {
          $scope.error_list.push('Please enter first name');
          result = false;
        }

        if (!$scope.payer.last_name) {
          $scope.error_list.push('Please enter last name');
          result = false;
        }

        if (!$scope.payer.job_title) {
          $scope.error_list.push('Please enter Current / Former job title');
          result = false;
        }

        if (!$scope.payer.country_of_residence) {
          $scope.error_list.push('Please enter country of residence');
          result = false;
        }

        if (!$scope.payer.country) {
          $scope.error_list.push('Please enter country of expertise');
          result = false;
        }

        if (!$scope.payer.time_zone) {
          $scope.error_list.push('Please enter time zone');
          result = false;
        }


        if (isNotPayerPortal && !$scope.selected_respondent_types.length) {
          $scope.error_list.push('Please select respondent type');
          result = false;
        }

        if (isNotPayerPortal && ($scope.selected_archetype_groups && !$scope.selected_archetype_groups.length)) {
          $scope.error_list.push('Please select Payer Archetype group');
          result = false;
        }

        if (isNotPayerPortal) {
          if (!$scope.payer.remuneration || !$scope.payer.remuneration.currency) {
            $scope.error_list.push('Please select Remuneration currency');
            result = false;
          }

          if (!$scope.payer.remuneration || $scope.payer.remuneration.rate === null || !angular.isDefined(
            $scope.payer.remuneration.rate)) {
            $scope.error_list.push('Please enter Remuneration rate');
            result = false;
          } else if ($scope.payer.remuneration.currency) {
            var rate_limits = { 'USD': 100, 'GBP': 75, 'EUR': 85, 'CAD': 125 };

            var c = $filter('filter')(
              $scope.currency,
              { 'id': $scope.payer.remuneration.currency });
            c = (c.length) ? c[0] : {};

            for (var rate_name in rate_limits) {
              var rate_limit = rate_limits[rate_name];
              if (c.currency === rate_name) {
                if ($scope.payer.remuneration.rate > rate_limit) {
                  $scope.error_list.push('Please ensure that rate is no more than ' + rate_limit + ' ' + rate_name);
                  result = false;
                }
                break;
              }
            }
          }
        }

        return result;
      };

      payerHelper.handle_payer_activity_report = function (payerId) {
        var dialog_scope = $rootScope.$new();

        dialog_scope.popupDateOptions = portalHelper.popupDateOptionsAny;
        dialog_scope.selected = {};
        dialog_scope.CONSTANTS = CONSTANTS;

        dialog_scope.date_popup1 = {
          opened: false
        };

        dialog_scope.open_popup1 = function () {
          dialog_scope.date_popup1.opened = true;
        };

        dialog_scope.date_popup2 = {
          opened: false
        };

        dialog_scope.open_popup2 = function () {
          dialog_scope.date_popup2.opened = true;
        };

        let dialog = $ngConfirm({
          title: 'Payer Activity Report',
          closeIcon: true,
          contentUrl: portalHelper.getUrlRev(
            'html_templates/dialogs/admin_payer_activity_report.ee605601.html'),
          scope: dialog_scope,
          type: 'blue',
          buttons: {
            submit: {
              btnClass: 'mat ui primary button',
              action: function () {
                if (dialog_scope.selected.from_date && dialog_scope.selected.to_date &&
                  new Date(dialog_scope.selected.from_date) > new Date(dialog_scope.selected.to_date)) {
                  portalHelper.showPopupError('Date error',
                    'Date from should be less than date to');
                  return false; 
                }

                var query_args = {};

                if (dialog_scope.selected.from_date) {
                  query_args['from_date'] = $filter('date')(
                    dialog_scope.selected.from_date,
                    CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
                }

                if (dialog_scope.selected.to_date) {
                  query_args['to_date'] = $filter('date')(
                    dialog_scope.selected.to_date,
                    CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
                }

                portalHelper.downloadFileWithUrl(
                  '/reports/payer/activity/xlsx/' + payerId + '/',
                  query_args
                );
              }
            }
          }
        });

        return dialog;
      };
    }]
);
