app.filter('calculateInitials', function () {
  return function (firstName, lastName) {
    var result = '';

    if (firstName && firstName.length > 0) {
      result = firstName[0];
    }

    if (lastName && lastName.length > 0) {
      result += lastName[0];
    }

    return result;
  };
});
