
app .directive('numberFieldDecimal', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      decimals: '=',
      applyDecimals: '='
    },
    link: function(scope, element, attrs, modelCtrl) {
      if (element[0].nodeName.toLowerCase() !== 'input' || attrs.type !== 'number') {
        throw new Error('numberFieldDecimal directive can only be used with <input type="number">');
      }
      element.bind('keypress', function(event) {
        if (!scope.applyDecimals && (event.charCode === 46)) {
          event.preventDefault();
        }
      });
      modelCtrl.$parsers.push(function(inputValue) {
        if (inputValue == undefined) return '';
        if (!scope.applyDecimals) {
          inputValue = Math.floor(inputValue);
          return inputValue;
        }
        let transformedInput = parseFloat(inputValue).toFixed(scope.decimals);
        if (transformedInput != inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }
        return transformedInput;
      });
    }
  };
});
