app.directive('durationPicker', ['portalHelper',
  function (portalHelper) {
    return {
      require: 'ngModel', 
      scope: {
        maxDays: '<',
        stepHours: '<',
        showDays: '<',
        showHours: '<',
        showMinutes: '<',
        showSeconds: '<',
        changeMethod: '<',
        disabled: '<'
      },
      link: function (scope, elt, attrs, ngModelCtrl) { 
        scope.$watch('minutes + hours + days', function () {
          var value = '';
          var sep = '';

          if (scope.showDays && scope.showHours) {
            if (scope.showDays) {
              value += sep + scope.format_number(scope.days);
              sep = ':';
            }

            if (scope.showHours) {
              value += sep + scope.format_number(scope.hours);
              sep = ':';
            }

            if (scope.showMinutes) {
              value += sep + scope.format_number(scope.minutes);
              sep = ':';
            }

            value = scope.days + ' ' + scope.format_number(scope.hours) + ':00:00';
          } else {
            if (scope.showDays) {
              value += sep + scope.format_number(scope.days);
              sep = ':';
            }

            if (scope.showHours) {
              value += sep + scope.format_number(scope.hours);
              sep = ':';
            }

            if (scope.showMinutes) {
              value += sep + scope.format_number(scope.minutes);
              sep = ':';
            }
          }

          ngModelCtrl.$setViewValue(value); 
        });

        ngModelCtrl.$formatters.push(function (modelValue) {
          if (modelValue == null) {
            return;
          }
          scope.minutes = scope.hours = scope.days = scope.seconds = 0;
          var values = modelValue.split(':');

          if (values.length === 2) {
            if (scope.showMinutes) {
              scope.minutes = parseInt(values.pop());
            }

            if (scope.showHours) {
              scope.hours = parseInt(values.pop());
            }

            if (scope.showDays) {
              scope.days = parseInt(values.pop());
            }
          } else {
            var myRegexp = /^(\d)+ (\d{2}):(\d{2}):(\d{2})$/g;

            var match = myRegexp.exec(modelValue);
            if (match) {
              if (scope.showSeconds) {
                scope.minutes = parseInt(match[4]);
              }

              if (scope.showMinutes) {
                scope.minutes = parseInt(match[3]);
              }

              if (scope.showHours) {
                scope.hours = parseInt(match[2]);
              }

              if (scope.showDays) {
                scope.days = parseInt(match[1]);
              }
            }
          }
        });

        if (scope.maxDays === undefined) {
          scope.maxDays = 50;
        }

        if (scope.showHours === undefined) {
          scope.showHours = true;
        }

        if (scope.stepHours === undefined) {
          scope.stepHours = 1;
        }

        scope.minutes_list = [];
        scope.hours_list = [];
        scope.days_list = [];

        scope.format_number = function (num) {
          return ('0' + num).substr(-2);
        };

        for (var i = 0; i < 60; i += 5) {
          scope.minutes_list.push(i);
        }

        for (i = 0; i < 24; i += scope.stepHours) {
          scope.hours_list.push(i);
        }

        for (i = 0; i <= scope.maxDays; i += 1) {
          scope.days_list.push(i);
        }
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/duration_picker.0bc673ef.html')
    };
  }]
);
