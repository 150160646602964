
app.directive('showDetailedErrors', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      errors: '=',
      setCurQuestion: '='
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/show_detailed_errors.f3c3c301.html')
  };
}]);
