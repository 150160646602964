app.service('questionService', [
  'portalHelper',
  function (portalHelper) {
    let questionService = this;
    const MIN_OPTIONS_LENGTH = 3;

    questionService.get_options_pasted_from_word = function (isPastingFromWord, pasteEvent) {
      if (!isPastingFromWord) {
        return [];
      }

      let options = portalHelper.get_list_items_from_paste_event(pasteEvent);
      if (!options.length) {
        return [];
      }

      pasteEvent.preventDefault();

      return options;
    };

    questionService.get_updated_options = function (currOptions, currIndex, optionsToAdd) {
      let len = 0;
      let i = 0;
      let options = [];
      for (i = 0; i < currIndex; ++i) {
        let option = currOptions[i];
        if (option.option_name) {
          options.push({
            option_name: option.option_name,
            option_value: ++len
          });
        }
      }

      for (i = 0; i < optionsToAdd.length; ++i) {
        options.push({
          option_name: optionsToAdd[i].text,
          option_value: ++len
        });
      }

      for (i = currIndex + 1; i < currOptions.length; ++i) {
        option = currOptions[i];
        if (option.option_name) {
          options.push({
            option_name: option.option_name,
            option_value: ++len
          });
        }
      }

      if (len < MIN_OPTIONS_LENGTH) {
        options.push({
          option_name: '',
          option_value: ++len
        });
      }

      return options;
    };

    questionService.get_updated_options_for_ranking_questions = function (currOptions, currIndex, optionsToAdd) {
      let len = 0;
      let i = 0;
      let options = [];
      for (i = 0; i < currIndex; ++i) {
        let option = currOptions[i];
        if (option.option_name[0]) {
          options.push({
            option_name: option.option_name,
            sequence_number: ++len
          });
        }
      }

      for (i = 0; i < optionsToAdd.length; ++i) {
        options.push({
          option_name: [optionsToAdd[i].text],
          sequence_number: ++len
        });
      }

      for (i = currIndex + 1; i < currOptions.length; ++i) {
        option = currOptions[i];
        if (option.option_name[0]) {
          options.push({
            option_name: option.option_name,
            sequence_number: ++len
          });
        }
      }

      if (len < MIN_OPTIONS_LENGTH) {
        options.push({
          option_name: [''],
          sequence_number: ++len
        });
      }

      return options;
    };
  }
]);