app.service('persistentService', ['storageService', 'authHelper', 'portalHelper',
  function (storageService, authHelper, portalHelper) {
    var persistentService = this;

    persistentService.getFinalPropertyName = function (property_name) {

      let me = authHelper.getMeShared();
      if (! (me && me.id) ) {
        return;
      }
      let impersonated_user_id = 0;
      let orig_user_id = null;

      if (authHelper.isImpersonation()) {
        const IS_ORIGINAL_USER = true;
        let me_orig = authHelper.getLogin(IS_ORIGINAL_USER);
        if ( !(me_orig && me_orig.id) ) {
          return;
        }
        orig_user_id = me_orig.id;
        impersonated_user_id = me.id;
      }
      else {
        orig_user_id = me.id;
      }

      return property_name + '_' + orig_user_id + '_' + impersonated_user_id;
    };

    persistentService.saveLocalStorageObject = function (object_data, property_name, expiry_date) {

      if (!expiry_date) {
        expiry_date = new Date();
        expiry_date.setDate(expiry_date.getDate() + 14);
      }

      var final_property_name = persistentService.getFinalPropertyName(property_name);

      if (!final_property_name) {
        return;
      }

      var object = {};
      object['expires_on'] = expiry_date;
      object['data'] = object_data;
      storageService.getLocalStorage()[final_property_name] = JSON.stringify(object);
    };

    persistentService.getLocalStorageObject = function (property_name) {
      var final_property_name = persistentService.getFinalPropertyName(property_name);

      if (!final_property_name) {
        return;
      }

      var storage = storageService.getLocalStorage();

      if (final_property_name in storage) {
        var object = JSON.parse(storage[final_property_name]);

        if ('expires_on' in object && new Date(object['expires_on']) < new Date()) {
          delete storage[final_property_name];
          return;
        }

        if ('data' in object) {
          return object['data'];
        }
      }
    };

    persistentService.clearLocalStorageObject = function (property_name) {
      var final_property_name = persistentService.getFinalPropertyName(property_name);
      if (!final_property_name) {
        return;
      }

      var storage = storageService.getLocalStorage();

      if (final_property_name in storage) {
        delete storage[final_property_name];
        return true;
      }
    };

    persistentService.isUserLoggedIn = function () {
      var myDetails = portalHelper.getMyDetails();
      if (myDetails) {
        return true;
      }
    };

    persistentService.ready = portalHelper.meSharedReady;
  }]
);
