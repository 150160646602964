app.directive('typeaheadFocus', [ function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attr, ngModel) {

      element.bind('click', function () {


        let viewValue = ngModel.$viewValue;
        if (viewValue) {
          ngModel.$setViewValue(' ');
          ngModel.$setViewValue(viewValue);
        }
      });

      scope.emptyOrMatch = function (actual, expected) {
        if (expected == ' ') {
          return true;
        }
        return actual.indexOf(expected) > -1;
      };
    }
  };
}]);
