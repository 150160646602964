app.factory('clientListService', ['HttpAuth', 'apiHelper', '$q',
  function (HttpAuth, apiHelper, $q) {
    var clientListService = {};
    clientListService.list = {};

    clientListService.fetchClientLists = function (callBack) {
      var promises = [
        HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/survey/lists/')
      ];

      $q.all(promises).then(function (result) {
        clientListService.list = result[0].data;

        if (callBack != null && typeof (callBack) == 'function') {
          callBack(result[0].data);
        }
      });
    };

    return clientListService;
  }]
);
