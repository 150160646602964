app.service('menuHeaderUserInfoService', ['$rootScope', 'HttpAuth', 'apiHelper', 'portalHelper',
  function ($rootScope, HttpAuth, apiHelper, portalHelper) {
    var service = this;

    service.urlForAdmin = apiHelper.getApiUrl() + '/api/admin/details/';
    service.urlForClient = apiHelper.getApiUrl() + '/api/client/details/';
    service.urlForPayer = apiHelper.getApiUrl() + '/api/payer/details/';

    service.fetchUserDetailsForAdmin = function (scope) {
      return HttpAuth.get(service.urlForAdmin).then(
        function (result) {
          scope.currentLoggedInUser = result.data;
        }
      );
    };

    service.fetchUserDetailsForClient = function (scope) {
      return HttpAuth.get(service.urlForClient).then(
        function (result) {
          scope.clientUserDetails = result.data;
        }
      );
    };

    service.fetchUserDetailsForPayer = function (scope) {
      return HttpAuth.get(service.urlForPayer).then(
        function (result) {
          scope.payerUserDetails = result.data;
        }
      )
        .catch(portalHelper.showErrorCommon);
    };
  }]);
