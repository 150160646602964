app.service('persistentObjectsHelper', ['persistentService', 'payerSurveyHelper',
  function (persistentService, payerSurveyHelper) {
    var persistentObjectsHelper = this;

    persistentObjectsHelper.removeObjectFromStorageArray = function (object_id, property_name) {
      var saved_objects = persistentService.getLocalStorageObject(
        property_name
      );

      if (!saved_objects) {
        return;
      }

      for (var i = 0; i < saved_objects.length; i++) {
        if (saved_objects[i].id == object_id) {
          saved_objects.splice(i, 1);

          persistentService.saveLocalStorageObject(
            saved_objects,
            property_name
          );

          return true;
        }
      }
    };

    persistentObjectsHelper.removeObjectFromStorageDictionary = function (object_id, property_name) {
      var saved_objects = persistentService.getLocalStorageObject(
        property_name
      );

      if (!saved_objects) {
        return;
      }

      if (object_id in saved_objects) {
        delete saved_objects[object_id];

        persistentService.saveLocalStorageObject(
          saved_objects,
          property_name
        );
        return true;
      }
    };

    persistentObjectsHelper.saveObjectsInLocalStorage = function (property_name, object_to_save) {
      persistentService.saveLocalStorageObject(
        object_to_save,
        property_name
      );
    };

    persistentObjectsHelper.getSavedClarifications = function (scope, backend_clarifications) {
      var saved = persistentService.getLocalStorageObject(
        scope.property_question_clarifications
      );

      if (!saved) {
        return angular.copy(backend_clarifications);
      }

      var combined_objects = angular.copy(saved);

      for (var i = 0; i < backend_clarifications.length; i++) {
        var backend_item = backend_clarifications[i];
        var saved_item = _.findWhere(saved, { id: backend_item.id });

        if (saved_item && !saved_item.clarification && backend_item.clarification) {
          var index = _.findIndex(combined_objects, { id: backend_item.id });
          combined_objects[index] = backend_item;
        }

        if (!saved_item) {
          combined_objects.push(backend_item);
        }
      }

      return combined_objects;
    };

    persistentObjectsHelper.getSavedResponses = function (scope, backend_responses) {
      payerSurveyHelper.updateAllResponsesFromServer(scope, backend_responses);

      var saved = persistentService.getLocalStorageObject(
        scope.property_responses
      );

      if (!saved) {
        return angular.copy(scope.responses);
      }

      var combined_objects = angular.copy(saved);

      for (var i = 0; i < backend_responses.length; i++) {
        var backend_item = backend_responses[i];
        var saved_item = _.findWhere(combined_objects, { id: backend_item.id });

        if (!saved_item) {
          combined_objects[backend_item.question] = backend_item;
        } else if (saved_item && !saved_item.clarification_request && backend_item.clarification_request) {
          combined_objects[backend_item.question] = backend_item;
        } else if (saved_item && !saved_item.clarification_response && backend_item.clarification_response) {
          combined_objects[backend_item.question] = backend_item;
        } else {
          var backend_time = new Date(backend_item.modified_datetime);
          var saved_time = new Date(saved_item.modified_datetime);

          if (backend_time > saved_time) {
            combined_objects[backend_item.question] = backend_item;
          }
        }
      }

      return combined_objects;
    };

    persistentObjectsHelper.injectToScopeResponses = function (scope, saved_responses) {
      for (var key in saved_responses) {
        var r = saved_responses[key];
        if ('id' in r) {
          scope.responses[key] = angular.copy(r);
        } else if (scope.responses_new) {
          scope.responses_new[key] = angular.copy(r);
        }
      }
    };
  }]
);
