app.directive('breadcrumb', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      trail: '='
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/breadcrumb.53c38acc.html'),
    controller: function ($scope) {
      $scope.computeCssClass = function (last) {
        if (last) {
          return 'active';
        } else {
          return '';
        }
      };
    }
  };
}]);
