app.directive('questionInput',
  ['$location', '$timeout', 'dataConvert', 'rankingHelper', 'portalHelper', 'multiselects',
    'CONSTANTS', 'stringHelper', '$rootScope', 'apiHelper', 'HttpAuth', 'authHelper',
    function ($location, $timeout, dataConvert, rankingHelper, portalHelper, multiselects,
      CONSTANTS, stringHelper, $rootScope, apiHelper, HttpAuth, authHelper) {
      return {
        scope: {
          question: '=',
          response: '=',
          disabled: '@',
          responses: '=',
          responsesNew: '=',
          emptyForm: '@',
          questionPreview: '@',
          currencies: '=',
          isClientRatingScale: '@',
          idPrefix: '@',
          screenName: '=',
          showValidationInComponent: '<',
          isResponseValid: '='
        },
        templateUrl: portalHelper.getUrlRev('html_templates/directives/question_input.4043a25f.html'),
        controller: function ($scope) {
          $scope.model_dropdown = '';
          $scope.isMultiRationale = portalHelper.isMultiRationale;
          $scope.isNullOrEmpty = stringHelper.isNullOrEmpty;
          $scope.otherOptionsEnum = CONSTANTS.otherOptions;
          $scope.isSingleRationale = portalHelper.isSingleRationale;
          $scope.responseValidation = {
            isValid: null
          };

          var getRandomizedOptions = function () {
            $timeout(
              function () {
                $scope.randomizedOptions = portalHelper.getRandomizedOptions(
                  $scope.question,
                  $scope.response,
                  $scope.seedToRandomizeOptions
                );
              }
            );
          };

          $scope.enableValidationMessages = stringHelper.isNullOrEmpty($scope.$parent['enableValidationMessages'])
            ? false
            : $scope.$parent.enableValidationMessages;
          $scope.CONSTANTS = CONSTANTS;
          $scope.isInMultiPageSurvey = $scope.$parent.survey == undefined ? null : $scope.$parent.survey.multi_page;
          $scope.has_long_dropdown_options = portalHelper.tor_classic_has_long_dropdown_options;

          this.$onInit = function () {
            if ($scope.question.randomize_options && $scope.response.randomization_seed) {
              $scope.seedToRandomizeOptions = $scope.response.randomization_seed;
            }
            else if (portalHelper.is_test_survey()){
              $scope.seedToRandomizeOptions = parseInt(
                10000000000000 * Math.random() * $scope.question.id
              );
            }

            getRandomizedOptions();
            if ($scope.emptyForm) {
              $scope.$watch('question.scale', function (oldValue, newValue){
                getRandomizedOptions();
              }, true);
            }

          };

          var variableToWatch = function () {
            return JSON.stringify($scope.responseValidation);
          };

          $scope.$watch(variableToWatch, function () {
            $scope.isResponseValid = $scope.responseValidation.isValid;
          }, true);

          $scope.getCurrencyName = function (currencyId) {
            if (!currencyId) {
              return '';
            }

            if (typeof (currencyId) == 'string') {
              return currencyId;
            }

            if ($scope.currencies) {
              return $scope.currencies.find(function (elem) {
                return elem.id == currencyId;
              }).code;
            }
          };

          $scope.validateVWInputs = function () {
            $timeout(
              function () {
                var acceptableQuestionInput = $('#question-' + $scope.question.id + '-response #acceptable_question_input');
                var expensiveQuestionInput = $('#question-' + $scope.question.id + '-response #expensive_question_input');
                var tooExpensiveQuestionInput = $('#question-' + $scope.question.id + '-response #too_expensive_question_input');
                var prohibitiveQuestionInput = $('#question-' + $scope.question.id + '-response #prohibitive_question_input');

                var acceptable_value = parseFloat(acceptableQuestionInput.val());
                var expensive_value = parseFloat(expensiveQuestionInput.val());
                var too_expensive_value = parseFloat(tooExpensiveQuestionInput.val());
                var prohibitive_value = parseFloat(prohibitiveQuestionInput.val());

                if (acceptable_value >= expensive_value) {
                  acceptableQuestionInput.addClass('input-error');
                  $scope.question.errors['acceptable_question_input'] =
                    'This value should be smaller than the next price point';
                } else if (acceptable_value > 0 && acceptable_value < 100000000) {
                  acceptableQuestionInput.removeClass('input-error');
                  $scope.question.errors['acceptable_question_input'] = '';
                }

                if (expensive_value >= too_expensive_value || expensive_value < acceptable_value) {
                  expensiveQuestionInput.addClass('input-error');
                  $scope.question.errors['expensive_question_input'] =
                    'This value should be greater than the previous price point\
                                and smaller than the next price point';
                } else if (expensive_value > 0 && expensive_value < 100000000) {
                  expensiveQuestionInput.removeClass('input-error');
                  $scope.question.errors['expensive_question_input'] = '';
                }

                if (too_expensive_value >= prohibitive_value || too_expensive_value < expensive_value) {
                  tooExpensiveQuestionInput.addClass('input-error');
                  $scope.question.errors['too_expensive_question_input'] =
                    'This value should be greater than previous price point\
                                and smaller than the next price point';
                } else if (too_expensive_value > 0 && too_expensive_value < 100000000) {
                  tooExpensiveQuestionInput.removeClass('input-error');
                  $scope.question.errors['too_expensive_question_input'] = '';
                }

                if (prohibitive_value < too_expensive_value) {
                  prohibitiveQuestionInput.addClass('input-error');
                  $scope.question.errors['prohibitive_question_input'] =
                    'This value should be greater than previous price point';
                } else if (prohibitive_value > 0 && prohibitive_value < 100000000) {
                  prohibitiveQuestionInput.removeClass('input-error');
                  $scope.question.errors['prohibitive_question_input'] = '';
                }
              }
              , 2000);
          };

          multiselects.initTableOfResponses($scope);

          if (!angular.isDefined($scope.response) || !$scope.response) {
            $scope.response = {};
            $scope.response.rating_responses = {};
            $scope.response.ranking_responses = {};
            $scope.response.points_allocation_responses = {};
            $scope.response.other_options = [];

            $scope.response.allow_payer_option = $scope.question.allow_payer_option;

            if ($scope.question.type_name === 'ranking_scale') {
              rankingHelper.initFakeRankingResponsesSaved($scope.response, $scope.question);
              rankingHelper.initFakeRankingResponsesNotSaved($scope.response, $scope.question);
            }
          }

          if ($scope.response['payer_option_selected'] == undefined || $scope.response['payer_option_selected'] == null) {
            $scope.response.payer_option_selected = !stringHelper.isNullOrEmpty($scope.response.payer_option);
          }

          $scope.getSumPoints = function () {
            var sum = 0;
            for (var option_name in $scope.response.points_allocation_responses) {
              if (!stringHelper.isNullOrEmpty($scope.response.points_allocation_responses[option_name].points)) {
                sum += parseInt($scope.response.points_allocation_responses[option_name].points);
              }
            }

            if (!stringHelper.isNullOrEmpty($scope.response.payer_option) &&
            !stringHelper.isNullOrEmpty($scope.response.payer_points)) {
              sum += parseInt($scope.response.payer_points);
            }

            return sum;
          };

          $scope.getRatingResponseCount = function () {
            var count = 0;

            for (var option_name in $scope.response.rating_responses) {
              if (!stringHelper.isNullOrEmpty($scope.response.rating_responses[option_name]) &&
                !stringHelper.isNullOrEmpty($scope.response.rating_responses[option_name].value)) {

                count += 1;
              }
            }

            if (!stringHelper.isNullOrEmpty($scope.response.payer_value)) {
              count += 1;
            }

            return count;
          };

          $scope.getSliderRatingResponseCount = function () {
            var count = 0;

            for (var option_name in $scope.response.rating_responses) {
              var option = $scope.response.rating_responses[option_name];
              if (!stringHelper.isNullOrEmpty(option) &&
                (!stringHelper.isNullOrEmpty(option.value || !stringHelper.isNullOrEmpty(option.non_discrete_scale_value)))
              ) {

                count += 1;
              }
            }

            if (!stringHelper.isNullOrEmpty($scope.response.payer_value) || !stringHelper.isNullOrEmpty($scope.response.non_discrete_payer_value)) {
              count += 1;
            }

            return count;
          };

          $scope.getRatingResponseQuestionsCount = function () {
            var count = 0;
            var question_count = $scope.question.options.length;

            for (var option_name in $scope.response.rating_responses) {
              count += 1;
            }

            if (!stringHelper.isNullOrEmpty($scope.response.payer_option)) {
              count += 1;
              question_count += 1;
            }

            return Math.max(count, question_count);
          };

          $scope.calculateRankingFirstColumnAutoWidth = function() {
            if ($scope.question && $scope.question.scale && $scope.question.scale.values) {
              let totalPercentage = 0;
              for (let i = 0; i < $scope.question.scale.values.length; i++) {
                totalPercentage += $scope.question.scale.values[i].percentage_width;
              }
              if (totalPercentage == 0) {
                return 50;
              } else {
                return 100 - totalPercentage;
              }
            }
          };

          $scope.calculateRatingFirstColumnAutoWidth = function() {
            if ($scope.question && $scope.question.scale && $scope.question.scale.values) {
              let totalPercentage = 0;
              for (let i = 0; i < $scope.question.scale.values.length; i++) {
                totalPercentage += $scope.question.scale.values[i].percentage_width;
              }
              if (totalPercentage == 0) {
                return 50;
              } else {
                return 100 - totalPercentage;
              }
            }
          };

          $scope.getRankingResponseCount = function () {
            var count = 0;
            for (var option_name in $scope.response.ranking_responses) {
              if (!stringHelper.isNullOrEmpty($scope.response.ranking_responses[option_name]) &&
                !stringHelper.isNullOrEmpty($scope.response.ranking_responses[option_name].value)) {

                count += 1;
              }
            }

            if ($scope.question.allow_payer_option == CONSTANTS.otherOptions.SINGLE_OTHER &&
                !stringHelper.isNullOrEmpty($scope.response.payer_option) &&
                !stringHelper.isNullOrEmpty($scope.response.payer_value)) {
              count += 1;
            }
            return count;
          };

          $scope.getItemsToRankCount = function () {
            if ($scope.question.scale == null || !$scope.question.scale.options) {
              return 0;
            }
            if ($scope.question.scale.items_to_rank == null) {
              return $scope.question.scale.options.length;
            }

            return $scope.question.scale.items_to_rank;
          };

          $scope.getTableResponsesCount = function () {
            var count = 0;

            for (var option_name in $scope.response.table_responses) {
              if (!stringHelper.isNullOrEmpty($scope.response.table_responses[option_name])) {
                var ansCount = 0;

                for (var col_name in $scope.response.table_responses[option_name]) {
                  if (!stringHelper.isNullOrEmpty($scope.response.table_responses[option_name][col_name]) &&
                    (!stringHelper.isNullOrEmpty($scope.response.table_responses[option_name][col_name].answer) ||
                      !stringHelper.isNullOrEmpty($scope.response.table_responses[option_name][col_name].option))) {
                    ansCount += 1;
                  }
                }

                if (ansCount > 0) {
                  count += 1;
                }
              }
            }

            return count;
          };

          $scope.getTableQuestionsCount = function (question) {
            if (question.scale.options) {
              return question.scale.options.length;
            }

            return 0;
          };

          $scope.payer_option_change = function () {
            if (stringHelper.isNullOrEmpty($scope.response.payer_option)) {
              $scope.response.payer_points = null;
              $scope.response.payer_rationale = '';
            }
          };

          $scope.payer_point_change = function () {
            if (stringHelper.isNullOrEmpty($scope.response.payer_points)) {
              $scope.response.payer_rationale = '';
            }
          };

          $scope.add_multi_other_option = function () {
            if (!$scope.response.other_options) {
              $scope.response.allow_payer_option = CONSTANTS.otherOptions.MULTIPLE_OTHER;
              $scope.response.other_options = [{
                'option_name': ''
              }];
            } else {
              $scope.response.other_options.push({ 'option_name': '' });
            }
          };


          $scope.clearUiSelect = function ($event, $select) {
            $event.stopPropagation();
            $select.clear($event);
          };

          $scope.delete_multi_other_option = function (other_index, option) {
            if ($scope.disabled == 'true') {
              return;
            }

            portalHelper.showPopupYesNo(
              '',
              'Are you sure you want to delete this option?',
              function () {
                if (option.option_name in $scope.response.rating_responses) {
                  delete $scope.response.rating_responses[option.option_name];
                }

                $scope.response.other_options.splice(other_index, 1);
                $scope.$apply(); 
              }
            );
          };

          $scope.multi_other_option_change = function (cur_option, old_option) {
            if ($scope.response.rating_responses[old_option]) {
              if (cur_option) {
                $scope.response.rating_responses[cur_option] = angular.copy($scope.response.rating_responses[old_option]);
              }
              delete $scope.response.rating_responses[old_option];
            } else if (cur_option && !$scope.response.rating_responses[cur_option]) {
              $scope.response.rating_responses[cur_option] = { 'value': null };
            }
          };

          $scope.updateCheckboxResponses = function (option) {
            if (!$scope.response.checkbox_responses[option.id].checked) {
              delete $scope.response.checkbox_responses[option.id];
            }
          };

          function updateRankingScaleValues() {
            var all_values = $scope.question.scale.values;

            if (!angular.isDefined($scope.response)) {
              $scope.response = {};
            }

            for (var k = 0; k < $scope.response.ranking_responses_saved.length; k++) {
              if (k < all_values.length) {
                $scope.response.ranking_responses_saved[k].value = all_values[k].id;
              }
            }

            for (k = 0; k < $scope.response.ranking_responses_not_saved.length; k++) {
              $scope.response.ranking_responses_not_saved[k].value = null;
            }

            $scope.response.ranking_responses = angular.copy(
              dataConvert.convert_to_dict($scope.response.ranking_responses_saved, 'option'));
          }

          function highlight_drag_drop_destination(id, status) {
            var srcContainer = $('#ranking-scale-question-' + id).find('.src-list')[0];
            var dstContainer = $('#ranking-scale-question-' + id).find('.dst-list')[0];

            var srcPlaceholder = $(srcContainer).find('.src-placeholder')[0];
            var dstPlaceholder = $(dstContainer).find('.dst-placeholder')[0];

            var srcHeight = $(srcPlaceholder).height();
            var dstHeight = $(dstPlaceholder).height();

            var maxHeight = Math.max(srcHeight, dstHeight);

            $(srcPlaceholder).css('min-height', maxHeight + 'px');
            $(dstPlaceholder).css('min-height', maxHeight + 'px');

            $(srcPlaceholder).removeClass('active');
            $(dstPlaceholder).removeClass('active');

            if (status == true) {
              $(srcPlaceholder).addClass('active');
              $(dstPlaceholder).addClass('active');
            }
          }

          $scope.drag_not_saved = {
            accept: function (sourceItemHandleScope, destSortableScope) {
              var srcQuestionId = $(sourceItemHandleScope.itemScope.element)
                .attr('data-question-id');
              var dstQuestionId = $(destSortableScope.element).attr('data-question-id');

              return srcQuestionId == dstQuestionId;
            },
            itemMoved: function (event) {
              updateRankingScaleValues();
            },
            orderChanged: function (event) {
              updateRankingScaleValues();
            },
            dragStart: function (event) {
              var questionId = $(event.source.itemScope.element).attr('data-question-id');
              highlight_drag_drop_destination(questionId, true);
            },
            dragEnd: function (event) {
              var questionId = $(event.source.itemScope.element).attr('data-question-id');
              highlight_drag_drop_destination(questionId, false);
            }
          };

          $scope.drag_saved = {
            accept: function (sourceItemHandleScope, destSortableScope) {
              var srcQuestionId = $(sourceItemHandleScope.itemScope.element)
                .attr('data-question-id');
              var dstQuestionId = $(destSortableScope.element).attr('data-question-id');
              return srcQuestionId == dstQuestionId;
            },
            itemMoved: function (event) {
              updateRankingScaleValues();
            },
            orderChanged: function (event) {
              updateRankingScaleValues();
            },
            dragStart: function (event) {
              var questionId = $(event.source.itemScope.element).attr('data-question-id');
              highlight_drag_drop_destination(questionId, true);
            },
            dragEnd: function (event) {
              var questionId = $(event.source.itemScope.element).attr('data-question-id');
              highlight_drag_drop_destination(questionId, false);
            }
          };

          function updateTextAreaHeights(elem) {
            var table = $(elem).closest('table');
            autosize.update($(table).find('textarea.expandable'));
          }

          $timeout(function () {
            autosize($('textarea.expandable'));

            $('textarea.expandable').focus(function () {
              updateTextAreaHeights(this);
            });

            $('textarea.expandable').blur(function () {
              updateTextAreaHeights(this);
            });

            $('.table-of-responses-select').click(function () {
              updateTextAreaHeights(this);
            });
          });

          $scope.calcRatingUpperCols = function () {
            if (!angular.isDefined($scope.question.scale.values)) {
              return;
            }

            var total_cols = $scope.question.scale.values.length;
            var upper_cols = Math.floor(total_cols / 3.0);

            if (upper_cols < 1) {
              upper_cols = 1;
            }

            return upper_cols;
          };

          $scope.calcRatingUpperSpaceCols = function () {
            if (!angular.isDefined($scope.question.scale.values)) {
              return;
            }

            var upper_cols = $scope.calcRatingUpperCols();
            return $scope.question.scale.values.length - upper_cols * 2;
          };

          $scope.checkbox_other_click = function (questionId) {
            var chkId = stringHelper.isNullOrEmpty($scope.idPrefix)
              ? '#chk_' + questionId + '_other'
              : '#' + $scope.idPrefix + 'chk_' + questionId + '_other';

            var txtId = stringHelper.isNullOrEmpty($scope.idPrefix)
              ? '#text-checkbox-other-' + questionId
              : '#' + $scope.idPrefix + 'text-checkbox-other-' + questionId;

            $scope.response.payer_rationale = '';
            var checkedStatus = $(chkId).is(':checked');

            if (checkedStatus) {
              $scope.response.payer_option_selected = true;
              $(txtId).focus();
            } else {
              $scope.response.payer_option_selected = false;
            }
          };

          $scope.toggle_other_option = function (questionId) {
            var elemId = stringHelper.isNullOrEmpty($scope.idPrefix)
              ? '#chk_' + questionId + '_other'
              : '#' + $scope.idPrefix + 'chk_' + questionId + '_other';

            $scope.response.payer_option_selected = $(elemId).is(':checked');

            if (!$scope.response.payer_option_selected) {
              $scope.response.payer_option = '';
            }
          };

          $scope.radio_other_click = function (questionId) {
            $scope.response.payer_option_selected = true;

            var txtId = stringHelper.isNullOrEmpty($scope.idPrefix)
              ? '#text-radio-other-' + questionId
              : '#' + $scope.idPrefix + 'text-radio-other-' + questionId;

            $(txtId).focus();
            $scope.response.response_value = '';
          };

          $scope.other_textbox_click = function (questionId) {
            var elemId = stringHelper.isNullOrEmpty($scope.idPrefix)
              ? '#chk_' + questionId + '_other'
              : '#' + $scope.idPrefix + 'chk_' + questionId + '_other';

            $(elemId).prop('checked', true);
            $scope.response.payer_option_selected = true;
          };

          $scope.rating_other_change = function () {
            if (!$scope.response.payer_option) {
              $scope.response.payer_value = '';
            }
          };

          $scope.radio_normal_click = function () {
            $scope.response.payer_option = '';
            $scope.response.payer_option_selected = false;
          };

          $scope.input_other_click_or_change = function (questionId) {
            if ($scope.response.payer_option) {
              $scope.response.response_value = '';
            }

            var id = '#' + $scope.idPrefix + 'radio_' + questionId + '_other';
            $(id).prop('checked', true);
            $scope.response.payer_option_selected = true;
          };

          $scope.ranking_scale_clicked = function (question, option_id, value_id) {
            if (!$scope.response) {
              return;
            }

            for (var cur_option_id in $scope.response.ranking_responses) {
              var scale_value = $scope.response.ranking_responses[cur_option_id].value;
              if (scale_value == value_id && cur_option_id != option_id) {
                delete $scope.response.ranking_responses[cur_option_id];
              }
            }

            if ($scope.question.allow_payer_option &&
              $scope.response.payer_value == value_id && $scope.response.payer_option != option_id) {
              $scope.response.payer_value = '';
            }
          };

          $scope.answerGaborGrangerSubquestion = function (answer_level_id) {
            $scope.response.is_disabled = true;

            var answerLevel = _.findWhere($scope.question.pricing_model.answer_levels,
              { id: answer_level_id });
            var answerLevelName = '';
            if (answerLevel != undefined) {
              answerLevelName = answerLevel.name;
            }

            $scope.response.gabor_granger_responses.push(
              {
                'price': $scope.question.subquestion.price,
                'answer_level_id': answer_level_id,
                'answer_level_name': answerLevelName
              }
            );

            if (!$scope.$parent.is_multipage_survey()) {
              $scope.$parent.set_question_in_focus($scope.question.id);
            }

            $scope.getNextGaborGrangerSubquestion().then(function () {
              $scope.response.is_disabled = false;
            }, function () {
              $scope.response.is_disabled = false;
            });
          };

          $scope.prevent_dblclick = function () {
            return false;
          };

          $scope.ready_to_render_tor = function () {
            var isRandomizedOptionsLoaded = $scope.randomizedOptions && $scope.randomizedOptions.cells &&
            $scope.randomizedOptions.cells.length;

            return isRandomizedOptionsLoaded;
          };

          $scope.getNextGaborGrangerSubquestion = function () {
            if (!$scope.response.gabor_granger_responses) {
              $scope.response.gabor_granger_responses = [];
            }

            var url = '';
            if (authHelper.isUserPayer()) {
              url = apiHelper.getApiUrl() + '/api/payer/survey/question/next/' + $scope.question.id + '.json';
            } else {
              url = apiHelper.getApiUrl() + '/api/authoring/survey/question/next/' + $scope.question.id + '.json';
            }
            var data = { 'gabor_granger_responses': $scope.response.gabor_granger_responses };

            var questionSelector = 'question-' + $scope.question.id + ' .preview-question';

            $(questionSelector).slideUp(100);
            return HttpAuth.put(url, data).then(function (result) {
              if (!result.data.price) {
                if (!$scope.question.subquestion) {
                  $scope.question.subquestion = {};
                }

                $scope.question.subquestion.is_last = true;

                if (!$scope.$parent.is_multipage_survey()) {
                  $scope.$parent.set_question_in_focus(null);
                }
              } else {
                $(questionSelector).slideDown(300);
                $scope.question.subquestion = result.data;
                $scope.question.subquestion.is_last = false;
              }
            }, function (result) {
              portalHelper.showErrorCommon(result);
            });
          };

          if ($scope.question.type == CONSTANTS.questionTypeIds.GABOR_GRANGER_PRICING_MODEL) {
            var is_view_survey = ($location.path().indexOf('/Payers/ViewSurvey') === 0);
            var is_pilot_survey = ($location.path()
              .indexOf('/Client/TestSurvey') === 0) || ($location.path()
              .indexOf('/Admin/TestSurvey') === 0);
            var is_payer_clarification_request = ($location.path().indexOf('/Payers/ViewRequest') === 0);

            if (authHelper.isUserPayer() && (is_view_survey || is_payer_clarification_request)) {
              if ($scope.response.clarification_request &&
                  $scope.response.clarification_request.is_response_reset) {
                $scope.response.gabor_granger_responses = [];
              }
              $scope.getNextGaborGrangerSubquestion();
            } else if (!authHelper.isUserPayer() && is_pilot_survey) {
              $scope.getNextGaborGrangerSubquestion();
            }
          }

        }
      };
    }]
);
