
app.directive('activeInactive', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      tableId: '@',
      columnIndex: '@',
      searchBoolean: '@?',
      activeModel: '=?',
      activeToggledCallback: '&?'
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/active_inactive.d8e16dcb.html'),
    controller: function ($scope) {
      $scope.ACTIVE = ACTIVE;
      $scope.INACTIVE = INACTIVE;

      if (!$scope.activeModel) {
        $scope.activeModel = $scope.ACTIVE;
      }

      $scope.columnIndex = (typeof $scope.columnIndex == 'undefined' ? 1 : $scope.columnIndex);

      $scope.filter_results = function () {
        var search_value = $scope.activeModel;

        if ($scope.searchBoolean && $scope.activeModel == $scope.ACTIVE) {
          search_value = true;
        } else if ($scope.searchBoolean && $scope.activeModel == $scope.INACTIVE) {
          search_value = false;
        }

        $('#' + $scope.tableId).DataTable().columns($scope.columnIndex).search(search_value).draw();
        if ($scope.activeToggledCallback) {
          $scope.activeToggledCallback({ argActiveModel: $scope.activeModel });
        }
      };
    }
  };
}]);
