app.service('clientQuestionValidate', ['CONSTANTS', 'stringHelper',
  function (CONSTANTS, stringHelper) {
    var clientQuestionValidate = this;

    clientQuestionValidate.checkUniqueOptions = function (options, custom_option_name) {
      var unique_options = {};
      var option_name = (custom_option_name) ? custom_option_name : 'option_name';

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option[option_name] in unique_options) {
          return false;
        }

        unique_options[option[option_name]] = 1;
      }
      return true;
    };

    clientQuestionValidate.checkBlankOptions = function (options) {
      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (!option.option_name) {
          return false;
        }
      }
      return true;
    };

    clientQuestionValidate.checkDefaultOptionsValidity = function (options, scale_values) {
      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option.default_initial_scale_value < parseFloat(scale_values[0].value) || option.default_initial_scale_value > parseFloat(scale_values[scale_values.length - 1].value)) {
          return false;
        }
      }
      return true;
    };

    clientQuestionValidate.checkScaleNameUnique = function (scales, cur_scale, cur_scale_id) {
      for (var i = 0; i < scales.length; i++) {
        var scale = scales[i];
        if (scale.name == cur_scale.name && scale.id != cur_scale_id) {
          return false;
        }
      }

      return true;
    };

    clientQuestionValidate.checkValidPercentageWidth = function (values) {
      let is_valid_percentage_wdith = _.every(_.pluck(values, 'percentage_width'), function(value) {
        return value === null || (value >= 1 && value <= 100);
      });
      return is_valid_percentage_wdith;
    };

    clientQuestionValidate.validateFreeTextQuestion = function ($scope, question) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      return res;
    };

    clientQuestionValidate.validateQuestionWithOptions = function ($scope, question) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      if (!angular.isDefined(question.options) || !question.options.length) {
        question.errors.push('Please add options to your question');
        return false;
      }

      if (!clientQuestionValidate.checkBlankOptions(question.options)) {
        question.errors.push('Option may not be blank');
        res = false;
      }

      if (!clientQuestionValidate.checkUniqueOptions(question.options)) {
        question.errors.push('Options must be unique');
        res = false;
      }

      return res;
    };

    clientQuestionValidate.isValidScale = function (scale) {
      if (scale.values.length < 2) {
        return false;
      }
      if (scale.values[0].value == null || scale.values[0].value === '') {
        return false;
      }
      let flag = true;
      scale.values.forEach(function(scaleVal) {
        if (isNaN(scaleVal.value)) {
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }
      return true;
    };

    clientQuestionValidate.validateRatingScaleQuestion = function ($scope, question, scale_lists) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      if (question.scale.values == null || question.scale.values.length < 2) {
        question.errors.push('Rating scales need atleast 2 column options to be saved');
        res = false;
      }

      if (question.scale && question.scale.values) {
        for (var i=0; i<question.scale.values.length; i++) {
          var val = question.scale.values[i];

          if (!val.value_name || val.value_name.trim() == '') {
            question.errors.push('Column labels are mandatory and cannot be blank.');

            return false;
          }
        }
      }

      var columnLabels = _.pluck(question.scale.values, 'value_name');

      if (columnLabels.length !== _.uniq(columnLabels).length) {
        question.errors.push('Rating scale column names must have unique values.');
        res = false;
      }

      if (!clientQuestionValidate.checkBlankOptions(question.options)) {
        question.errors.push('Option may not be blank');
        res = false;
      }

      if (!clientQuestionValidate.checkUniqueOptions(question.options)) {
        question.errors.push('Options must be unique');
        res = false;
      }

      if (!clientQuestionValidate.checkValidPercentageWidth(question.scale.values)) {
        question.errors.push('Invalid percentage value for the column width');
        res = false;
      }

      let totalPercentage = _.reduce(question.scale.values, function(sum, value) {
        return sum + value.percentage_width;
      }, 0);
      let remainingPercentage = 100 - totalPercentage;
      if (remainingPercentage < 20) {
        question.errors.push(
          'The total width of all columns must be under 100 percent.');
        res = false;
      }

      return res;
    };

    clientQuestionValidate.validateDragDropQuestion = function ($scope, question, scale_lists) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      var scale_options = [];
      for (var i = 0; i < question.scale.options.length; i++) {
        scale_options.push({ 'option_name': question.scale.options[i]['option_name'][0] });
      }

      if (!clientQuestionValidate.checkBlankOptions(scale_options)) {
        question.errors.push('Option may not be blank');
        res = false;
      }

      if (!clientQuestionValidate.checkUniqueOptions(scale_options)) {
        question.errors.push('Options must be unique');
        res = false;
      }

      if (!question.scale.items_to_rank) {
        question.errors.push('Please enter Number of items to rate');
        res = false;
      } else if (question.scale.items_to_rank < 2) {
        question.errors.push('Number of items to rate should be greater than 2');
        res = false;
      } else if (question.scale.items_to_rank > scale_options.length) {
        question.errors.push('Number of items to rate should be less or equal than ' + scale_options.length);
        res = false;
      }

      return res;
    };

    clientQuestionValidate.validateRadioRankingQuestion = function ($scope, question, scale_lists) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      var scale_options = _.flatten(_.pluck(question.scale.options, 'option_name'));

      var scale_values = _.pluck(question.scale.values, 'value_name');

      if (_.some(scale_options, function (val) {
        return (val == null || val == '');
      })) {
        question.errors.push('Option Labels cannot be left blank.');
        res = false;
      }

      if (_.uniq(scale_options).length !== scale_options.length) {
        question.errors.push('Option Label values must be unique.');
        res = false;
      }

      if (_.some(scale_values, function (val) {
        return (val == null || val == '');
      })) {
        question.errors.push('Column Labels cannot be left blank.');
        res = false;
      }

      if (_.uniq(scale_values).length !== scale_values.length) {
        question.errors.push('Column Label values must be unique.');
        res = false;
      }

      if (question.scale.values.length > question.scale.options.length) {
        question.errors.push(
          'For radio ranking questions, the number of rows needs to be equal to or greater to the columns.');
        res = false;
      }

      if (!clientQuestionValidate.checkValidPercentageWidth(question.scale.values)) {
        question.errors.push('Invalid percentage value for the column width');
        res = false;
      }

      let totalPercentage = _.reduce(question.scale.values, function(sum, value) {
        return sum + value.percentage_width;
      }, 0);
      let remainingPercentage = 100 - totalPercentage;
      if (remainingPercentage < 20) {
        question.errors.push(
          'The total width of all columns must be under 100 percent.');
        res = false;
      }

      return res;
    };

    clientQuestionValidate.validatePointsQuestion = function ($scope, question, scale_lists) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      if (!clientQuestionValidate.checkBlankOptions(question.options)) {
        question.errors.push('Option may not be blank');
        res = false;
      }

      if (!clientQuestionValidate.checkUniqueOptions(question.options)) {
        question.errors.push('Options must be unique');
        res = false;
      }

      if (question.options.length < 2) {
        question.errors.push('Please add at least 2 options');
        res = false;
      }

      if (question.total_points == null || !angular.isDefined(question.total_points)) {
        question.errors.push('Please enter a valid value for Total Points');
        res = false;
      }

      if (question.total_points != null && question.total_points < 1) {
        question.errors.push('Total Points need to be greater than 0');
        res = false;
      }

      if (question.total_points != null && question.total_points % 1 !== 0) {
        question.errors.push('Total points need to be an integer value');
        res = false;
      }

      if (question.total_points != null && question.total_points > 100000) {
        question.errors.push('Total points must be between 1 and 100000');
        res = false;
      }

      return res;
    };

    clientQuestionValidate.validateVanWestendorpQuestion = function ($scope, question, isAdminAmendment) {
      var res = true;

      if (question.text == '' || question.text == null) {
        question.errors.push('Please enter some question text to continue.');
        res = res && false;
      }
      if (question.pricing_model.question_acceptable === '' || question.pricing_model.question_acceptable == null) {
        question.errors.push('Please enter acceptable price question text to continue.');
        res = res && false;
      }
      if (question.pricing_model.question_expensive === '' || question.pricing_model.question_expensive == null) {
        question.errors.push('Please enter expensive price question text to continue.');
        res = res && false;
      }
      if (question.pricing_model.question_too_expensive === '' || question.pricing_model.question_too_expensive == null) {
        question.errors.push('Please enter too expensive price question text to continue.');
        res = res && false;
      }
      if (question.pricing_model.question_prohibitive === '' || question.pricing_model.question_prohibitive == null) {
        question.errors.push('Please enter prohibitive price question text to continue.');
        res = res && false;
      }

      if (isAdminAmendment) {
        return res;
      }

      if (!question.pricing_model.use_percentage_instead_of_currency && (question.pricing_model.currency === '' || question.pricing_model.currency == null)) {
        question.errors.push('Please select currency to continue.');
        res = res && false;
      }
      if (question.pricing_model.min_price === '' || question.pricing_model.min_price == null) {
        question.errors.push('Please correct min price to continue.');
        res = res && false;
      }
      if (question.pricing_model.max_price === '' || question.pricing_model.max_price == null) {
        question.errors.push('Please correct max price to continue.');
        res = res && false;
      }
      if (question.pricing_model.min_price > question.pricing_model.max_price) {
        question.errors.push('Min price can\'t be greater than max price');
        res = res && false;
      }

      return res;
    };

    function isSorted(array) {
      return array.every(function(num, idx, arr) {
        return (num <= arr[idx + 1]) || (idx === arr.length - 1) ? 1 : 0;
      });
    }

    clientQuestionValidate.validateGaborGrangerQuestion = function ($scope, question, isAdminAmendment) {
      $scope.error_list = [];
      var res = true;

      if (stringHelper.isNullOrEmpty(question.text)) {
        $scope.error_list.push('Please enter some question text to continue.');
        res = res && false;
      }

      if (!stringHelper.isNullOrEmpty(question.text) &&
        question.text.indexOf('#price#') === -1) {
        var msg = 'A #price# keyword is missing from the question text. ' +
          'This keyword (#price#) will be replaced by the actual price in ' +
          'the final question rendered to the user. ' +
          'Please add this to the question text to continue.';
        question.errors.push(msg);
        res = res && false;
      }

      if (!stringHelper.isNullOrEmpty(question.pricing_model.question_subsequent) &&
        question.pricing_model.question_subsequent.indexOf('#price#') === -1) {

        msg = 'A #price# keyword is missing from the subsequent question text. ' +
          'This keyword (#price#) will be replaced by the actual price in ' +
          'the final question rendered to the user. ' +
          'Please add this to the subsequent question text to continue.';
        question.errors.push(msg);
        res = res && false;
      }

      if (isAdminAmendment) {
        return res;
      }

      if (!question.pricing_model.use_percentage_instead_of_currency && stringHelper.isNullOrEmpty(question.pricing_model.currency)) {
        question.errors.push('Please select currency to continue.');
        res = res && false;
      }

      if (!question.pricing_model.max_questions) {
        question.errors.push('Please select maximum questions');
        res = res && false;
      }

      if (stringHelper.isNullOrEmpty(question.pricing_model.max_price)) {
        question.errors.push('Please correct max price to continue.');
        res = res && false;
      }

      if (stringHelper.isNullOrEmpty(question.pricing_model.min_price)) {
        question.errors.push('Please correct min price to continue.');
        res = res && false;
      }

      if (stringHelper.isNullOrEmpty(question.pricing_model.min_step)) {
        question.errors.push('Please correct precision to continue.');
        res = res && false;
      }

      if (!stringHelper.isNullOrEmpty(question.pricing_model.first_price) &&
        !stringHelper.isNullOrEmpty(question.pricing_model.min_price) &&
        !stringHelper.isNullOrEmpty(question.pricing_model.min_step)) {

        var first_price = parseFloat(question.pricing_model.first_price);

        if (isNaN(first_price)) {
          question.errors.push('Please correct first price to continue.');
          res = res && false;
        } else if (first_price > question.pricing_model.max_price ||
          first_price < question.pricing_model.min_price) {
          question.errors.push('Please correct first price to continue.');
          res = res && false;
        } else if ((Math.round(100.0 * question.pricing_model.first_price) - Math.round(100.0 * question.pricing_model.min_price)) % Math.round(
          100.0 * question.pricing_model.min_step) != 0) {
          question.errors.push('First price is not one of possible prices');
          res = res && false;
        }
      }

      var checked_levels = $scope.cur_question.pricing_model.answer_levels;
      if (!checked_levels || checked_levels.length < 2) {
        question.errors.push('Please add at least 2 answer levels.');
        res = res && false;
      } else {
        if (!clientQuestionValidate.checkUniqueOptions(checked_levels, 'name')) {
          question.errors.push('Answer level captions should not be duplicate.');
          res = res && false;
        }

        for (var i = 0; i < checked_levels.length; i++) {
          if (!checked_levels[i].name ||
            stringHelper.isNullOrEmpty(checked_levels[i].meaning)) {
            question.errors.push('Please fill all checked answer levels.');
            res = res && false;
            break;
          }
        }

        if (res) {
          var count_positive = 0, count_negative = 0;
          for (i = 0; i < checked_levels.length; i++) {
            if (checked_levels[i].meaning > 0) {
              count_positive += 1;
            }
            if (checked_levels[i].meaning < 0) {
              count_negative += 1;
            }
          }

          if (!count_positive) {
            question.errors.push('At least one answer level should be positive.');
            res = res && false;
          }

          if (!count_negative) {
            question.errors.push('At least one answer level should be negative.');
            res = res && false;
          }
        }

        if (res) {
          var meaningOrder = _.pluck(checked_levels, 'meaning');
          var isAscending = isSorted(meaningOrder);
          var isDescending = isSorted(meaningOrder.reverse());
          if ( !isAscending && !isDescending ) {
            question.errors.push(
              'Answer levels should appear in this order: Negative -> Positive OR Positive -> Negative');
            res = res && false;
          }
        }
      }

      return res;
    };

    clientQuestionValidate.validateSliderRatingQuestion = function ($scope, question, scale_lists) {
      var res = true;

      if (!question.text) {
        question.errors.push('Please enter a question');
        res = false;
      }

      if (question.scale.values == null || question.scale.values.length < 1) {
        question.errors.push('Slider question needs atleast 2 grid points to be saved');
        res = false;
      }

      if (!clientQuestionValidate.isValidScale(question.scale)) {
        question.errors.push('Please enter valid min and max values');
        res = false;
      }

      if (parseFloat(question.scale.values[0].value) >= parseFloat(question.scale.values[question.scale.values.length - 1].value)) {
        question.errors.push('Minimum should be greater than Maximum');
        res = false;
      }

      if (question.scale.decimal_places == null || (question.scale.decimal_places < 0 || question.scale.decimal_places > 2) ) {
        question.errors.push('Please enter a valid value for Number of decimal field');
        res = false;
      }

      if (_.uniq(question.scale.captions).length !== question.scale.captions.length) {
        question.errors.push('Please remove duplicate column labels');
        res = false;
      }

      if (_.contains(question.scale.captions, null) || _.contains(question.scale.captions, '') || _.contains(question.scale.captions, undefined) ) {
        question.errors.push('Column labels should not have empty values. Remove empty labels if required.');
        res = false;
      }

      if (!clientQuestionValidate.checkBlankOptions(question.options)) {
        question.errors.push('Option may not be blank');
        res = false;
      }

      if (!clientQuestionValidate.checkUniqueOptions(question.options)) {
        question.errors.push('Options must be unique');
        res = false;
      }

      if (!clientQuestionValidate.checkDefaultOptionsValidity(question.options, question.scale.values)) {
        question.errors.push('Default initial scale value should be within scale values limits. Please adjust and move sliders to fix the issue');
        res = false;
      }

      return res;
    };


    clientQuestionValidate.validateQuestion = function ($scope, question, scale_lists, tor_scale) {
      if (question.type == CONSTANTS.questionTypeIds.FREE_TEXT) {
        return clientQuestionValidate.validateFreeTextQuestion($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.DROPDOWN_LIST) {
        return clientQuestionValidate.validateQuestionWithOptions($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.RADIO_BUTTONS) {
        return clientQuestionValidate.validateQuestionWithOptions($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.CHECK_BOXES) {
        return clientQuestionValidate.validateQuestionWithOptions($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.RATING) {
        return clientQuestionValidate.validateRatingScaleQuestion($scope, question, scale_lists);
      }

      if (question.type == CONSTANTS.questionTypeIds.DRAG_DROP_RANKING) {
        return clientQuestionValidate.validateDragDropQuestion($scope, question, scale_lists);
      }

      if (question.type == CONSTANTS.questionTypeIds.RADIO_RANKING) {
        return clientQuestionValidate.validateRadioRankingQuestion($scope, question, scale_lists);
      }

      if (question.type == CONSTANTS.questionTypeIds.POINTS_ALLOCATION) {
        return clientQuestionValidate.validatePointsQuestion($scope, question, scale_lists);
      }

      if (question.type == CONSTANTS.questionTypeIds.VAN_WESTENDORP_PRICING_MODEL) {
        return clientQuestionValidate.validateVanWestendorpQuestion($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.GABOR_GRANGER_PRICING_MODEL) {
        return clientQuestionValidate.validateGaborGrangerQuestion($scope, question);
      }

      if (question.type == CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE) {
        return clientQuestionValidate.validateSliderRatingQuestion($scope, question);
      }
    };
  }]);
