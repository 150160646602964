app.directive('onlyDigits', function () {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        if (inputValue == undefined) {
          return '';
        }

        if (attrs.skipdigits == 'true') {
          return inputValue;
        }

        var transformedInput = inputValue.toString().replace(/[^0-9]/g, '');

        if (transformedInput.length > 0){
          if (attrs.maxAllowed !== undefined){
            transformedInput = Math.min( parseInt(attrs.maxAllowed), parseInt(inputValue) );
          }

          if (attrs.minAllowed !== undefined){
            transformedInput = Math.max( parseInt(attrs.minAllowed), transformedInput );
          }
        }

        if (transformedInput !== inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }

        return transformedInput;
      });
    }
  };
});



