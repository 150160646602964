app.filter('pendingClarResponsesByCountryAndResponses', ['$filter', function ($filter) {

  return function (input, countryName, responses) {

    if (!input) {
      return input;
    }

    var cur_response_ids = _.pluck($filter('filter')(responses, { 'country_name': countryName }),
      'id');

    return $filter('filter')(input, function (item) {
      return (cur_response_ids.indexOf(item.response) != -1 && !item.is_approved && !item.is_rejected);
    });
  };
}]);
