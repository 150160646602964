app.directive('convertToString', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function (val) {
        return val != null ? val.toString() : null;
      });

      ngModel.$formatters.push(function (val) {
        return val != null ? '' + val : null;
      });
    }
  };
});
