app.service('scaleService', ['HttpAuth', 'portalHelper', 'apiHelper', '$q', 'CONSTANTS',
  function (HttpAuth, portalHelper, apiHelper, $q, CONSTANTS) {
    this.numberOfColumnsChanged = function (scale) {
      scale.columnWidth = (96 / scale.numberOfColumns).toFixed() + '%';

      var diff = scale.numberOfColumns - scale.values.length;
      if (diff > 0) {
        for (var i = 0; i < diff; i++) {
          scale.values[scale.values.length] = { 'value_name': '', 'value': i + 1 };
        }
      } else if (diff < 0) {
        for (i = diff; i < 0; i++) {
          scale.values.pop();
        }
      }

      for (i = 0; i < scale.numberOfColumns; i++) {
        scale.values[i].value = i + 1;
      }
    };

    this.removeScaleValue = function (scale, index) {
      if (scale.values.length === 1) {
        return;
      }

      scale.values.splice(index, 1);
      scale.numberOfColumns = scale.values.length;
      this.numberOfColumnsChanged(scale);
    };

    this.validate = function (scale, error_list) {
      var res = true;
      error_list = [];

      var rankingTypes = [CONSTANTS.scaleTypes.RANKING, CONSTANTS.scaleTypes.RADIO_RANKING];
      var scalesTwoValues = [
        CONSTANTS.scaleTypes.RATING,
        CONSTANTS.scaleTypes.RANKING,
        CONSTANTS.scaleTypes.SLIDER_RATING_SCALE,
        CONSTANTS.scaleTypes.RADIO_RANKING
      ];

      if (!scale.name) {
        error_list.push('Please enter a scale name');
        res = false;
      }

      if (scalesTwoValues.indexOf(scale.scale_type) > -1 &&
        scale.values.length < 2
      ) {
        error_list.push('Please enter at least 2 scale values');
        res = false;
      }

      if (scale.scale_type === portalHelper.scaleTypeIds.RANKING) {
        if (scale.options.length < 2) {
          error_list.push('Please enter at least 2 options to rank');
          res = false;
        }

        var itemsToRank = scale.items_to_rank;
        if (itemsToRank !== null && (itemsToRank < 1 || itemsToRank > scale.options.length)) {
          res = false;
          error_list.push('Please enter a number between 1 and ' + scale.options.length +
            ' in the No. of items to rate field.');
        }
      }

      if (scale.scale_type === portalHelper.scaleTypeIds.SLIDER_RATING) {
        if (_.uniq(scale.captions).length !== scale.captions.length) {
          res = false;
          error_list.push('Please remove duplicate column labels');
        }

        if (_.contains(scale.captions, null) || _.contains(scale.captions, '') || _.contains(scale.captions, undefined)) {
          res = false;
          error_list.push('Column labels should not have empty values. Remove empty labels if required.');
        }
      }

      if (scale.options && scale.options.length > 0) {
        for (var i = 0; i < scale.options.length; i++) {
          if (!scale.options[i].option_name[0] || scale.options[i].option_name[0].trim() == '') {
            res = false;
            error_list.push('Scale options can\'t be blank');
            break;
          }
        }
      }

      return { 'valid': res, 'error_list': error_list };
    };

    this.mapSliderValueToScaleId = function(response, questions) {
      var question = questions.find(question => question.id == response.question);
      if (question.type === CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE) {
        response.rating_responses.forEach(function (ratingResponse) {
          if (!ratingResponse.non_discrete_scale_value) {
            const scale = question.scale.values.find(val => val.value == ratingResponse.value);
            if (scale) {
              ratingResponse.value = scale.id;
            }
          }
          ratingResponse.value_name = '';
        });

        if (response.payer_value !== null) {
          const scale = question.scale.values.find(val => val.value == response.payer_value);
          if (scale) { response.payer_value = scale.id; }
        }
      }
    };


    this.scaleDataCleaner = function(questionType, scaleData){

      switch (questionType){
        case CONSTANTS.questionTypeIds.RATING:
          scaleData.options = _.map(scaleData.options, function(option) {
            return option;
          });

          delete scaleData.items_to_rank;
          delete scaleData.heading_columns;

          return scaleData;

        case CONSTANTS.questionTypeIds.DRAG_DROP_RANKING:
          scaleData.options = _.map(scaleData.options, function(option) {
            option.option_name = [option.option_name[0]];
            return option;
          });

          delete scaleData.description;
          delete scaleData.heading_rows;
          delete scaleData.heading_columns;

          return scaleData;

        case CONSTANTS.questionTypeIds.RADIO_RANKING:
          scaleData.options = _.map(scaleData.options, function(option) {
            option.option_name = [option.option_name[0]];
            return option;
          });

          delete scaleData.description;

          if (Array.isArray(scaleData.heading_rows) && scaleData.heading_rows.length > 1) {
            scaleData.heading_rows = [scaleData.heading_rows[0]];
          }

          if (Array.isArray(scaleData.heading_columns) && scaleData.heading_columns.length > 1) {
            scaleData.heading_columns = [scaleData.heading_columns[0]];
          }

          return scaleData;

        default:
          return scaleData;
      }
    };


    var initiate_scale = function ($scope) {
      var scale = $scope.cur_question.scale;

      switch ($scope.cur_question.type) {
        case $scope.questionTypeIds.RADIO_RANKING:
          if (!scale) {
            scale = {
              'scale_type': CONSTANTS.scaleTypes.RADIO_RANKING,
              'heading_rows': []
            };
          } else {
            scale.scale_type = CONSTANTS.scaleTypes.RADIO_RANKING;
          }

          if (!scale.values || scale.values.length === 0) {
            scale.values = [{ 'value_name': '', 'value': 1 }, {
              'value_name': '',
              'value': 2
            }, { 'value_name': '', 'value': 3 }];
          }

          if (!scale.options || scale.options.length === 0) {
            scale.options = [{ 'option_name': [''] }, { 'option_name': [''] }, { 'option_name': [''] }];
          }

          if (!scale.numberOfColumns) {
            scale.numberOfColumns = 3;
            scale.columnWidth = (96 / scale.numberOfColumns).toFixed() + '%';
          }

          $scope.cur_question.scale = scale;
          break;

        case $scope.questionTypeIds.DRAG_DROP_RANKING:
          if (!scale) {
            scale = {
              'scale_type': CONSTANTS.scaleTypes.RANKING
            };
          } else {
            scale.scale_type = CONSTANTS.scaleTypes.RANKING;
          }

          if (!scale.options || scale.options.length === 0) {
            scale.options = [{ 'option_name': [''] }, { 'option_name': [''] }, { 'option_name': [''] }];
            scale.items_to_rank = scale.options.length.toString();
          }

          $scope.cur_question.scale = scale;
          break;

        case $scope.questionTypeIds.RATING:
          if (!scale) {
            scale = {
              'scale_type': CONSTANTS.scaleTypes.RATING
            };
          } else {
            scale.scale_type = CONSTANTS.scaleTypes.RATING;
          }

          if (!scale.values || scale.values.length === 0) {
            scale.values = [{ 'value_name': '', 'value': 1 }, {
              'value_name': '',
              'value': 2
            }, { 'value_name': '', 'value': 3 }];
          }

          if (!scale.numberOfColumns) {
            scale.numberOfColumns = 3;
            scale.columnWidth = (96 / scale.numberOfColumns).toFixed() + '%';
          }

          $scope.cur_question.scale = scale;
          break;

        case $scope.questionTypeIds.SLIDER_RATING_SCALE:
          if (!scale) {
            scale = {
              'scale_type': CONSTANTS.scaleTypes.SLIDER_RATING,
              'snap_to_discrete': true,
              'captions': Array(3).fill(undefined),
              'decimal_places': 0
            };
          } else {
            scale.scale_type = CONSTANTS.scaleTypes.SLIDER_RATING;
          }

          if (!scale.values || scale.values.length === 0) {
            scale.values = Array(11).fill().map((_, i) => {
              return { 'value_name': '', 'value': i*10 };
            });
          }
          $scope.cur_question.scale = scale;
          break;
      }

      if ($scope.cur_question.rating_scale) {
        $scope.cur_question.scale.id = null;
      }
    };

    this.loadScale = function ($scope) {
      if ($scope.cur_question.rating_scale) {
        var url = apiHelper.getApiUrl() + '/api/authoring/scale/' + $scope.cur_question.rating_scale + '.json';

        return HttpAuth.get(url).then(
          function (result) {
            $scope.cur_question.scale = result.data;
            $scope.cur_question.originalScaleType = $scope.cur_question.scale.scale_type;
            $scope.cur_question.scale.numberOfColumns = $scope.cur_question.scale.values.length;
            $scope.cur_question.scale.columnWidth = (96 / $scope.cur_question.scale.numberOfColumns).toFixed() + '%';

            initiate_scale($scope);
          }
        );
      } else {
        initiate_scale($scope);
      }
    };

  }]);
