
app.service('clientSurveyHelper',
  ['CONSTANTS', 'portalHelper', 'dataConvert', '$filter', '$rootScope',
    function (CONSTANTS, portalHelper, dataConvert, $filter, $rootScope) {
      var clientSurveyHelper = this;

      clientSurveyHelper.filterGroupByCountry = function (section, scope) {
        if (!angular.isDefined(scope.selected.country) || !scope.selected.country) {
          return true;
        }

        var group_questions = $filter('filter')(scope.questions, { 'section': section.id });

        for (var i = 0; i < group_questions.length; i++) {
          if (group_questions[i].countries.indexOf(scope.selected.country) !== -1) {
            return true;
          }
        }
        return false;
      };

      clientSurveyHelper.surveyValidateFromProject = function ($scope) {
        var result = true;
        $scope.error_list = [];
        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        if (!$scope.survey.start_date) {
          $scope.error_list.push('Please enter fielding date');
          result = false;
        }

        if (!$scope.survey.read_out_date && $scope.survey.share_report) {
          $scope.error_list.push('Please enter read out date');
          result = false;
        }

        return result;
      };

      clientSurveyHelper.surveyValidate = function ($scope) {
        var result = true;
        $scope.error_list = [];

        if (!$scope.survey.name) {
          $scope.error_list.push('Please enter a survey name');
          result = false;
        }

        if (!$scope.survey.start_date) {
          $scope.error_list.push('Please enter fielding date');
          result = false;
        }

        if (new Date($scope.survey.start_date) < new Date().setHours(0, 0, 0, 0)) {
          $scope.error_list.push('Fielding date can not be in the past');
          result = false;
        }

        return result;
      };

      clientSurveyHelper.surveyCountriesValidate = function ($scope) {
        var result = true;
        $scope.error_list = [];

        if (!$scope.survey.countries || !$scope.survey.countries.length) {
          $scope.error_list.push('Choose your target countries');
          result = false;
        } else {
          for (var i = 0; i < $scope.survey.survey_countries.length; i++) {
            var cur_country = $scope.survey.survey_countries[i];
            if (!cur_country.estimated_question_count) {
              $scope.error_list.push('Please fill estimated number of questions');
              result = false;
              break;
            }
          }
        }

        return result;
      };

      clientSurveyHelper.projectValidate = function ($scope) {
        var result = true;
        $scope.error_list = [];

        if ($scope.project.question_count && ($scope.project.question_count < 1 || $scope.project.question_count > 50)) {
          $scope.error_list.push('Number of questions must be between 1 and 50');
          result = false;
        }

        return result;
      };
    }]
);
