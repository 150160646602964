app.service('sessionStorageService', [ '$window',
  function ($window) {
    var service = this;

    var isSessionStorageAvailable = function () {
      return typeof($window.sessionStorage) !== 'undefined';
    };

    service.set_item = function (key, value) {
      if (isSessionStorageAvailable()) {
        $window.sessionStorage.setItem(key, JSON.stringify(value));
        return true;
      }

      return false;
    };

    service.get_item = function (key) {
      if (isSessionStorageAvailable()) {
        var value = $window.sessionStorage.getItem(key);
        if (value != null) {
          return JSON.parse(value);
        }
      }

      return null;
    };

    service.remove_item = function (key) {
      if (isSessionStorageAvailable()) {
        $window.sessionStorage.removeItem(key);
        return true;
      }

      return false;
    };

    service.clear = function () {
      if (isSessionStorageAvailable()) {
        $window.sessionStorage.clear();
        return true;
      }

      return false;
    };
  }]
);
