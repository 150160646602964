app.controller('authCheckController',
  ['authHelper', 'portalHelper', 'apiHelper', 'HttpAuth', '$location',
    function (authHelper, portalHelper, apiHelper, HttpAuth, $location) {
      if (authHelper.isPublicRoute()) {
        return;
      }

      var checkIf2FARequired = function (myDetails) {
        if (myDetails.tfa_mandatory && !myDetails.tfa_configured) {
          var redirectUrl = $location.protocol() + '://' + $location.host();
          if ($location.port()) {
            redirectUrl += (':' + $location.port() + '/#');
          }
          window.location.href = apiHelper.getApiUrl() + '/auth/setup-tfa/?next=' + redirectUrl;
        }
      };

      authHelper.isLoggedIn().then(
        function (result) {
          var myDetails = result.data;
          if (myDetails) {
            checkIf2FARequired(myDetails);
          }
        },
        function (result) {
          if ('abort' != result.xhrStatus) {
            authHelper.doLogoutAll(true);
          }
        }
      );
    }
  ]
);
