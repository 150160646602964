app.service('clientSurveyManageQuestionsControllerData', function () {
  function get_default_question_data() {
    return {
      type: 1,
      rationale_type: 0,
      allow_payer_option: 0,
      text: '',
      instructions: '',
      instruction_above: '',
      documents: [],
      client_preview: true
    };
  }

  this.cur_question_data = get_default_question_data();
  this.set_data = function (question) {
    this.cur_question_data = angular.copy(question);
  };

  this.set_data_attribute = function (attribute_name, attribute_value) {
    this.cur_question_data[attribute_name] = attribute_value;
  };

  this.get_data = function () {
    var ret = this.cur_question_data;
    this.cur_question_data = get_default_question_data();
    return ret;
  };
});
