app.service('menuHeaderClocksService', ['$rootScope', '$interval', 'moment',
  function ($rootScope, $interval, moment) {
    var service = this;

    $rootScope.rootscope_header_currentTime = moment();

    service.startClockTicks = function () {
      $interval(function () {
        $rootScope.rootscope_header_currentTime = moment();
      }, 1000);
    };

    service.fetchCurrentTimeFor = function (timezone) {
      return $rootScope.rootscope_header_currentTime.tz(timezone).format('HH:mm z');
    };
  }]);
