app.directive('questionsAndResponses',
  ['portalHelper', 'CONSTANTS', '$filter', 'HttpAuth', 'apiHelper', '$routeParams', 'layoutService', 'stringHelper',
    function (portalHelper, CONSTANTS, $filter, HttpAuth, apiHelper, $routeParams, layoutService, stringHelper) {
      return {
        scope: {
          surveyDetails: '=',
          questions: '=',
          responses: '=',
          sections: '=',
          layout: '=',
          isCompleteSurvey: '@',
          screenName: '@',
          clarRequests: '=',
          clarResponses: '=',
          loadData: '&'
        },
        templateUrl: portalHelper.getUrlRev('html_templates/directives/questions_and_responses.html'),
        controller: function ($scope) {
          $scope.getQuestionNumber = portalHelper.getQuestionNumber;
          $scope.formatGaborGrangerQuestionText = portalHelper.formatGaborGrangerQuestionText;
          $scope.CONSTANTS = CONSTANTS;
          $scope.isNullOrEmpty = stringHelper.isNullOrEmpty;
          $scope.getFlagUrl = portalHelper.getFlagUrl;

          $scope.get_clarification_count = portalHelper.get_clarification_count;
          $scope.toggle_rejection_body = portalHelper.toggle_rejection_body;
          $scope.is_last_payer_clarification = portalHelper.is_last_payer_clarification;

          $scope.getQuestionExpanded = function (question) {
            var response1 = $filter('filter')($scope.responses, { 'question_id': question.id });
            response1 = (response1 && response1.length > 0);
            var clarRequest1 = $filter('filter')($scope.clarRequests, { 'question': question.id });
            clarRequest1 = (clarRequest1 && clarRequest1.length > 0);

            if ($scope.screenName === 'client_clarifications') {
              return response1 && clarRequest1;
            } else if ($scope.screenName === 'client_responses') {
              return response1;
            }

            return true;
          };

          $scope.getQuestionNumberForLayoutElement = function (le, layout) {
            if (le.number_label) {
              return le.number_label;
            }

            var index = _.indexOf(_.pluck(_.filter(layout, function (val) {
              return val.question != null;
            }), 'question'), le.question);

            if (index === -1) {
              return le.element_order + 1;
            }

            return index + 1;
          };

          $scope.canShowQuestion = function (le, questions) {
            if (le.question) {
              return _.indexOf(_.pluck(questions, 'id'), le.question) > -1;
            }

            return false;
          };
        }
      };
    }]
);
