app.service('CONSTANTS', function () {
  var constantsService = this;
  constantsService.siteTitle = 'Genesis Research Group';
  constantsService.TAB_KEY = 9;
  constantsService.LOGIN_DETAILS_KEY = 'myDetails';
  const SECONDS_TO_MILLISECONDS = 1000; 
  constantsService.HEARTBEAT_INTERVAL_SECONDS = 30; 
  constantsService.HEARTBEAT_TIMEOUT = constantsService.HEARTBEAT_INTERVAL_SECONDS * SECONDS_TO_MILLISECONDS; 

  constantsService.SUB_SESSION_HEADER_KEY = 'X-SubSessionId';
  constantsService.REQUEST_TIMEOUT = 60 * SECONDS_TO_MILLISECONDS; 


  constantsService.minCharges = {
    QUESTION_DEVELOPMENT: 30,
    BACKGROUND_MATERIAL_DEVELOPMENT: 20,
    INSIGHT_REPORT: 50
  };

  constantsService.payerResponseReviewStatus = {
    UNREAD: 'unread',
    READ: 'read',
    WAITING: 'waiting'
  };

  constantsService.payerRelevanceRatings = {
    NO_RATING: {rating: null, label: 'No Rating'},
    LOW_RELEVANCE: {rating: 1, label: 'Low Relevance'},
    ACCEPTABLE_RELEVANCE: {rating: 2, label: 'Acceptable Relevance'},
    HIGH_RELEVANCE: {rating: 3, label: 'High Relevance'}
  };

  constantsService.payerResponseReviewStatusLookup = {
    'unread': { 'title': 'Unread', 'class': 'fa fa-fw fa-exclamation-triangle gray-icon' },
    'read': { 'title': 'Read. All good.', 'class': 'fa fa-fw fa-check-circle green-icon' },
    'waiting': {
      'title': 'Clarifications raised. Waiting.',
      'class': 'fa fa-fw fa-clock-o orange-icon'
    }
  };

  constantsService.adminPayerViewTabs = {
    about_the_payer: 'About the Payer',
    activity_report: 'Activity Report',
    payer_expertise: 'Payer Expertise',
    kol_expertise: 'KOL Expertise',
    intercom_conversations: 'Intercom Conversations',
    payer_notes: 'Payer Notes'
  };

  constantsService.surveyStateNames = {
    1: 'Fielded',
    2: 'Scheduled',
    3: 'Approved'
  };

  constantsService.ggAnswerLevelMeanings = [
    { 'value': -1, 'name': 'Negative' },
    { 'value': 1, 'name': 'Positive' }
  ];

  constantsService.ggPredefinedAnswerLevels = [
    { 'name': 'Prohibitively expensive', 'meaning': -1, 'level_order': 0 },
    { 'name': 'Too expensive', 'meaning': -1, 'level_order': 1 },
    { 'name': 'Expensive', 'meaning': 1, 'level_order': 2 },
    { 'name': 'Acceptable', 'meaning': 1, 'level_order': 3 }
  ];

  constantsService.regex_positive_int = /^[1-9][0-9]*$/;

  constantsService.trumbowygConfig = '{removeformatPasted: true, btns: [\'viewHTML\', \'|\', [\'strong\',\'|\',\'em\',\'|\',\'underline\',\'|\',\'del\'], \'|\', [\'justifyLeft\',\'|\',\'justifyCenter\',\'|\',\'justifyRight\',\'|\',\'justifyFull\'], \'|\', [\'unorderedList\', \'|\', \'orderedList\'], \'|\', \'link\']}';
  constantsService.trumbowygAutogrowConfig = '{removeformatPasted: true, autogrow: true, minimalLinks:true, btns: [\'viewHTML\', \'|\', [\'strong\',\'|\',\'em\',\'|\',\'underline\',\'|\',\'del\'], \'|\', [\'justifyLeft\',\'|\',\'justifyCenter\',\'|\',\'justifyRight\',\'|\',\'justifyFull\'], \'|\', [\'unorderedList\', \'|\', \'orderedList\'], \'|\', \'link\'], tagsToRemove: [\'script\', \'link\']}';

  constantsService.otherOptions = {
    NO_OTHER: 0,
    SINGLE_OTHER: 1,
    MULTIPLE_OTHER: 2
  };

  constantsService.scaleTypes = {
    RATING: 1,
    RANKING: 2,
    RADIO_RANKING: 4,
    SLIDER_RATING: 5
  };

  constantsService.responseStatuses = {
    DRAFT: 'draft',
    SUBMITTED: 'submitted',
    APPROVED: 'approved'
  };

  constantsService.surveyStatuses = {
    SCOPING: 'scoping',
    QUESTION_DEVELOPMENT: 'question_development',
    COMPLIANCE: 'compliance',
    FIELDING_AND_ANALYSIS: 'fielding_and_analysis',
    FOLLOW_UP: 'follow_up',
    DELIVERED: 'delivered',
    ON_HOLD: 'on_hold',
    CANCELLED: 'cancelled',
    DELETED: 'deleted'
  };

  constantsService.openSurveyStatusDisplayNames = {
    QUESTION_DEVELOPMENT: 'Question Development',
    COMPLIANCE: 'Compliance',
    FIELDING_AND_ANALYSIS: 'Fielding and Analysis',
    FOLLOW_UP: 'Follow Up'
  };

  constantsService.onHoldMilestonesAllowed = [
    constantsService.surveyStatuses.SCOPING,
    constantsService.surveyStatuses.QUESTION_DEVELOPMENT,
    constantsService.surveyStatuses.COMPLIANCE
  ];

  constantsService.surveyStatusesDisableEditing = [
    constantsService.surveyStatuses.FOLLOW_UP,
    constantsService.surveyStatuses.DELIVERED,
    constantsService.surveyStatuses.CANCELLED,
    constantsService.surveyStatuses.DELETED
  ];

  constantsService.questionTypeIds = {
    FREE_TEXT: 1,
    DROPDOWN_LIST: 2,
    RADIO_BUTTONS: 3,
    CHECK_BOXES: 4,
    RATING: 5,
    DRAG_DROP_RANKING: 6,
    RADIO_RANKING: 8,
    POINTS_ALLOCATION: 9,
    VAN_WESTENDORP_PRICING_MODEL: 10,
    GABOR_GRANGER_PRICING_MODEL: 11,
    TABLE_OF_RESPONSES_2: 12,
    SLIDER_RATING_SCALE : 13
  };

  constantsService.multiRationaleQuestions = [
    constantsService.questionTypeIds.CHECK_BOXES,
    constantsService.questionTypeIds.RATING,
    constantsService.questionTypeIds.POINTS_ALLOCATION,
    constantsService.questionTypeIds.VAN_WESTENDORP_PRICING_MODEL,
    constantsService.questionTypeIds.RADIO_RANKING,
    constantsService.questionTypeIds.SLIDER_RATING_SCALE
  ];

  constantsService.singleRationaleQuestions = [
    constantsService.questionTypeIds.FREE_TEXT,
    constantsService.questionTypeIds.CHECK_BOXES,
    constantsService.questionTypeIds.RATING,
    constantsService.questionTypeIds.POINTS_ALLOCATION,
    constantsService.questionTypeIds.DROPDOWN_LIST,
    constantsService.questionTypeIds.RADIO_BUTTONS,
    constantsService.questionTypeIds.RADIO_RANKING,
    constantsService.questionTypeIds.DRAG_DROP_RANKING,
    constantsService.questionTypeIds.GABOR_GRANGER_PRICING_MODEL,
    constantsService.questionTypeIds.TABLE_OF_RESPONSES_2,
    constantsService.questionTypeIds.SLIDER_RATING_SCALE
  ];

  constantsService.shortlistPayerStatus = {
    'invite_out': 'Invite Out',
    'accepted_and_assigned': 'Accepted and Assigned',
    'back_up': 'Back Up',
    'declined': 'Declined',
    'stopped_responding': 'Stopped Responding',
    'withdrew': 'Withdrew',
    'survey_submitted': 'Survey Submitted'
  };

  constantsService.userTypeIds = {
    CLIENT: 1,
    PAYER: 2,
    SUPER_USER: 3
  };

  constantsService.matUserTypes = {
    ADMIN: 'admin',
    CLIENT: 'client',
    PAYER: 'payer'
  };

  constantsService.typeOfPage = {
    ADMIN: 'admin',
    CLIENT: 'client',
    PAYER: 'payer'
  };

  constantsService.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  constantsService.dateTimeFormat = {
    MEDIUM_24H_TIME_NO_SEC: 'dd MMM yyyy HH:mm',
    MEDIUM_24H_TIME_SEC: 'dd MMM yyyy HH:mm:ss',
    MEDIUM_12H_TIME_SEC: 'dd MMM yyyy h:mm:ss a',
    MEDIUM_12H_TIME_NO_SEC: 'dd MMM yyyy h:mm a',
    MEDIUM_ONLY_DATE: 'dd MMM yyyy',
    MEDIUM_ONLY_MONTH_YEAR: 'MMM yyyy',
    LONG_ONLY_MONTH_YEAR: 'MMMM yyyy',
    SORTING_DATE: 'yyyyMMdd',
    MONTH_YEAR: 'MMM-yy',
    API_DATE: 'yyyy-MM-dd'
  };

  constantsService.dateTimeFormatMoment = {
    MEDIUM_24H_TIME_NO_SEC: 'DD MMM YYYY HH:mm',

    MEDIUM_24H_TIME_SEC: 'DD MMM YYYY HH:mm:ss',

    MEDIUM_12H_TIME_NO_SEC_TIMEZONE: 'DD MMM YYYY h:mm A [GMT]Z',

    MEDIUM_ONLY_DATE: 'DD MMM YYYY',
    API_DATE: 'YYYY-MM-DD'
  };

  constantsService.tableOfResponseRowType = {
    QUESTION: 'Q',
    SEPARATOR: 'S'
  };

  constantsService.typeOfToasts = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
  };

  constantsService.bannerType = {
    ERROR: 'error',
    WARNING: 'warning'
  };

  constantsService.adminReports = {
    FORECAST: 1,
    RECOGNIZED_REVENUE_TOTALS: 2,
    PROJECT_MASTER: 3,
    LICENCE: 4,
    WEEKLY_CREDIT_REPORT: 5,
    CREDIT_TRANSACTION_HISTORY: 6,
    SURVEY_COST_DETAILS: 7,
    PAYERS_CLASSIFICATION: 8,
    PAYER_QUICKBOOK_DETAILS: 9,
    CLIENT_QUICKBOOK_DETAILS: 10,
    CLIENT_STATEMENT: 11,
    LICENCE_NEW: 12,
    WEEKLY_CREDIT_REPORT_NEW: 13,
    PAYER_FMV_RATES: 14
  };

  constantsService.payersTableViews = {
    GENERAL: 'g',
    QUALIFICATIONS: 'q',
    CONTACT_DETAILS: 'c',
    STATISTICS: 's',
    ASSIGNMENT: 'a',
    HONORARIUM_AND_AGREEMENT_VIEW: 'h'
  };

  constantsService.multiRationaleOptions = {
    NONE: 0,
    END_OF_QUESTION_ONLY: 1,
    FOR_EACH_ROW: 2
  };

  constantsService.surveyViewPageNavigation = {
    PROJECT_INFORMATION: 0,
    PAYER_AND_COUNTRY: 1,
    LICENCES: 2,
    MILESTONES: 3,
    QUESTIONNAIRES: 4,
    PREVIEW: 5,
    RESPONDENTS: 6,
    RESPONSES: 7,
    CLIENT_FOLDER: 8,
    NOTES: 9,
    DISCLAIMERS: 10,
    CHARGES: 11
  };

  constantsService.surveyMilestones = {
    QUESTION_DEVELOPMENT: 'question_development',
    COMPLIANCE: 'compliance',
    FIELDING_AND_ANALYSIS: 'fielding',
    READ_OUT: 'read_out',
    DELIVERED: 'delivered'
  };

  constantsService.customReportViews = {
    HYBRID: 0,
    FINANCE: 1,
    OPERATIONS: 2
  };

  constantsService.disclaimerElementTypes = {
    HEADER: 'header',
    TEXT: 'text',
    QUESTION: 'question',
    PAGE_BREAK: 'page_break'
  };

  constantsService.getPermissions = function () {
    const PERMISSIONS = {
      CAN_LIST_SURVEY_REPORT: 'misc.can_list_surveyreport',
      ADD_SURVEY_REPORT: 'misc.add_surveyreport',
      VIEW_SURVEY_REPORT: 'misc.view_surveyreport',
      CHANGE_SURVEY_REPORT: 'misc.change_surveyreport',
      DELETE_SURVEY_REPORT: 'misc.delete_surveyreport',
      CAN_SEND_SURVEY_REPORT_NOTIFICATION: 'misc.can_send_surveyreport_notification',
      VIEW_SUBSCRIPTION: 'misc.view_subscription',
      LIST_SUBSCRIPTION: 'misc.list_subscription',
      EXPORT_SUBSCRIPTION: 'misc.export_subscription',
      ADD_SUBSCRIPTION: 'misc.add_subscription',
      CHANGE_SUBSCRIPTION: 'misc.change_subscription',
      DELETE_SUBSCRIPTION: 'misc.delete_subscription',
      CHANGE_SUBSCRIPTIONUPGRADE: 'misc.change_subscriptionupgrade',
      CHANGE_SUBSCRIPTIONCREDITTRANSFER: 'misc.change_subscriptioncredittransfer',
      VIEW_SUBSCRIPTION_UPGRADE: 'misc.view_subscriptionupgrade',
      CAN_VIEW_SURVEY: 'misc.view_survey',
      CAN_ADD_SURVEY_SCREEN: 'misc.add_survey_screen',
      CAN_CHANGE_SURVEY_SCREEN: 'misc.change_survey_screen',
      CAN_UPDATE_SURVEY_GENERAL_TAB: 'misc.can_update_survey_general_tab',
      CAN_DELETE_SURVEY_SCREEN: 'misc.delete_survey_screen',
      CAN_VIEW_SURVEY_MILESTONES_SCREEN: 'misc.view_survey_milestones_screen',
      CAN_START_SURVEY_MILESTONE: 'misc.start_survey_milestone',
      CAN_REVERT_SURVEY_MILESTONE: 'misc.revert_survey_milestone',
      CAN_UPDATE_SURVEY_LICENCE: 'misc.can_update_surveylicence',
      CAN_VIEW_LICENCE_REPORT: 'mat_auth.can_view_licence_report',
      CAN_EXPORT_LICENCE_REPORT: 'mat_auth.can_export_licence_report',
      CAN_VIEW_FORECAST_REPORT: 'mat_auth.can_view_forecast_report',
      CAN_EXPORT_FORECAST_REPORT: 'mat_auth.can_export_forecast_report',
      CAN_VIEW_RECOGNISED_CREDIT_REPORT: 'mat_auth.can_view_recognised_credit_report',
      CAN_EXPORT_RECOGNISED_CREDIT_REPORT: 'mat_auth.can_export_recognised_credit_report',
      CAN_VIEW_PROJECT_MASTER_REPORT: 'mat_auth.can_view_project_master_report',
      CAN_EXPORT_PROJECT_MASTER_REPORT: 'mat_auth.can_export_project_master_report',
      CAN_VIEW_PAYER_FMV_RATES_REPORT: 'mat_auth.can_view_payer_fmv_rates_report',
      CAN_EXPORT_PAYER_FMV_RATES_REPORT: 'mat_auth.can_export_payer_fmv_rates_report',
      CAN_VIEW_WEEKLY_CREDIT_CONTROL_REPORT: 'mat_auth.can_view_weekly_credit_control_report',
      CAN_EXPORT_WEEKLY_CREDIT_CONTROL_REPORT: 'mat_auth.can_export_weekly_credit_control_report',
      CAN_VIEW_CREDIT_TRANSACTION_REPORT: 'mat_auth.can_view_credit_transaction_report',
      CAN_EXPORT_CREDIT_TRANSACTION_REPORT: 'mat_auth.can_export_credit_transaction_report',
      CAN_VIEW_PROJECT_COST_REPORT: 'mat_auth.can_view_project_cost_report',
      CAN_EXPORT_PROJECT_COST_REPORT: 'mat_auth.can_export_project_cost_report',
      CAN_VIEW_PAYER_REPORT: 'mat_auth.can_view_payer_report',
      CAN_EXPORT_PAYER_REPORT: 'mat_auth.can_export_payer_report',
      CAN_VIEW_PAYER_QUICKBOOK_REPORT: 'mat_auth.can_view_payer_quickbook_report',
      CAN_EXPORT_PAYER_QUICKBOOK_REPORT: 'mat_auth.can_export_payer_quickbook_report',
      CAN_VIEW_CLIENT_QUICKBOOK_REPORT: 'mat_auth.can_view_client_quickbook_report',
      CAN_EXPORT_CLIENT_QUICKBOOK_REPORT: 'mat_auth.can_export_client_quickbook_report',
      CAN_VIEW_CLIENT_REPORT: 'mat_auth.can_view_client_report',
      CAN_VIEW_CLIENT_SCREEN: 'misc.view_client_screen',
      CAN_ADD_CLIENT: 'misc.add_client',
      CAN_UPDATE_CLIENT: 'misc.change_client',
      CAN_EXPORT_CLIENT_LIST: 'misc.export_client',
      CAN_EXPORT_CLIENT_REPORT: 'mat_auth.can_export_client_report',
      CHANGE_SUBSCRIPTION_DATES: 'misc.change_subscription_dates',
      CAN_VIEW_PAYER: 'mat_auth.view_payer',
      CAN_VIEW_PAYER_LIST: 'mat_auth.can_view_payer_list',
      CAN_EXPORT_PAYER_LIST: 'mat_auth.can_export_payer_list',
      CAN_IMPORT_PAYER: 'mat_auth.can_import_payer',
      CAN_ADD_PAYER: 'mat_auth.add_payer',
      CAN_UPDATE_PAYER: 'mat_auth.update_payer',
      CAN_DELETE_PAYER: 'mat_auth.delete_payer',
      CAN_EXPORT_VISIBLE_APS_COLUMNS: 'mat_auth.can_export_visible_columns_aps',
      CAN_EXPORT_ALL_APS_COLUMNS: 'mat_auth.can_export_all_columns_aps',
      CAN_VIEW_OPEN_SURVEYS: 'misc.can_view_open_surveys',
      CAN_VIEW_LIVE_SURVEYS: 'misc.can_view_live_surveys',
      CAN_VIEW_SURVEYS_IN_DEVELOPMENT: 'misc.can_view_surveys_in_development',
      CAN_VIEW_ALL_SURVEYS: 'misc.can_view_all_surveys',
      CAN_VIEW_ALL_SURVEYS_SCREEN: 'misc.can_view_all_surveys_screen',
      CAN_ACCESS_ON_BEHALF_OF_PAYER: 'mat_auth.can_access_on_behalf_of_payer',
      CAN_ACCESS_ON_BEHALF_OF_CLIENT: 'mat_auth.can_access_on_behalf_of_client',
      CAN_VIEW_ASSIGNED_PAYERS: 'misc.can_view_assigned_payers',
      CAN_UPDATE_ASSIGNED_PAYERS: 'misc.can_update_assigned_payers',
      VIEW_PAYMENT: 'misc.view_payment',
      ADD_PAYMENT: 'misc.add_payment',
      CHANGE_PAYMENT: 'misc.change_payment',
      DELETE_PAYMENT: 'misc.delete_payment',
      EXPORT_PAYMENT: 'misc.export_payment',
      VIEW_BILL: 'misc.view_bill',
      ADD_BILL: 'misc.add_bill',
      CHANGE_BILL: 'misc.change_bill',
      DELETE_BILL: 'misc.delete_bill',
      VIEW_QUICKBOOKS_PAGES: 'misc.can_view_quickbooks_pages',
      CAN_VIEW_SURVEY_CREDIT_LIST: 'misc.can_view_surveycredit_list',
      CAN_VIEW_SURVEY_CREDIT: 'misc.view_surveycredit',
      CAN_ADD_SURVEY_CREDIT: 'misc.add_surveycredit',
      CAN_UPDATE_SURVEY_CREDIT: 'misc.change_surveycredit',
      CAN_DELETE_SURVEY_CREDIT: 'misc.delete_surveycredit',
      CAN_ADD_MANUAL_ADJUSTMENT: 'misc.can_add_manual_adjustment',
      CAN_UPDATE_MANUAL_ADJUSTMENT: 'misc.can_update_manual_adjustment',
      CAN_DELETE_MANUAL_ADJUSTMENT: 'misc.can_delete_manual_adjustment',
      CAN_VIEW_MANUAL_ADJUSTMENT: 'misc.can_view_manual_adjustment',
      CAN_VIEW_MATUSER_SCREEN: 'mat_auth.view_matuser_screen',
      CAN_ADD_MATUSER: 'mat_auth.add_matuser',
      CAN_UPDATE_MATUSER: 'mat_auth.change_matuser',
      VIEW_PAGE: 'misc.view_page',
      CHANGE_PAGE: 'misc.change_page',
      CAN_VIEW_DISEASEAREA: 'misc.view_diseasearea',
      CAN_ADD_DISEASEAREA: 'misc.add_diseasearea',
      CAN_UPDATE_DISEASEAREA: 'misc.change_diseasearea',
      CAN_ACTIVATE_DEACTIVATE_DISEASEAREA: 'misc.activate_deactivate_diseasearea',
      CAN_VIEW_EXPERTISE: 'misc.view_expertise',
      CAN_ADD_EXPERTISE: 'misc.add_expertise',
      CAN_UPDATE_EXPERTISE: 'misc.change_expertise',
      CAN_ACTIVATE_DEACTIVATE_EXPERTISE: 'misc.activate_deactivate_expertise',
      CAN_VIEW_QUESTIONBANK: 'misc.view_questionbank',
      CAN_ADD_QUESTIONBANK: 'misc.add_questionbank',
      CAN_UPDATE_QUESTIONBANK: 'misc.change_questionbank',
      CAN_ACTIVATE_DEACTIVATE_QUESTIONBANK: 'misc.activate_deactivate_questionbank',
      CAN_VIEW_USERLOGINATTEMPT: 'misc.view_userloginattempt',
      CAN_ADD_PAYER_FAQ: 'misc.add_payer_faq',
      CAN_VIEW_PAYER_FAQ: 'misc.view_payer_faq',
      CAN_UPDATE_PAYER_FAQ: 'misc.update_payer_faq',
      CAN_ACTIVATE_DEACTIVATE_PAYER_FAQ: 'misc.activate_deactivate_payer_faq',
      CAN_ADD_CLIENT_FAQ: 'misc.add_client_faq',
      CAN_VIEW_CLIENT_FAQ: 'misc.view_client_faq',
      CAN_UPDATE_CLIENT_FAQ: 'misc.update_client_faq',
      CAN_ACTIVATE_DEACTIVATE_CLIENT_FAQ: 'misc.activate_deactivate_client_faq',
      CAN_VIEW_ADMIN_CLARIFICATION: 'misc.can_view_admin_clarification',
      CAN_REQUEST_ADMIN_CLARIFICATION: 'misc.can_request_admin_clarification',
      CAN_APPROVE_ADMIN_CLARIFICATION: 'misc.can_approve_admin_clarification',
      CAN_VIEW_CLIENT_CLARIFICATION: 'misc.can_view_client_clarification',
      CAN_APPROVE_CLIENT_CLARIFICATION: 'misc.can_approve_client_clarification',
      CAN_LIST_SURVEY_RESPONSES: 'misc.can_list_survey_responses',
      CAN_VIEW_SURVEY_RESPONSE: 'misc.can_view_survey_response',
      CAN_REVIEW_SURVEY_RESPONSE: 'misc.can_review_survey_response',
      CAN_EXPORT_SURVEY_RESPONSE: 'misc.can_export_survey_response',
      CAN_VIEW_RELEVANCE_RATING: 'misc.can_view_relevance_rating',
      CAN_UPDATE_RELEVANCE_RATING: 'misc.can_update_relevance_rating',
      CAN_SHORTLIST_PAYERS: 'misc.can_shortlist_payers',
      CAN_VIEW_GLOBAL_DISCLAIMER: 'misc.can_view_global_disclaimer',
      CAN_UPDATE_GLOBAL_DISCLAIMER: 'misc.can_update_global_disclaimer'
    };
    return angular.copy(PERMISSIONS);
  };

  constantsService.updateUsersFlagTypes = {
    ACTIVE: 'active',
    LOCK: 'lock'
  };

  constantsService.inlineEditableComponentInputTypes  = {
    TEXTAREA: 'textarea',
    TEXT: 'text'
  };

  constantsService.creditTypes = {
    SURVEY_BACKGROUND_MATERIAL_DEVELOPMENT: {
      credit_name: 'Background Material Development',
      credit_code: 'survey_background_development'
    },
    SURVEY_QUESTION_DEVELOPMENT_MINIMUM: {
      credit_name: 'Question Development Minimum',
      credit_code: 'survey_question_development_minimum'
    },
    SURVEY_QUESTION_DEVELOPMENT_REMAINDER: {
      credit_name: 'Question Development Remainder',
      credit_code: 'survey_question_development_remainder'
    },
    SURVEY_FIELDING_CHARGE_PHASE_1: {
      credit_name: 'Fielding Charges Phase 1',
      credit_code: 'survey_fielded_phase_1'
    },
    SURVEY_FIELDING_CHARGE_PHASE_2: {
      credit_name: 'Fielding Charges Phase 2',
      credit_code: 'survey_fielded_phase_2'
    },
    SURVEY_FIELDING_CHARGE_PHASE_3: {
      credit_name: 'Fielding Charges Phase 3',
      credit_code: 'survey_fielded_phase_3'
    },
    SURVEY_INSIGHT_REPORT: {
      credit_name: 'Insight Report',
      credit_code: 'survey_insight_report'
    },
    SURVEY_DELIVERED: {
      credit_name: 'Follow up and Delivery',
      credit_code: 'survey_delivered'
    }
  };

  constantsService.creditTypeForecastDays = {
    SURVEY_QUESTION_DEVELOPMENT_MINIMUM: 1,
    SURVEY_QUESTION_DEVELOPMENT_REMAINDER: 5,
    SURVEY_FIELDING_CHARGE_PHASE_1: 10,
    SURVEY_FIELDING_CHARGE_PHASE_2: 7,
    SURVEY_FIELDING_CHARGE_PHASE_3: 7,
    SURVEY_INSIGHT_REPORT: 7
  };

});
