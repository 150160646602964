
app.service('adminLiveSurveysData', function () {
  this.cur_data = null;

  this.setData = function (questions) {
    this.cur_data = angular.copy(questions);
  };

  this.getData = function () {
    return this.cur_data;
  };
});
