app.service('multiselects', [function () {
  var theMultiselect = this;

  theMultiselect.get_multi_select_model = function (ids, big_list) {
    var model = [];
    var big_dict = {};

    if (!ids || ids == []) {
      return [];
    }

    for (var i = 0; i < big_list.length; i++) {
      big_dict[big_list[i].id] = big_list[i];
    }

    for (i = 0; i < ids.length; i++) {
      var id = ids[i];
      if (id in big_dict) {
        model.push(big_dict[id]);
      }
    }
    return model;
  };

  theMultiselect.get_multi_select_ids = function (multi_select_model) {
    var ids = [];
    if (!angular.isArray(multi_select_model)) {
      return ids;
    }

    var a = multi_select_model;
    for (var i = 0; i < a.length; i++) {
      ids.push(a[i].id);
    }
    return ids;
  };

  theMultiselect.initTableOfResponses = function ($scope) {
    $scope.multiSelectSettings = {
      selectionLimit: 1,
      scrollable: true,
      checkBoxes: false,
      template: '{{option.option_name}}'
    };
  };

  theMultiselect.multiSelectTexts = { buttonDefaultText: '' };

  theMultiselect.getMultiSelectSettings = function (maxLength, template_fields) {
    function getTemplate(template_fields) {
      if (template_fields) {
        var cur_template = [];
        for (var k = 0; k < template_fields.length; k++) {
          cur_template.push('{{option.' + template_fields[k] + '}}');
        }

        return cur_template.join(' ');
      } else {
        return '{{option.name}}';
      }
    }

    return {
      smartButtonTextProvider: function (selectionArray) {
        if (selectionArray && selectionArray.length >= 1) {
          var text = '';
          var sep = '';

          for (var i = 0; i < selectionArray.length; i++) {
            if (!template_fields) {
              text += sep + selectionArray[i].name;
            } else {
              var cur_text = [];

              for (var k = 0; k < template_fields.length; k++) {
                cur_text.push(selectionArray[i][template_fields[k]]);
              }

              text += sep + cur_text.join(' ');
            }

            sep = ', ';

            if (text.length > maxLength) {
              return selectionArray.length + ' checked';
            }
          }

          return text;
        }
      },
      scrollable: true,
      checkBoxes: true,
      template: getTemplate(template_fields),
      buttonClasses: 'mat ui secondary button'
    };
  };

  theMultiselect.init = function ($scope, maxLength) {
    $scope.multiSelectSettings = theMultiselect.getMultiSelectSettings(maxLength);
    $scope.multiSelectTexts = theMultiselect.multiSelectTexts;
    $scope.selected_country = [];
    $scope.selected_documents = [];
    $scope.selected_locality = [];
    $scope.selected_expertise = [];
    $scope.selected_experiential_expertise = [];
    $scope.selected_disease_area_category = [];
    $scope.selected_disease_area = [];
  };

  theMultiselect.disease_category_change = function ($scope, disease_area, selected_disease_area, selected_disease_area_category) {
    var selected_cat = {};

    $scope.filtered_disease_area = [];
    var filtered_disease_dict = {}; 

    var a = selected_disease_area_category;
    for (var i = 0; i < a.length; i++) {
      selected_cat[a[i].id] = 1;
    }

    a = disease_area;
    for (i = 0; i < disease_area.length; i++) {
      if (a[i].category in selected_cat) {
        $scope.filtered_disease_area.push(a[i]);
        filtered_disease_dict[a[i].id] = 1;
      }
    }

    a = selected_disease_area;
    var b = [];
    if (angular.isArray(a)) { 
      for (i = 0; i < a.length; i++) {
        if (a[i].id in filtered_disease_dict) {
          b.push(a[i]);
        }
      }
    }

    selected_disease_area = b;
  };

  theMultiselect.loadData = function ($scope) {
    $scope.selected_disease_area_category = this.get_multi_select_model(
      $scope.payer.disease_categories,
      $scope.payer_lists.disease_area_category
    );

    $scope.selected_disease_area = this.get_multi_select_model(
      $scope.payer.diseases,
      $scope.payer_lists.disease_area
    );

    $scope.selected_locality = this.get_multi_select_model(
      $scope.payer.localities,
      $scope.payer_lists.locality
    );

    $scope.selected_expertise = this.get_multi_select_model(
      $scope.payer.functional_expertises,
      $scope.payer_lists.functional_expertise
    );

    $scope.selected_experiential_expertise = this.get_multi_select_model(
      $scope.payer.experiential_expertises,
      $scope.payer_lists.experiential_expertise
    );
  };

  theMultiselect.unloadData = function ($scope) {
    $scope.payer.disease_categories = this.get_multi_select_ids($scope.selected_disease_area_category);
    $scope.payer.diseases = this.get_multi_select_ids($scope.selected_disease_area);
    $scope.payer.localities = this.get_multi_select_ids($scope.selected_locality);
    $scope.payer.functional_expertises = this.get_multi_select_ids($scope.selected_expertise);
    $scope.payer.experiential_expertises = this.get_multi_select_ids($scope.selected_experiential_expertise);
  };
}]
);
