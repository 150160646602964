app.service('dataConvert', ['CONSTANTS', '$filter', function (CONSTANTS, $filter) {
  var dataConvertService = this;

  dataConvertService.getQuotedList = function (list) {
    if (!list) {
      return;
    }

    var list1 = [];

    for (var i = 0; i < list.length; i++) {
      list1.push('\'' + list[i] + '\'');
    }

    return list1;
  };

  dataConvertService.initRankingResponsesSaved = function (response, question) {
    response.ranking_responses_saved = angular.copy(
      response.ranking_responses 
    );
  };

  dataConvertService.initRankingResponsesNotSaved = function (response, question) {
    response.ranking_responses_not_saved = [];

    for (var i = 0; i < question.scale.options.length; i++) {
      var cur_option = question.scale.options[i];
      var saved_option = false;

      for (var k = 0; k < response.ranking_responses.length; k++) {
        var r = response.ranking_responses[k];

        if (r.option == cur_option.id) {
          saved_option = true;
          break;
        }
      }

      if (saved_option) {
        continue;
      }

      response.ranking_responses_not_saved.push(
        { 'option': cur_option.id, 'option_name': cur_option.option_name }
      );
    }
  };

  dataConvertService.convert_to_dict = function (data_list, key_field) {
    var data_dict = {};

    if (data_list) {
      for (var i = 0; i < data_list.length; i++) {
        var key = data_list[i][key_field];
        data_dict[key] = data_list[i];
      }
    }

    return data_dict;
  };

  dataConvertService.convert_to_list = function (data_dict, key_field) {
    var data_list = [];

    for (var key in data_dict) {
      var list_entry = angular.copy(data_dict[key]);
      list_entry[key_field] = key;
      data_list.push(list_entry);
    }
    return data_list;
  };

  dataConvertService.convert_list_to_dict = function (data_list, target_property, key_field, properties_to_check) {
    for (var i = 0; i < data_list.length; i++) {
      if (Object.prototype.hasOwnProperty.call(data_list[i], target_property) &&
          !Array.isArray(data_list[i][target_property])) {
        continue;
      }

      if (!properties_to_check) {
        if (Object.prototype.hasOwnProperty.call(data_list[i],
          target_property) && data_list[i][target_property]) {
          data_list[i][target_property] = this.convert_to_dict(data_list[i][target_property],
            key_field);
        }
      } else {
        var all_satisfied = true;
        for (var k = 0; k < properties_to_check.length; k++) {

          var property_name = properties_to_check[k].name;
          var property_value = properties_to_check[k].value;

          if (!Object.prototype.hasOwnProperty.call(data_list[i], property_name)) {
            all_satisfied = false;
            break;
          }

          if (property_value && data_list[i][property_name] != property_value) {
            all_satisfied = false;
            break;
          }
        }

        if (all_satisfied) {
          data_list[i][target_property] = this.convert_to_dict(data_list[i][target_property],
            key_field);
        }
      }
    }
  };


  dataConvertService.single_convert_list_to_dict = function (entry, target_property, key_field, properties_to_check) {
    if (Object.prototype.hasOwnProperty.call(entry,
      target_property) && !Array.isArray(entry[target_property])) {
      return;
    }

    if (!properties_to_check) {
      if (Object.prototype.hasOwnProperty.call(entry, target_property) && entry[target_property]) {
        entry[target_property] = this.convert_to_dict(entry[target_property], key_field);
      }
    } else {
      var all_satisfied = true;
      for (var k = 0; k < properties_to_check.length; k++) {

        var property_name = properties_to_check[k].name;
        var property_value = properties_to_check[k].value;

        if (!Object.prototype.hasOwnProperty.call(entry, property_name)) {
          all_satisfied = false;
          break;
        }

        if (property_value && entry[property_name] != property_value) {
          all_satisfied = false;
          break;
        }
      }

      if (all_satisfied) {
        entry[target_property] = this.convert_to_dict(entry[target_property], key_field);
      }
    }
  };

  dataConvertService.single_convert_dict_to_list = function (entry, target_property, key_field, properties_to_check) {
    if (Object.prototype.hasOwnProperty.call(entry,
      target_property) && Array.isArray(entry[target_property])) {
      return;
    }

    if (!properties_to_check) {
      if (Object.prototype.hasOwnProperty.call(entry, target_property)) {
        entry[target_property] = this.convert_to_list(entry[target_property], key_field);
      }
    } else {
      var all_satisfied = true;
      for (var k = 0; k < properties_to_check.length; k++) {
        var property_name = properties_to_check[k].name;
        var property_value = properties_to_check[k].value;

        if (!Object.prototype.hasOwnProperty.call(entry, property_name)) {
          all_satisfied = false;
          break;
        }

        if (property_value && entry[property_name] != property_value) {
          all_satisfied = false;
          break;
        }
      }

      if (all_satisfied) {
        entry[target_property] = this.convert_to_list(entry[target_property], key_field);
      }
    }
  };

  dataConvertService.convert_list_to_dict2d = function (data, property_name, row_field, col_field) {
    for (var i = 0; i < data.length; i++) {
      if (Object.prototype.hasOwnProperty.call(data[i], property_name) && data[i][property_name]) {
        var dict2d = {};
        var data_entry = data[i][property_name];

        for (var k = 0; k < data_entry.length; k++) {
          var list_entry = data_entry[k];

          var row_id = list_entry[row_field];
          var col_id = list_entry[col_field];

          if (!dict2d[row_id]) {
            dict2d[row_id] = {};
          }

          dict2d[row_id][col_id] = list_entry;
        }
        data[i][property_name] = dict2d;
      }
    }
  };

  dataConvertService.single_convert_list_to_dict2d = function (entry, property_name, row_field, col_field) {
    if (Object.prototype.hasOwnProperty.call(entry, property_name) && entry[property_name]) {
      var dict2d = {};
      var data_entry = entry[property_name];

      for (var k = 0; k < data_entry.length; k++) {
        var list_entry = data_entry[k];
        var row_id = list_entry[row_field];
        var col_id = list_entry[col_field];

        if (!dict2d[row_id]) {
          dict2d[row_id] = {};
        }

        dict2d[row_id][col_id] = list_entry;
      }
      entry[property_name] = dict2d;
    }
  };

  dataConvertService.mergeDict = function (obj1, obj2) {
    var obj3 = angular.copy(obj1);

    for (var key in obj2) {
      obj3[key] = obj2[key];
    }

    return obj3;
  };

  dataConvertService.single_convert_dict2d_to_list = function (entry, property_name, row_name, col_name) {
    if (Object.prototype.hasOwnProperty.call(entry, property_name) && entry[property_name]) {
      var res_list = [];
      var data_entry = entry[property_name];

      for (var row_id in data_entry) {
        var row_data = data_entry[row_id];

        for (var col_id in row_data) {
          var col_data = row_data[col_id];
          var list_entry = {};
          list_entry[row_name] = row_id;
          list_entry[col_name] = col_id;
          res_list.push(this.mergeDict(list_entry, col_data));
        }
      }

      entry[property_name] = res_list;
    }
  };

  dataConvertService.convertMultipleResponseOptions = function (questions, responses) {
    for (var i = 0; i < responses.length; i++) {
      var r = responses[i];
      var q = $filter('filter')(questions, { 'id': r.question });

      if (!q.length) {
        continue;
      }

      q = q[0];

      dataConvertService.convertResponseOptions(q, r);
    }
  };

  dataConvertService.convertResponseOption = function (questions, response) {
    var q = $filter('filter')(questions, { 'id': response.question });

    if (!q.length) {
      return;
    }

    q = q[0];

    dataConvertService.convertResponseOptions(q, response);
  };

  dataConvertService.convertResponseOptions = function (question, response) {
    if (question.type_name == 'check_boxes') {
      this.single_convert_list_to_dict(response, 'checkbox_responses', 'option');
    }

    if ((question.type_name == 'rating_scale' || question.type_name == 'slider_rating_scale') && question.allow_payer_option !== CONSTANTS.otherOptions.MULTIPLE_OTHER) {
      this.single_convert_list_to_dict(response, 'rating_responses', 'option');
    }

    if (question.type_name == 'rating_scale' && question.allow_payer_option == CONSTANTS.otherOptions.MULTIPLE_OTHER) {
      this.single_convert_list_to_dict(response, 'rating_responses', 'payer_option_name',
        [{ 'name': 'rating_responses' }, {
          'name': 'allow_payer_option',
          'value': CONSTANTS.otherOptions.MULTIPLE_OTHER
        }]
      );
    }

    if (question.type_name == 'ranking_scale') {
      this.initRankingResponsesSaved(response, question);
      this.initRankingResponsesNotSaved(response, question);
      this.single_convert_list_to_dict(response, 'ranking_responses', 'option');
    }

    if (question.type_name == 'radio_ranking_scale') {
      this.single_convert_list_to_dict(response, 'ranking_responses', 'option');
    }

    if (question.type_name == 'points_allocation') {
      this.single_convert_list_to_dict(response, 'points_allocation_responses', 'option');
    }
  };

  dataConvertService.unconvertMultipleResponseOptions = function (questions, responses) {
    for (var i = 0; i < responses.length; i++) {
      var r = responses[i];
      dataConvertService.unconvertResponseOptions(null, r);
    }
  };

  dataConvertService.unconvertResponseOptions = function (question, response) {
    this.single_convert_dict_to_list(response, 'rating_responses', 'payer_option_name',
      [{ 'name': 'rating_responses' }, {
        'name': 'allow_payer_option',
        'value': CONSTANTS.otherOptions.MULTIPLE_OTHER
      }]
    );

    this.single_convert_dict_to_list(response, 'rating_responses', 'option');
    this.single_convert_dict_to_list(response, 'checkbox_responses', 'option');
    this.single_convert_dict_to_list(response, 'ranking_responses', 'option');
    this.single_convert_dict2d_to_list(response, 'table_responses', 'row', 'column');
    this.single_convert_dict_to_list(response, 'points_allocation_responses', 'option');
  };
}]);

