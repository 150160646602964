app.service('permissionsHelper', ['authHelper', function (authHelper) {
  var permissionsHelper= this;

  permissionsHelper.hasPermission = function (permission) {
    if (!authHelper.isUserAdmin()) {
      return true;
    }

    let permissions = authHelper.getUserPermissions();
    if (permissions) {
      return permissions.includes(permission);
    } else {
      return false;
    }
  };

  permissionsHelper.hasAnyOfPermissions = function (permissions_list) {
    for (let permission of permissions_list) {
      if (permissionsHelper.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  };

  permissionsHelper.hasAllOfPermissions = function (permissions_list) {
    for (let permission of permissions_list) {
      if (!permissionsHelper.hasPermission(permission)) {
        return false;
      }
    }
    return true;
  };

  permissionsHelper.hasCreateOrUpdatePermission = function (is_create_request, create_permission, update_permission) {
    return (
      (is_create_request && permissionsHelper.hasPermission(create_permission)) ||
        (!is_create_request && permissionsHelper.hasPermission(update_permission))
    );
  };
}]);
