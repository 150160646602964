app.service('dynamicBreadcrumbs',
  ['SETTINGS', '$location', '$window', 'authHelper', '$ngConfirm', 'apiHelper', 'HttpAuth',
    '$rootScope', '$filter', '$timeout', '$anchorScroll', 'CONSTANTS', 'defaultBreadcrumbs',
    function (SETTINGS, $location, $window, authHelper, $ngConfirm, apiHelper, HttpAuth,
      $rootScope, $filter, $timeout, $anchorScroll, CONSTANTS, defaultBreadcrumbs) {

      var dynamicBreadcrumbsService = this;
      var dynamicCrumbs = [];

      dynamicBreadcrumbsService.getPrevPath = function () {
        if (dynamicCrumbs.length >= 2) {
          var url = dynamicCrumbs[dynamicCrumbs.length - 2][1];
          if (url) {
            return url.replace('#', '');
          }
        }
      };

      dynamicBreadcrumbsService.getDefault = function (start, extra_url_lookup, extra_text_lookup) {
        return dynamicBreadcrumbsService.get(start, extra_url_lookup, extra_text_lookup, true);
      };

      dynamicBreadcrumbsService.get = function (start, extra_url_lookup, extra_text_lookup, reset_to_default) {
        var defaultCrumbs = defaultBreadcrumbs.getBreadcrumb(start,
          extra_url_lookup,
          extra_text_lookup);

        if (!defaultCrumbs) {
          return;
        }

        if (!dynamicCrumbs || !dynamicCrumbs.length || reset_to_default) {
          dynamicCrumbs = defaultCrumbs;

          if (extra_url_lookup != null && !$.isEmptyObject(extra_url_lookup)) {
            for (var i = 0; i < dynamicCrumbs.length; i++) {
              for (var urlKey in extra_url_lookup) {
                if (dynamicCrumbs[i][0].toLowerCase() === urlKey.toLowerCase()) {
                  dynamicCrumbs[i][1] = extra_url_lookup[urlKey];
                }
              }
            }
          }

          return dynamicCrumbs;
        }

        var cur_text = defaultCrumbs[defaultCrumbs.length - 1][0];
        var cur_url = defaultCrumbs[defaultCrumbs.length - 1][1];

        var excludeText = 'Welcome to Genesis Research Group';

        var cur_text_index = -1;

        for (i = 0; i < dynamicCrumbs.length; i++) {
          if (dynamicCrumbs[i][0] === cur_text || dynamicCrumbs[i][1] === cur_url) {
            cur_text_index = i;
            break;
          }

          if (dynamicCrumbs[i][0] === excludeText) {
            cur_text_index = i;
            break;
          }
        }

        if (cur_text_index !== -1) {
          dynamicCrumbs = dynamicCrumbs.slice(0, i + 1);
          dynamicCrumbs[dynamicCrumbs.length - 1][0] = cur_text;
          dynamicCrumbs[dynamicCrumbs.length - 1][1] = cur_url;
        } else {
          dynamicCrumbs.push(defaultCrumbs[defaultCrumbs.length - 1]);
        }

        return dynamicCrumbs;
      };
    }]
);
