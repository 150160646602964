app.service('ADMIN_REPORTS', ['CONSTANTS', '$filter', 'portalHelper', function (CONSTANTS, $filter, portalHelper) {
  var adminReportsService = this;
  const PERMISSIONS = CONSTANTS.getPermissions();

  adminReportsService.ReportConfig = function(reportUrl, columns) {
    if  (!reportUrl || !columns) {
      throw new Error('ValidationError: reportUrl and columns are mandatory to create report configuration.');
    }

    this.reportUrl = reportUrl;
    this.columns = columns;
  };

  adminReportsService.ReportConfig.prototype.setColumnDefs = function (columnDefs) {
    this.columnDefs = columnDefs;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setOrder = function (order) {
    var reportConfig = this;
    if (!reportConfig.columns || !Array.isArray(reportConfig.columns)){
      throw new Error('argument passed in setOrder is invalid:\n\
        ===============Sample===============\n\
        reportConfig.setOrder([[\'key_to_sort\', \'asc\'], [\'another_key_to_sort\', \'desc\'] ]);');
    }
    var actualOrder = order.map(
      function (item) {
        item[0] = _.findIndex(reportConfig.columns, function(column) { return column.data == item[0]; });
        return item;
      }
    );

    this.order = actualOrder;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setPaging = function (isPagingEnabled) {
    this.paging = isPagingEnabled;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setReportFooter = function (reportFooter) {
    this.reportFooter = reportFooter;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setExportButtonsFunction = function (exportButtonsFunction) {
    this.exportButtonsFunction = exportButtonsFunction;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setPreProcessingFunction = function (preProcessingFunction) {
    this.preProcessingFunction = preProcessingFunction;
    return this;
  };

  adminReportsService.ReportConfig.prototype.setRowCallback = function (rowCallbackFunction) {
    this.rowCallbackFunction = rowCallbackFunction;
    return this;
  };

  adminReportsService.reportsList = [
    {
      'id': CONSTANTS.adminReports.RECOGNIZED_REVENUE_TOTALS,
      'name': 'Recognized Credits Report',
      'description': 'The Daily, Monthly, Quarterly and Yearly recognized totals.',
      'order': 1,
      'view_permission': PERMISSIONS.CAN_VIEW_RECOGNISED_CREDIT_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_RECOGNISED_CREDIT_REPORT
    }, {
      'id': CONSTANTS.adminReports.PROJECT_MASTER,
      'name': 'Project Master Report',
      'description': 'List projects and show the breakdown of all credit charges related to each one.',
      'order': 2,
      'view_permission': PERMISSIONS.CAN_VIEW_PROJECT_MASTER_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_PROJECT_MASTER_REPORT
    }, {
      'id': CONSTANTS.adminReports.LICENCE,
      'name': 'Licence Report',
      'description': 'List licences by client, showing licence class, start and end dates, number of days remaining ' +
          'and details of credits purchased and spent.',
      'order': 3,
      'view_permission': PERMISSIONS.CAN_VIEW_LICENCE_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_LICENCE_REPORT
    }, {
      'id': CONSTANTS.adminReports.LICENCE_NEW,
      'name': 'New Licence Report',
      'description': 'Licence Report_New.',
      'order': 4,
      'view_permission': PERMISSIONS.CAN_VIEW_LICENCE_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_LICENCE_REPORT
    }, {
      'id': CONSTANTS.adminReports.WEEKLY_CREDIT_REPORT,
      'name': 'Weekly Credit Control Report',
      'description': 'Weekly Credit Control Report.',
      'order': 5,
      'view_permission': PERMISSIONS.CAN_VIEW_WEEKLY_CREDIT_CONTROL_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_WEEKLY_CREDIT_CONTROL_REPORT
    },{
      'id': CONSTANTS.adminReports.WEEKLY_CREDIT_REPORT_NEW,
      'name': 'New Weekly Credit Control Report',
      'description': 'Weekly Credit Control Report_New.',
      'order': 6,
      'view_permission': PERMISSIONS.CAN_VIEW_WEEKLY_CREDIT_CONTROL_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_WEEKLY_CREDIT_CONTROL_REPORT
    }, {
      'id': CONSTANTS.adminReports.CREDIT_TRANSACTION_HISTORY,
      'name': 'Credit Transaction History',
      'description': 'See a periodic statement of a Client\'s account including transaction history for the period.',
      'order': 7,
      'view_permission': PERMISSIONS.CAN_VIEW_CREDIT_TRANSACTION_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_CREDIT_TRANSACTION_REPORT
    }, {
      'id': CONSTANTS.adminReports.SURVEY_COST_DETAILS,
      'name': 'Project Cost Details Report',
      'description': 'A breakdown of the cost of each project to Genesis Research, showing Payer remuneration details for each project.',
      'order': 8,
      'view_permission': PERMISSIONS.CAN_VIEW_PROJECT_COST_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_PROJECT_COST_REPORT
    }, {
      'id': CONSTANTS.adminReports.PAYERS_CLASSIFICATION,
      'name': 'Payers Classification',
      'description': 'List of all the Payers in the portal.',
      'order': 9,
      'view_permission': PERMISSIONS.CAN_VIEW_PAYER_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_PAYER_REPORT
    }, {
      'id': CONSTANTS.adminReports.PAYER_QUICKBOOK_DETAILS,
      'name': 'Payer Quickbook Details',
      'description': 'Lists all the mappings of RPR payer accounts with the quickbook vendor accounts',
      'order': 10,
      'view_permission': PERMISSIONS.CAN_VIEW_PAYER_QUICKBOOK_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_PAYER_QUICKBOOK_REPORT
    }, {
      'id': CONSTANTS.adminReports.CLIENT_QUICKBOOK_DETAILS,
      'name': 'Client Quickbook Details',
      'description': 'Lists all the mappings of RPR client accounts with the quickbook customer accounts.',
      'order': 11,
      'view_permission': PERMISSIONS.CAN_VIEW_CLIENT_QUICKBOOK_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_CLIENT_QUICKBOOK_REPORT
    }, {
      'id': CONSTANTS.adminReports.CLIENT_STATEMENT,
      'name': 'Client Statement Report',
      'description': 'Client Statement Report',
      'order': 12,
      'view_permission': PERMISSIONS.CAN_VIEW_CLIENT_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_CLIENT_REPORT
    }, {
      'id': CONSTANTS.adminReports.PAYER_FMV_RATES,
      'name': 'Genesis Research Payer FMV Rates',
      'description': 'List all countries, their default remuneration currency and default remuneration rate.',
      'order': 13,
      'view_permission': PERMISSIONS.CAN_VIEW_PAYER_FMV_RATES_REPORT,
      'export_permission': PERMISSIONS.CAN_EXPORT_PAYER_FMV_RATES_REPORT
    }];

  adminReportsService.projectMasterReportSummaryColumns = [
    {
      name: 'Credits (Fielding Phase 1)',
      source: 'credit_details.fielding_charge_phase_1'
    }, {
      name: 'Credits (Fielding Phase 2)',
      source: 'credit_details.fielding_charge_phase_2'
    }, {
      name: 'Credits (Fielding Phase 3)',
      source: 'credit_details.fielding_charge_phase_3'
    }, {
      name: 'Credits (Background)',
      source: 'credit_details.prepare_background'
    }, {
      name: 'Credits (Q.Dev)',
      source: 'credit_details.question_development'
    }, {
      name: 'Credits (Insights)',
      source: 'credit_details.insight_report'
    }, {
      name: 'Credits (Follow-Up)',
      source: 'credit_details.follow_up_clarification'
    }, {
      name: 'Credits (Adjusted Credits)',
      source: 'credit_details.additional'
    }, {
      name: 'Total Credits (Committed)',
      source: 'credit_details.total'
    }, {
      name: 'Total Credits (Spent)',
      source: 'total_spent'
    }, {
      name: 'Work in progress',
      source: 'total_in_progress'
    }
  ];

  adminReportsService.columnsPayerFmvRatesReport = [
    {
      title: 'Country of Residence',
      data: 'name',
      defaultContent: ''
    },
    {
      title: 'Country Code',
      data: 'country_code',
      defaultContent: ''
    },
    {
      title: 'Default Remuneration Currency',
      data: 'default_remuneration_currency.code',
      defaultContent: ''
    },
    {
      title: 'Default Remuneration Rate',
      data: 'default_remuneration_rate',
      defaultContent: ''
    }
  ];

  adminReportsService.columnsProjectMasterReport = [
    {
      title: 'Genesis Research Survey ID',
      data: 'survey_prefixed_id',
      defaultContent: ''
    },
    {
      title: 'Project ID',
      data: 'project_code',
      defaultContent: ''
    },
    {
      title: 'Licence IDs',
      data: 'licence_codes',
      render: function (data, type, row) {
        return data.join(', ');
      }
    },
    {
      title: 'Client',
      data: 'client_name',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? data : '';
      }
    },
    {
      title: 'Client User',
      data: 'client_user',
      defaultContent: ''
    },
    {
      title: 'Client Contacts',
      data: 'client_contacts',
      defaultContent: ''
    },
    {
      title: 'Project Leads',
      data: 'project_managers',
      defaultContent: '',
      render: function (data, type, row) {
        var fullNames = [];
        for (var i = 0; i < data.length; i++) {
          fullNames.push(data[i].first_name + ' ' + data[i].last_name);
        }
        return fullNames.join(', ');
      }
    },
    {
      title: 'Commercial Leads',
      data: 'commercial_managers',
      defaultContent: '',
      render: function (data, type, row) {
        var fullNames = [];
        for (var i = 0; i < data.length; i++) {
          fullNames.push(data[i].first_name + ' ' + data[i].last_name);
        }
        return fullNames.join(', ');
      }
    },
    {
      data: 'payer_team_leads_display_text',
      title: 'Payer Team Leads',
      defaultContent: ''
    },
    {
      title: 'Ops Team Members',
      data: 'ops_team_members',
      defaultContent: '',
      render: function (data, type, row) {
        var fullNames = [];
        for (var i = 0; i < data.length; i++) {
          fullNames.push(data[i].first_name + ' ' + data[i].last_name);
        }
        return fullNames.join(', ');
      }
    },
    {
      title: 'Project Name',
      data: 'project_name',
      defaultContent: '-',
      render: function (data) {
        return data ? data : '-';
      }
    },
    {
      title: 'Survey Title',
      data: 'survey_title'
    },
    {
      title: 'Project Shortname',
      data: 'project_shortname',
      defaultContent: ''
    },
    {
      title: 'Project Type',
      data: 'project_type',
      render: function (data, type, row) {
        return data || '';
      }
    },
    {
      title: 'Project Subtype(s)',
      data: 'project_sub_types',
      render: function (data, type, row) {
        return (data ? data.join(', ') : '');
      }
    },
    {
      title: 'Therapy Area(s)',
      data: 'therapy_areas',
      render: function (data, type, row) {
        return (data ? data.join(', ') : '');
      }
    },
    {
      title: 'Disease(s)',
      data: 'diseases',
      render: function (data, type, row) {
        return (data ? data.join(', ') : '');
      }
    },
    {
      title: 'HEOR/P&MA',
      data: 'research_type',
      render: function (data, type, row) {
        return data || '';
      }
    },
    {
      title: 'Product Focused / Above Brand',
      data: 'focus_type',
      render: function (data, type, row) {
        return data || '';
      }
    },
    {
      title: 'Phase of Product Dev',
      data: 'product_development_phases',
      render: function (data, type, row) {
        return (data ? data.join(', ') : '');
      }
    },
    {
      title: 'Drug Name (Brand)',
      data: 'project_drug_name_brand'
    },
    {
      title: 'Drug Name (Molecule)',
      data: 'project_drug_name_molecule'
    },
    {
      title: 'Relevance Rating',
      data: 'relevance_ratings_status',
      render: function (data, type, row) {
        if (data == 'none') {
          return 'No';
        } else if (data == 'all') {
          return 'Yes';
        } else if (data == 'partial') {
          return 'Partial';
        } else if (data == null) {
          return '';
        } else {
        }
      }
    },
    {
      title: 'Regions',
      data: 'regions',
      render: function (data, type, row) {
        return data.join(', ');
      }
    },
    {
      title: 'Scope Countries',
      data: 'scope_countries',
      render: function (data, type, row) {
        return data.join(', ');
      }
    },
    {
      title: 'Payers per country',
      data: 'display_requested_payer_counts'
    },
    {
      title: 'Questions per country',
      data: 'display_question_counts'
    },
    {
      title: 'Credits (Fielding Phase 1)',
      data: 'credit_details.fielding_charge_phase_1',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Fielding Phase 2)',
      data: 'credit_details.fielding_charge_phase_2',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Fielding Phase 3)',
      data: 'credit_details.fielding_charge_phase_3',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Background)',
      data: 'credit_details.prepare_background',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Q. Dev)',
      data: 'credit_details.question_development',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Insights)',
      data: 'credit_details.insight_report',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Follow-Up)',
      data: 'credit_details.follow_up_clarification',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Credits (Adjusted Credits)',
      data: 'credit_details.additional',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Total Credits (Committed)',
      data: 'credit_details.total',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Total Credits (Spent)',
      data: 'total_spent',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Work in Progress',
      data: 'total_in_progress',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Scoping',
      data: 'scoping_datetime',
      defaultContent: '',
      type: 'date',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Question Development',
      data: 'question_development_started_datetime',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Compliance',
      data: 'compliance_started_datetime',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Fielded',
      data: 'fielded_datetime',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Read Out',
      data: 'report_delivered_datetime',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Delivered',
      data: 'closed_datetime',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Status',
      data: 'survey_status',
      render: function (data, type, row) {
        var status = portalHelper.getReadableSurveyStatusName(data, row.survey_on_hold);
        if (status != null) {
          return status.toUpperCase();
        }
        return '';
      }
    },
    {
      title: 'Survey Internal ID',
      data: 'survey_id',
      visible: false
    }
  ];

  adminReportsService.columnsDailyRecognizedRevenue = [
    {
      title: 'Date',
      data: 'date',
      type: 'date',
      orderable: false,
      responsivePriority: 1,
      render: function(data, type, row) {
        return data ? $filter('date')(new Date(data), CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE): '';
      }
    },
    {
      title: 'Week',
      data: 'week',
      className: 'dt-number',
      type: 'num',
      orderable: false
    },
    {
      title: 'Month',
      data: 'month',
      className: 'dt-number',
      type: 'num',
      orderable: false
    },
    {
      title: 'Quarter',
      data: 'quarter',
      className: 'dt-number',
      type: 'num',
      orderable: false
    },
    {
      title: 'New Credits',
      data: 'purchased_credits',
      className: 'dt-number',
      type: 'num',
      orderable: false
    },
    {
      title: 'Credits Utilised',
      data: 'credits_spent',
      className: 'dt-number',
      type: 'num',
      orderable: false

    },
    {
      title: 'Credits Expired',
      data: 'credits_expired',
      className: 'dt-number',
      type: 'num',
      orderable: false

    },
    {
      title: 'Unrecognized Utilised',
      data: 'credits_spent_unrecognised',
      className: 'dt-number',
      type: 'num',
      orderable: false

    },
    {
      title: 'Credits Recognized',
      data: 'total_recognised',
      className: 'dt-number',
      type: 'num',
      orderable: false

    }
  ];

  adminReportsService.columnsSummaryRecognizedRevenue = [
    {
      title: 'Quarter',
      data: 'quarter',
      orderable: false,
      responsivePriority: 1
    },
    {
      title: 'Month',
      data: 'month',
      responsivePriority: 1,
      orderable: false
    },
    {
      title: 'Month Count',
      data: 'month_count',
      responsivePriority: 1,
      orderable: false
    },
    {
      title: 'New Credits',
      data: 'credits_added',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Utilised',
      data: 'credits_utilised',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Expired',
      data: 'credits_expired',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Unrecognized Utilised',
      data: 'unrecognised_utilised',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Recognized',
      data: 'rev_rec',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    }
  ];

  adminReportsService.columnsManagerwiseSummaryRecognizedRevenue = [
    {
      title: 'Manager',
      data: 'manager_name',
      orderable: false,
      responsivePriority: 1
    },
    {
      title: 'Quarter',
      data: 'quarter',
      orderable: false,
      responsivePriority: 1
    },
    {
      title: 'Month',
      data: 'month',
      responsivePriority: 1,
      orderable: false
    },
    {
      title: 'Month Count',
      data: 'month_count',
      responsivePriority: 1,
      orderable: false
    },
    {
      title: 'New Credits',
      data: 'credits_added',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Utilised',
      data: 'credits_utilised',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Expired',
      data: 'credits_expired',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Unrecognized Utilised',
      data: 'unrecognised_utilised',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    },
    {
      title: 'Credits Recognized',
      data: 'rev_rec',
      responsivePriority: 1,
      className: 'dt-number',
      type: 'num',
      orderable: false,
      render: function(data, type, row) {
        if (row.quarter) {
          return '<b>' + Number(data).toLocaleString() + '</b>';
        }
        return data;
      }
    }
  ];

  adminReportsService.recognizedRevDailyReportSummaryCols = [
    {
      name: 'New Credits',
      source: 'purchased_credits'
    }, {
      name: 'Credits Utilised',
      source: 'credits_spent'
    }, {
      name: 'Credits Expired',
      source: 'credits_expired'
    }, {
      name: 'Unrecognized Utilised',
      source: 'credits_spent_unrecognised'
    }, {
      name: 'Credits Recognized',
      source: 'total_recognised'
    }
  ];

  adminReportsService.columnsPayersClassification = [
    {
      title: 'Country Of Expertise',
      data: 'country_name',
      responsivePriority: 1
    },
    {
      title: 'Country Of Residence',
      data: 'country_of_residence',
      responsivePriority: 1,
      defaultContent: '',
      render: function (data, type, row) {
        if (data != null) {
          return data.name;
        }
        return '';
      }
    },
    {
      title: 'Active/Inactive',
      data: 'active',
      responsivePriority: 1,
      render: function (data, type, row) {
        return (data) ? 'Active' : 'Deactivated';
      }
    },
    {
      title: 'Locked/Unlocked',
      data: 'locked',
      responsivePriority: 1,
      render: function (data, type, row) {
        return (data) ? 'Locked' : 'Unlocked';
      }
    },
    {
      title: 'Title',
      data: 'title',
      responsivePriority: 1
    },
    {
      title: 'Advisor First Name',
      data: 'first_name',
      responsivePriority: 1
    },
    {
      title: 'Advisor Last Name',
      data: 'last_name',
      responsivePriority: 1
    },
    {
      title: 'Job Title',
      data: 'job_title',
      responsivePriority: 1
    },
    {
      title: 'Type',
      data: 'localities',
      responsivePriority: 1
    },
    {
      title: 'Country specific type',
      data: 'additional_information',
      responsivePriority: 2
    },
    {
      title: 'Profile',
      className: 'dt-free-text',
      data: 'description',
      responsivePriority: 2,
      createdCell: function (cell, cellData, rowData, row, col) {
        var cur_text = $(cell).text();
        if (!cur_text) {
          return;
        }

        $(cell).attr('title', cur_text);
      }
    },
    {
      title: 'Anonymized Profile',
      className: 'dt-free-text',
      data: 'anonymized_profile',
      responsivePriority: 2,
      createdCell: function (cell, cellData, rowData, row, col) {
        var cur_text = $(cell).text();
        if (!cur_text) {
          return;
        }

        $(cell).attr('title', cur_text);
      }
    },
    {
      title: 'Contact details',
      data: 'contact_details',
      responsivePriority: 2,
      render: function (data, type, row) {
        return data.replace(/\n/g, '<br/>\n');
      }
    },
    {
      title: 'Telephone',
      data: 'all_telephones',
      responsivePriority: 1
    },
    {
      title: 'E-mail',
      data: 'email',
      responsivePriority: 1
    },
    {
      title: 'Remuneration Rate',
      data: 'rate',
      type: 'number',
      responsivePriority: 1
    },
    {
      title: 'Remuneration Currency',
      data: 'currency',
      responsivePriority: 1
    },
    {
      title: 'Comments',
      data: null,
      responsivePriority: 2,
      render: function (data, type, row) {
        return '';
      }
    },
    {
      title: 'Notes',
      data: null,
      responsivePriority: 2,
      render: function (data, type, row) {
        return '';
      }
    },
    {
      title: 'New/Existing',
      data: 'new_payer',
      responsivePriority: 1,
      render: function (data, type, row) {
        return (data) ? 'New' : 'Existing';
      }
    },
    {
      title: 'Network Joined',
      data: 'joined_date',
      responsivePriority: 1,
      type: 'date',
      render: function (data, type, row) {
        if (type === 'display' || type === 'filter') {
          return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MONTH_YEAR) : '';
        } else {
          return data;
        }
      }
    },
    {
      title: 'Created by',
      data: 'created_by',
      responsivePriority: 1,
      defaultContent: '',
      render: function (data, type, row) {
        if (data != null) {
          return data.first_name + ' ' + data.last_name;
        }

        return '';
      }
    },
    {
      title: 'RPR Creation Date',
      data: 'created_datetime',
      responsivePriority: 1,
      defaultContent: '',
      type: 'date',
      render: function (data, type, row) {
        if (type === 'display' || type === 'filter') {
          return (data) ? $filter('date')(new Date(data), CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
        } else {
          return data;
        }
      }
    },
    {
      title: 'Agreement Signed',
      data: 'agreement_signed',
      responsivePriority: 2,
      render: function (data, type, row) {
        return (data) ? 'Yes' : 'No';
      }
    },
    {
      title: 'Expertise check form Yes/No',
      data: null,
      responsivePriority: 2,
      render: function (data, type, row) {
        return '';
      }
    },
    {
      title: 'Demo',
      data: null,
      responsivePriority: 2,
      render: function (data, type, row) {
        return '';
      }
    }
  ];

  adminReportsService.columnsSurveyCostDetailsReport = [
    {
      title: 'Project ID',
      data: 'project_code'
    },
    {
      title: 'Client / Client User',
      data: null,
      render: function (data, type, row) {
        return row.client_name + ' / ' + row.client_user_full_name;
      }
    },
    {
      visible: false,
      data: 'survey_id',
      type: 'num'
    },
    {
      title: 'Genesis Research Survey ID',
      data: null,
      render: function (data, type, row) {
        if (type == 'sort') {
          return row.survey_id.toString().padStart(10, 0);
        }
        return 'MAT_' + row.survey_id;
      }
    },
    {
      title: 'Survey Name',
      data: 'survey_name'
    },
    {
      title: 'Background Materials',
      data: null,
      render: function (data, type, row) {
        return (row.prepare_background) ? 'YES' : 'NO';
      }
    },
    {
      title: 'Question Development',
      data: null,
      render: function (data, type, row) {
        return (row.question_development) ? 'YES' : 'NO';
      }
    },
    {
      title: 'Insights Report',
      data: null,
      render: function (data, type, row) {
        return (row.share_report) ? 'YES' : 'NO';
      }
    },
    {
      title: 'Clean Data',
      data: null,
      render: function (data, type, row) {
        return (row.share_responses) ? 'YES' : 'NO';
      }
    },
    {
      title: 'Payer Name',
      data: 'payer_first_name'
    },
    {
      title: 'Last Name',
      data: 'payer_last_name'
    },
    {
      title: 'Respondent Type',
      data: 'respondent_type'
    },
    {
      title: 'Response Submitted',
      data: 'response_submitted',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Time Spent on Survey',
      data: 'time_spent',
      defaultContent: '',
      render: function (data, type, row) {
        if (type == 'sort') {
          return ('' + parseInt(row.time_spent_duration)).padStart(20, '0');
        }
        else {
          return data;
        }
      }
    },
    {
      title: 'Time to Submit Survey',
      data: 'responded_in',
      defaultContent: '',
      render: function (data, type, row) {
        if (type == 'sort'){
          return parseInt(row.responded_in_duration_seconds).toString().padStart(20, '0');
        }
        return data;
      }
    },
    {
      title: 'Response Submitted By',
      data: 'response_submitted_by'
    },
    {
      title: 'Payer Email',
      data: 'payer_email'
    },
    {
      title: 'Country of Expertise',
      data: 'payer_country'
    },
    {
      title: 'Currency',
      data: null,
      render: function (data, type, row) {
        return (row.currency) ? row.currency : '-';
      }
    },
    {
      title: 'No. of Clarifications requested',
      data: 'admin_clarification_request_count',
      className: 'dt-number',
      type: 'num',
      render: function(data, type, row) {
        if (data === 0) {
          return 'N/A';
        }
        return data;
      }
    },
    {
      title: 'No. of Clarifications responded',
      data: 'admin_clarification_request_response_count',
      className: 'dt-number',
      type: 'num',
      render: function(data, type, row) {
        if (data === 0) {
          return 'N/A';
        }
        return data;
      }
    },
    {
      title: 'Questions Answered',
      data: 'question_count',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Question Equivalency',
      data: 'question_to_pay_count',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Rate',
      data: null,
      render: function (data, type, row) {
        return (row.rate) ? row.rate : '-';
      },
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Honorarium for Response',
      data: null,
      render: function (data, type, row) {
        return (row.honorarium_response) ? row.honorarium_response : '-';
      },
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Follow-up Fees',
      data: null,
      render: function (data, type, row) {
        return (row.follow_up_fees) ? row.follow_up_fees : '-';
      },
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Additional Fees',
      data: null,
      render: function (data, type, row) {
        return (row.additional_fees) ? row.additional_fees : '-';
      },
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Additional Fees Breakup',
      data: null,
      render: function (data, type, row) {

        var fee_records = row.additional_fees_records;
        if (fee_records.length === 0) {
          return '-';
        }

        var finalStr = '';
        var finalHtml = '';
        for (var i = 0; i < fee_records.length; i++) {
          finalHtml += '<b>' + fee_records[i].fee + '</b> : ' + fee_records[i].reason + '<br />';
          finalStr += fee_records[i].fee + ' : ' + fee_records[i].reason + '\r\n';
        }

        if ( type === 'display' ){
          return finalHtml;
        }
        else {
          return finalStr;
        }
      }
    },
    {
      title: 'Total Honorarium',
      data: null,
      render: function (data, type, row) {
        var honorarium = ((row.honorarium_response) ? row.honorarium_response : 0) +
            ((row.additional_fees) ? row.additional_fees : 0) +
            ((row.follow_up_fees) ? row.follow_up_fees : 0);
        return (honorarium) ? honorarium : '-';
      },
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Payer Recruited By',
      data: 'recruited_by_full_name',
      defaultContent: '',
      render: function (data, type, row) {
        return data || ' ';
      }
    },
    {
      title: 'Relevance Rating',
      data: 'relevance_rating_value',
      defaultContent: '',
      render: function (data, type, row) {
        return data || 'N/A';
      }
    }
  ];

  adminReportsService.weeklyCreditReportSummaryColumns = [
    {
      name: 'Purchased Credits',
      source: 'purchased_credits'
    }, {
      name: 'Additional recognised',
      source: 'additional_recognised'
    }, {
      name: 'Transfers',
      source: 'transfers'
    }, {
      name: 'WIP Credits (Recognised)',
      source: 'credits_wip_recognised'
    }, {
      name: 'Credits Spent (Recognised)',
      source: 'credits_spent_recognised'
    }, {
      name: 'Committed (Recognised)',
      source: 'credits_committed_recognised'
    }, {
      name: 'Week\'s Credits Spent (Recognised)',
      source: 'periodic_credits_spent_recognised'
    }, {
      name: 'Week\'s Credits Expired',
      source: 'periodic_credits_expired'
    },{
      name: 'Remaining Credits (Recognisable)',
      source: 'credits_remaining_recognisable'
    }, {
      name: 'Credits Expired',
      source: 'credits_expired'
    }, {
      name: 'Remaining Credits (Unspent, Adj)',
      source: 'credits_remaining_unspent_adjusted'
    }, {
      name: 'Remaining Credits (Uncommitted, Adj)',
      source: 'credits_remaining_uncommitted_adjusted'
    }, {
      name: 'Week\'s Spent (Unrecognised)',
      source: 'periodic_credits_spent_unrecognised'
    }, {
      name: 'WIP (Unrecognised)',
      source: 'credits_wip_unrecognised'
    }, {
      name: 'Spent (Unrecognised)',
      source: 'credits_spent_unrecognised'
    }, {
      name: 'Committed (Unrecognised)',
      source: 'credits_committed_unrecognised'
    }
  ];

  adminReportsService.licenceReportSummaryColumns = [
    {
      name: 'Purchased Credits',
      source: 'purchased_credits'
    }, {
      name: 'Additional recognised',
      source: 'additional_recognised'
    }, {
      name: 'Transfers',
      source: 'transfers'
    }, {
      name: 'WIP Credits (Recognised)',
      source: 'credits_wip_recognised'
    }, {
      name: 'Credits Spent (Recognised)',
      source: 'credits_spent_recognised'
    }, {
      name: 'Committed (Recognised)',
      source: 'credits_committed_recognised'
    }, {
      name: 'Remaining Credits (Recognisable)',
      source: 'credits_remaining_recognisable'
    }, {
      name: 'Credits Expired',
      source: 'credits_expired'
    }, {
      name: 'Remaining Credits (Unspent, Adjusted)',
      source: 'credits_remaining_unspent_adjusted'
    }, {
      name: 'Remaining Credits (Uncommitted, Adj)',
      source: 'credits_remaining_uncommitted_adjusted'
    }, {
      name: 'WIP (Unrecognised)',
      source: 'credits_wip_unrecognised'
    }, {
      name: 'Spent (Unrecognised)',
      source: 'credits_spent_unrecognised'
    }, {
      name: 'Committed (Unrecognised)',
      source: 'credits_committed_unrecognised'
    }
  ];

  adminReportsService.columnsLicenceReport = [
    {
      data: 'licence_code',
      title: 'Licence ID'
    },
    {
      data: 'status',
      title: 'Licence Status',
      render: function (data, type, row) {
        return data.toUpperCase().replace(/_/g, ' ');
      }
    },
    {
      data: 'expired',
      title: 'Expired/Closed',
      render: function (data, type, row) {
        return (data) ? 'Yes' : 'No';
      }
    },
    {
      data: 'account_managers',
      title: 'Engagement Manager(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'commercial_directors',
      title: 'Commercial Director(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'client_name',
      title: 'Client'
    },
    {
      data: 'client_users',
      title: 'Client User(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }

        return '-';
      }
    },
    {
      title: 'Start Date',
      data: 'from_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'End Date',
      data: 'to_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Grace Period End Date',
      data: 'grace_period_end_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return data ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      data: 'days_remaining',
      title: 'Remaining Days',
      className: 'dt-number',
      defaultContent: '-',
      type: 'num'
    },
    {
      data: 'licence_class',
      title: 'Licence Class'
    },
    {
      data: 'purchased_credits',
      title: 'Total Purchased Credits',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits',
      title: 'Initial Purchased Credits',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'dollar_value_per_credit',
      title: 'Dollar Value (Initial Purchased Credits)',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'additional_recognised',
      title: 'Additional recognised',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'transfers',
      title: 'Transfers',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip_recognised',
      title: 'WIP (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent_recognised',
      title: 'Spent (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed_recognised',
      title: 'Committed (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_recognisable',
      title: 'Remaining Credits (Recognisable)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_expired',
      title: 'Credits expired',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_unspent_adjusted',
      title: 'Remaining Credits (Unspent, Adj.)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_adjusted',
      title: 'Remaining Credits (Uncommitted, Adj.)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip_unrecognised',
      title: 'WIP (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent_unrecognised',
      title: 'Spent (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed_unrecognised',
      title: 'Committed (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    }
  ];

  adminReportsService.columnsNewLicenceReport = [
    {
      data: 'licence_code',
      title: 'Licence ID'
    },
    {
      data: 'status',
      title: 'Licence Status',
      render: function (data, type, row) {
        return data.toUpperCase().replace(/_/g, ' ');
      }
    },
    {
      data: 'expired',
      title: 'Expired/Closed',
      render: function (data, type, row) {
        return (data) ? 'Yes' : 'No';
      }
    },
    {
      data: 'account_managers',
      title: 'Engagement Manager(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'commercial_directors',
      title: 'Commercial Director(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'client_name',
      title: 'Client'
    },
    {
      data: 'client_users',
      title: 'Client User(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }

        return '-';
      }
    },
    {
      title: 'Start Date',
      data: 'from_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'End Date',
      data: 'to_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
      }
    },
    {
      title: 'Grace Period End Date',
      data: 'grace_period_end_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return data ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      data: 'days_remaining',
      title: 'Remaining Days',
      className: 'dt-number',
      defaultContent: '-',
      type: 'num'
    },
    {
      data: 'licence_class',
      title: 'Licence Class'
    },
    {
      data: 'purchased_credits',
      title: 'Total Purchased Credits',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits',
      title: 'Initial Purchased Credits',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'dollar_value_per_credit',
      title: 'Dollar Value (Initial Purchased Credits)',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'additional_recognised',
      title: 'Additional recognised',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'transfers',
      title: 'Transfers',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip',
      title: 'WIP',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent',
      title: 'Spent',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed',
      title: 'Committed',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_unspent_new',
      title: 'Remaining Credits (Unspent)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_new',
      title: 'Remaining Credits (Uncommitted)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_unspent_adjusted',
      title: 'Remaining Credits (Unspent, Inc. Transfers)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_adjusted',
      title: 'Remaining Credits (Uncommitted, Inc. Transfers)',
      className: 'dt-number',
      type: 'num'
    }
  ];

  adminReportsService.newLicenceReportSummaryColumns = [
    {
      name: 'Purchased Credits',
      source: 'purchased_credits'
    }, {
      name: 'Additional recognised',
      source: 'additional_recognised'
    }, {
      name: 'Transfers',
      source: 'transfers'
    }, {
      name: 'Committed',
      source: 'credits_committed'
    }, {
      name: 'Remaining Credits (Unspent)',
      source: 'credits_remaining_unspent_new'
    }, {
      name: 'Remaining Credits (Uncommitted)',
      source: 'credits_remaining_uncommitted_new'
    }, {
      name: 'Remaining Credits (Unspent, Inc. Transfers)',
      source: 'credits_remaining_unspent_adjusted'
    }, {
      name: 'Remaining Credits (Uncommitted, Inc. Transfers)',
      source: 'credits_remaining_uncommitted_adjusted'
    }
  ];

  adminReportsService.columnsWeeklyCreditReport = [
    {
      title: 'Licence Code',
      data: 'licence_code'
    },
    {
      data: 'status',
      title: 'Licence Status',
      render: function (data, type, row) {
        return data.toUpperCase().replace(/_/g, ' ');
      }
    },
    {
      data: 'expired',
      title: 'Expired/Closed',
      render: function (data, type, row) {
        return (data) ? 'Yes' : 'No';
      }
    },
    {
      data: 'account_managers',
      title: 'Engagement Manager(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'commercial_directors',
      title: 'Commercial Director(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      title: 'Start Date',
      data: 'from_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'End Date',
      data: 'to_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'Grace Period End Date',
      data: 'grace_period_end_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'Days remaining',
      data: 'days_remaining',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Licence Class',
      data: 'licence_class'
    },
    {
      data: 'purchased_credits',
      title: 'Total Purchased Credits',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits',
      title: 'Initial Purchased Credits',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'dollar_value_per_credit',
      title: 'Dollar Value (Initial Purchased Credits)',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'additional_recognised',
      title: 'Additional recognised',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'transfers',
      title: 'Transfers',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip_recognised',
      title: 'WIP (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent_recognised',
      title: 'Spent (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed_recognised',
      title: 'Committed (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'periodic_credits_spent_recognised',
      title: 'Week\'s Spent (Recognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'periodic_credits_expired',
      title: 'Week\'s Credit Expired',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_recognisable',
      title: 'Remaining Credits (Recognisable)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_expired',
      title: 'Credits expired',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: '% credits utilised',
      data: 'credits_utilised_percentage',
      className: 'dt-number',
      type: 'num',
      render: function (data, type, row) {
        return (data) ? (data + '%') : '';
      }
    },
    {
      data: 'credits_remaining_unspent_adjusted',
      title: 'Remaining Credits (Unspent, Adj.)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_adjusted',
      title: 'Remaining Credits (Uncommitted, Adj.)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'periodic_credits_spent_unrecognised',
      title: 'Week\'s Spent (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip_unrecognised',
      title: 'WIP (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent_unrecognised',
      title: 'Spent (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed_unrecognised',
      title: 'Committed (Unrecognised)',
      className: 'dt-number',
      type: 'num'
    }
  ];

  adminReportsService.columnsNewWeeklyCreditReport = [
    {
      title: 'Licence Code',
      data: 'licence_code'
    },
    {
      data: 'status',
      title: 'Licence Status',
      render: function (data, type, row) {
        return data.toUpperCase().replace(/_/g, ' ');
      }
    },
    {
      data: 'expired',
      title: 'Expired/Closed',
      render: function (data, type, row) {
        return (data) ? 'Yes' : 'No';
      }
    },
    {
      data: 'account_managers',
      title: 'Engagement Manager(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      data: 'commercial_directors',
      title: 'Commercial Director(s)',
      defaultContent: '-',
      render: function (data, type, row) {
        if (data != null) {
          return data.join(', ');
        }
        return '-';
      }
    },
    {
      title: 'Start Date',
      data: 'from_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'End Date',
      data: 'to_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'Grace Period End Date',
      data: 'grace_period_end_date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        return (data) ? $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
      }
    },
    {
      title: 'Days remaining',
      data: 'days_remaining',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Licence Class',
      data: 'licence_class'
    },
    {
      data: 'purchased_credits',
      title: 'Total Purchased Credits',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits',
      title: 'Initial Purchased Credits',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'dollar_value_per_credit',
      title: 'Dollar Value (Initial Purchased Credits)',
      className: 'dt-number',
      visible: false,
      type: 'num'
    },
    {
      data: 'additional_recognised',
      title: 'Additional recognised',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'transfers',
      title: 'Transfers',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_wip',
      title: 'WIP',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_spent',
      title: 'Spent',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_committed',
      title: 'Committed',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'periodic_credits_spent_new',
      title: 'Week\'s Spent',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_unspent_new',
      title: 'Remaining Credits (Unspent)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_new',
      title: 'Remaining Credits (Uncommitted)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_unspent_adjusted',
      title: 'Remaining Credits (Unspent, Inc. Transfers)',
      className: 'dt-number',
      type: 'num'
    },
    {
      data: 'credits_remaining_uncommitted_adjusted',
      title: 'Remaining Credits (Uncommitted, Inc. Transfers)',
      className: 'dt-number',
      type: 'num'
    }
  ];

  adminReportsService.newWeeklyCreditReportSummaryColumns = [
    {
      name: 'Purchased Credits',
      source: 'purchased_credits'
    }, {
      name: 'Additional recognised',
      source: 'additional_recognised'
    }, {
      name: 'Transfers',
      source: 'transfers'
    }, {
      name: 'WIP Credits',
      source: 'credits_wip'
    }, {
      name: 'Credits Spent',
      source: 'credits_spent'
    }, {
      name: 'Committed',
      source: 'credits_committed'
    }, {
      name: 'Week\'s Credits Spent',
      source: 'periodic_credits_spent_new'
    },{
      name: 'Remaining Credits (Unspent)',
      source: 'credits_remaining_unspent_new'
    }, {
      name: 'Remaining Credits (Uncommitted)',
      source: 'credits_remaining_uncommitted_new'
    }, {
      name: 'Remaining Credits (Unspent, Inc. Transfers)',
      source: 'credits_remaining_unspent_adjusted'
    }, {
      name: 'Remaining Credits (Uncommitted, Inc. Transfers)',
      source: 'credits_remaining_uncommitted_adjusted'
    }
  ];

  adminReportsService.columnsPayerQuickbooks = [
    {
      title: 'RPR ID',
      data: 'id',
      className: 'dt-number',
      type: 'num'
    },
    {
      title: 'Payer Full Name',
      data: 'full_name'
    },
    {
      title: 'Active Status',
      data: 'active',
      render: function (data, type, row) {
        return data ? 'Active' : 'Disabled';
      }
    },
    {
      title: 'Locked Status',
      data: 'locked',
      render: function (data, type, row) {
        return data ? 'Locked' : 'Open';
      }
    },
    {
      title: 'Quickbooks Id',
      data: 'quick_books_id'
    },
    {
      title: 'Quickbooks Vendor Name',
      data: 'quick_books_name'
    },
    {
      title: 'Payment Currency',
      data: 'payment_currency',
      render: function (data, type, row) {
        if (data) {
          return data.code + ' ' + data.name;
        } else {
          return 'N/A';
        }
      }
    }
  ];

  adminReportsService.columnsClientQuickbooks = [
    {
      title: 'Client Active Status',
      data: 'active',
      visible: false,
      render: function (data, type, row) {
        return data ? 'Active' : 'Disabled';
      }
    },
    {
      title: 'Client Locked Status',
      data: 'locked',
      visible: false,
      render: function (data, type, row) {
        return data ? 'Locked' : 'Open';
      }
    },
    {
      title: 'RPR ID of Client',
      data: 'id',
      className: 'dt-number',
      type: 'num',
      visible: false
    },
    {
      title: 'Client Name',
      data: 'name'
    },
    {
      title: 'Client ID',
      data: 'client_id'
    },
    {
      title: 'Client QuickBooks ID',
      data: 'quick_books_id'
    },
    {
      title: 'QuickBooks Customer Name',
      data: 'quick_books_name'
    },
    {
      title: 'RPR ID of Licence',
      data: 'subscription_id',
      visible: false
    },
    {
      title: 'Licence ID',
      data: 'subscription_licence_code'
    },
    {
      title: 'Licence QuickBooks ID',
      data: 'subscription_quick_books_id'
    },
    {
      title: 'QuickBooks Sub-Customer Name',
      data: 'subscription_quick_books_customer_name'
    }
  ];

  adminReportsService.columnsCreditTransactionHistory = [
    {
      data: 'event_datetime',
      title: 'Transaction Date',
      type: 'date',
      defaultContent: '',
      render: function (data, type, row) {
        if (type==='display') {
          return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_24H_TIME_NO_SEC);
        }
        let d = new Date(data);
        let utcDateTime = new Date(
          d.getUTCFullYear(),
          d.getUTCMonth(),
          d.getUTCDate(),
          d.getUTCHours(),
          d.getUTCMinutes()
        );
        return $filter('date')(utcDateTime, CONSTANTS.dateTimeFormat.MEDIUM_24H_TIME_NO_SEC);
      }
    },
    {
      data: 'client_code',
      title: 'Client'
    },
    {
      data: 'licence_code',
      title: 'Licence'
    },
    {
      data: 'project_code',
      title: 'Project'
    },
    {
      data: 'description',
      title: 'Transaction'
    },
    {
      data: 'credit_in',
      title: 'In',
      className: 'right aligned',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    },
    {
      data: 'credit_out',
      title: 'Out',
      className: 'right aligned',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    },
    {
      data: 'balance',
      title: 'Balance',
      className: 'right aligned',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    },
    {
      data: 'entered_by_full_name',
      title: 'Entered by'
    },
    {
      data: 'recognised_credit_in',
      title: 'Recognised In',
      className: 'dt-number',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    },
    {
      data: 'recognised_credit_out',
      title: 'Recognised Out',
      className: 'dt-number',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    },
    {
      data: 'recognised_balance',
      title: 'Recognised Balance',
      className: 'dt-number',
      type: 'num',
      render: function (data, type, row) {
        if (data != null) {
          return data.toLocaleString();
        }

        return '-';
      }
    }
  ];
}]
);
