app.directive('matProcessingAnimation', function () {
  return {
    templateUrl: '/html_templates/directives/animated_saving_button.4c942534.html',
    restrict: 'A',
    transclude: true,
    scope: {
      matProcessing: '=',
      matText: '@',
      matTextProcessing: '@'
    },
    link: function (scope, element, attrs, modelCtrl) {
    }
  };
});
