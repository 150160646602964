app.filter('steppedRange', function () {
  return function (input, min, max, step) {
    min = parseInt(min); 
    max = parseInt(max);

    for (var i = min; i < max; i = i + step) {
      input.push(i);
    }

    return input;
  };
});
