app.service('adminProjectBriefHelper',
  ['HttpAuth', '$routeParams', 'apiHelper', 'portalHelper', '$q',
    function (HttpAuth, $routeParams, apiHelper, portalHelper, $q) {
      var adminProjectBriefHelper = this;

      adminProjectBriefHelper.loadData = function ($scope, survey_id, cb_success) {
        var cur_survey_id = (survey_id) ? survey_id : $routeParams.id;

        var promises = [
          HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/survey/all/details/' + cur_survey_id + '.json'),
          HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/mat_user_active.json'),
          HttpAuth.get(apiHelper.getApiUrl() + '/api/payer_details_lists/')
        ];

        $q.all(promises).then(function (result) {
          $scope.survey = angular.copy(result[0].data);
          $scope.admin_users = angular.copy(result[1].data);
          $scope.payer_lists = angular.copy(result[2].data);

          $scope.survey.read_out_date = new Date($scope.survey.read_out_date);
          $scope.survey.start_date = new Date($scope.survey.start_date);
          if ($scope.survey.question_development_minimum_date) {
            $scope.survey.question_development_minimum_date = new Date($scope.survey.question_development_minimum_date);
          }

          var url_project = apiHelper.getApiUrl() + '/api/admin/project/' + $scope.survey.project_id + '.json';

          HttpAuth.get(url_project, {}).then(function (result2) {
            $scope.project = result2.data;
            $scope.data_loaded = true;


            if (cb_success) {
              cb_success();
            }
          }, function (result2) {
            portalHelper.showErrorCommon(result2);
          });
        }, function (result) {
          portalHelper.showErrorCommon(result);
        });
      };
    }]
);
