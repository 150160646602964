app.directive('associatedDocuments', ['portalHelper', 'authHelper', 'storageService',
  function (portalHelper, authHelper, storageService) {
    return {
      scope: {
        documents: '='
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/associated_documents.27fbd39d.html'),
      controller: function ($scope) {
        $scope.isLocalStorageSupported = storageService.isLocalStorageSupported;
        $scope.getUrl = portalHelper.getUrl;

        $scope.$watch('documents', function () {
          portalHelper.escapeDocumentNames($scope.documents);
        });
      }
    };
  }]
);
