app.directive('showDisclaimerQuestions', ['portalHelper', 'apiHelper', 'HttpAuth',
  function (portalHelper, apiHelper, HttpAuth) {
    return {
      restrict: 'E',
      scope: {
        disclaimer: '='
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/disclaimer_questions.21879d23.html'),
      controller: function ($scope) {


      }
    };
  }
]);
