app.service('surveyService', [
  'portalHelper', 'HttpAuth', 'apiHelper', '$location', 'CONSTANTS', '$timeout',
  function (portalHelper, HttpAuth, apiHelper, $location, CONSTANTS, $timeout) {
    var surveyService = this;
    var is_client_portal = $location.absUrl().indexOf('client/#/Client') != -1;

    surveyService.deleteSurvey = function (survey_id) {
      portalHelper.showPopupYesNo('Delete Survey?',
        'Are you sure you want to delete this Survey?',
        function () {
          var url = apiHelper.getApiUrl() + '/api/authoring/survey/' + survey_id + '.json';
          HttpAuth.delete(url).then(
            function (result) {
              if (is_client_portal) {
                $location.path('Client/SurveyDeleted');
              }
              else {
                $location.path('Admin/SurveyDeleted');
              }
            },
            function (result) {
              portalHelper.showErrorCommon(result);
            }
          );
        });
    };
  }
]);