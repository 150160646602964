app.service('creditChargeHistoryService', [ 'HttpAuth', 'portalHelper', 'apiHelper',
  function (HttpAuth, portalHelper, apiHelper) {
    var service = this;
    service.subscribers = {};

    service.publishCreditChargeHistory = function (surveyId) {
      return HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/survey/all/' + surveyId + '/charge_history.json').then(
        function (result) {
          if (service.subscribers[surveyId] && service.subscribers[surveyId].length) {
            service.subscribers[surveyId].forEach(function (callback) {
              if (typeof callback === 'function'){
                callback(result);
              }
            });
          }
          return result;
        }, portalHelper.showErrorCommon
      );
    };

    service.subscribeForCreditHistoryUpdates = function (surveyId, callback) {
      if (!service.subscribers[surveyId]) {
        service.subscribers[surveyId] = [];
      }
      service.subscribers[surveyId].push(callback);
    };

  }]);
