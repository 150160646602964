app.directive('tableEstimatedCredits', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      project: '=',
      survey: '=',
      admin: '@',
      collapsed: '@'
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/table_estimated_credits.f64004d4.html'),
    controller: function ($scope) {

      $scope.isAdmin = function () {
        return $scope.admin === 'true';
      };
      $scope.showMinimumCredits = function () {
        var survey_countries = [];

        if ($scope.isAdmin()) {
          survey_countries = $scope.survey.countries;
        } else {
          survey_countries = $scope.survey.survey_countries;
        }

        if (!survey_countries) {
          return;
        }

        for (var i = 0; i < survey_countries.length; i++) {
          if (survey_countries[i].estimated_question_count <= 5) {
            return true;
          }
        }
      };
    }
  };
}]);
