app.service('surveyAdminStatsService', ['$rootScope', 'HttpAuth', 'apiHelper', '$interval',
  function ($rootScope, HttpAuth, apiHelper, $interval) {
    var service = this;
    service.statsUrl = apiHelper.getApiUrl() + '/api/admin/stats/';

    service.pollSurveyStats = function () {
      $interval(
        function () {
          service.fetchStats();
        }
        , 30000);
    };

    service.fetchStats = function (invalidate) {
      var invalidate_cache = '';
      if (invalidate){
        invalidate_cache = '?invalidate=true';
      }
      HttpAuth.get(service.statsUrl + invalidate_cache ).then(
        function (result) {
          $rootScope.rootscope_header_surveyStats = result.data;
        }
      );
    };
  }]);


app.service('surveyClientStatsService', ['$rootScope', 'HttpAuth', 'apiHelper', '$interval',
  function ($rootScope, HttpAuth, apiHelper, $interval) {
    var service = this;
    service.statsUrl = apiHelper.getApiUrl() + '/api/client/survey/stats/';

    service.pollSurveyStats = function () {
      $interval(
        function () {
          service.fetchStats();
        }
        , 30000);
    };

    service.fetchStats = function () {
      HttpAuth.get(service.statsUrl).then(
        function (result) {
          $rootScope.rootscope_header_surveyStats = result.data;
        }
      );
    };
  }]);

app.service('surveyPayerStatsService', ['$rootScope', 'HttpAuth', 'apiHelper', '$interval', 'portalHelper',
  function ($rootScope, HttpAuth, apiHelper, $interval, portalHelper) {
    var service = this;
    service.statsUrl = apiHelper.getApiUrl() + '/api/payer/survey/stats/';

    service.pollSurveyStats = function () {
      $interval(
        function () {
          service.fetchStats();
        }
        , 30000);
    };

    service.fetchStats = function () {
      HttpAuth.get(service.statsUrl).then(
        function (result) {
          $rootScope.rootscope_header_surveyStats = result.data;
        }
      ).catch(portalHelper.showErrorCommon);
    };
  }]);


app.service('menuHeaderService', ['$rootScope', 'HttpAuth', 'apiHelper', 'portalHelper',
  function ($rootScope, HttpAuth, apiHelper, portalHelper) {
    var menuHeaderService = this;

    menuHeaderService.fetchPayers = function () {
      var payerListUrl = apiHelper.getApiUrl() + '/api/admin/payer.json?view_type=n';
      HttpAuth.get(payerListUrl).then(
        function (result) {
          $rootScope.rootscope_header_PayersList = result.data;

          $rootScope.rootscope_header_PayersList = $rootScope.rootscope_header_PayersList.filter(
            function (payer) {
              return payer.full_name && payer.active && !payer.locked;
            }
          );
          $rootScope.rootscope_header_PayersList = _.map($rootScope.rootscope_header_PayersList,
            function (payer) {
              return {
                'id': payer.id,
                'full_name': payer.full_name,
                'country_flag': payer.country.flag,
                'country_name': payer.country.name
              };
            }
          );
        }
      );
    };

    menuHeaderService.fetchClients = function () {
      var clientListUrl = apiHelper.getApiUrl() + '/api/admin/client/user.json';
      return HttpAuth.get(clientListUrl).then(
        function (result) {
          $rootScope.rootscope_header_ClientsList = result.data;

          $rootScope.rootscope_header_ClientsList = $rootScope.rootscope_header_ClientsList.filter(
            function (client) {
              return client.first_name && client.last_name && client.active && !client.locked;
            }
          );

          $rootScope.rootscope_header_ClientsList = _.map($rootScope.rootscope_header_ClientsList,
            function (client) {
              var updatedClientFields = {
                'id': client.id,
                'full_name' : client.first_name + ' ' + client.last_name,
                'country_name' : client.country_name,
                'country_flag' : client.country_flag
              };

              var updatedClient = Object.assign(client, updatedClientFields);
              return updatedClient;
            }
          );

          return $rootScope.rootscope_header_ClientsList;
        }
      );
    };

    menuHeaderService.fetchPayerExpertiseFormTypes = function () {

      if (menuHeaderService.payerExpertiseFormTypes) {
        return Promise.resolve(menuHeaderService.payerExpertiseFormTypes);
      }

      return HttpAuth.get(
        apiHelper.getApiUrl() + '/api/admin/payer_form.json'
      ).then(
        function (response) {
          menuHeaderService.payerExpertiseFormTypes = response.data;
          return menuHeaderService.payerExpertiseFormTypes;
        },
        function (error) {
          portalHelper.showErrorCommon(error);
        }
      );

    };


  }]);
