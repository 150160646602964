app.filter('renderDetailError', function () {
  return function (error) {
    var result = '<strong>' + error.errorMessage + '</strong>';

    if (error.detailMessage != null && error.detailMessage != '') {
      result += ' ' + error.detailMessage;
    }

    return result;
  };
});
