app.service('rankingHelper', ['dataConvert', '$filter', function (dataConvert, $filter) {
  this.initFakeRankingResponsesSaved = function (response, question) {
    response.ranking_responses_saved = [];
  };

  this.initFakeRankingResponsesNotSaved = function (response, question) {
    response.ranking_responses_not_saved = [];
    for (var i = 0; i < question.scale.options.length; i++) {
      var cur_option = question.scale.options[i];
      response.ranking_responses_not_saved.push(
        {
          'scale_option': cur_option.id,
          'option_name': cur_option.option_name,
          'sequence_number': cur_option.sequence_number
        }
      );
    }
  };
}]);
