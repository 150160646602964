app.directive('reportDocuments', ['portalHelper',
  function (portalHelper) {
    return {
      scope: {
        documents: '='
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/report_documents.32dce661.html'),
      controller: function ($scope) {
        $scope.downloadReport = portalHelper.downloadReport;
        $scope.getFileIconPath = portalHelper.getFileIconPath;
      }
    };
  }]
);
