app.service('payerHelpControllerData', function () {
  function get_default_help_data() {
    return {};
  }

  this.cur_help_data = get_default_help_data();
  this.set_data = function (data) {
    this.cur_help_data = angular.copy(data);
  };

  this.pop_data = function () {
    var ret = this.cur_help_data;
    this.cur_help_data = get_default_help_data();
    return ret;
  };
});
