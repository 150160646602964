app.directive('smallButton', ['portalHelper', function (portalHelper) {
  return {
    scope: {
      faIcon: '@',
      faIconClassName: '@',
      desc: '@',
      desc2: '@',
      descHide: '=',
      desc2Hide: '=',
      loaded: '=',
      title: '@',
      url: '@'
    },
    templateUrl: portalHelper.getUrlRev('html_templates/directives/small_button.11769ae2.html'),
    controller: function ($scope, $sce) {
      $scope.$watch('title', function (value) {
        $scope.titleAsHtml = $sce.trustAsHtml(value);
      });
      $scope.$watch('desc', function (value) {
        $scope.descAsHtml = $sce.trustAsHtml(value);
      });
      $scope.$watch('desc2', function (value) {
        $scope.desc2AsHtml = $sce.trustAsHtml(value);
      });
      $scope.goToDataUrl = function (url) {
        location.href = url;
      };
    }
  };
}]);
