app.service('historyService', [ 'sessionStorageService',
  function (sessionStorageService) {
    var service = this;
    var SESSION_STORAGE_KEY = '$visited_history';

    var IGNORE_LIST = [
      '/login/#/Login'
    ];

    service.add_to_history = function (url, title) {
      var ignoreEntry = false;
      IGNORE_LIST.forEach(function (elem) {
        if (url.toLowerCase().indexOf(elem.toLowerCase()) !== -1) {
          ignoreEntry = true;
        }
      });

      if (ignoreEntry) {
        return false;
      }

      var visitedHistory = sessionStorageService.get_item(SESSION_STORAGE_KEY);
      if (!visitedHistory) {
        visitedHistory = [];
      }

      if (visitedHistory.length > 0 && visitedHistory[visitedHistory.length - 1].url === url) {
        return true;
      }

      visitedHistory.push({
        url: url,
        title: title
      });

      sessionStorageService.set_item(SESSION_STORAGE_KEY, visitedHistory);
      return true;
    };

    service.fetch_from_recent_history = function (regexUrl, recentCount) {
      var visitedHistory = sessionStorageService.get_item(SESSION_STORAGE_KEY);
      if (visitedHistory == null || visitedHistory.length === 0) {
        return null;
      }

      if (regexUrl == '' || regexUrl == null) {
        return null;
      }

      var re = new RegExp(regexUrl, 'gi');

      var countTo = 0;
      if (recentCount != null && $.isNumeric(recentCount)) {
        countTo = Math.max(0, (visitedHistory.length - recentCount));
      }

      for (var i=(visitedHistory.length-1); i >= countTo; i = i-1) {
        var url = visitedHistory[i].url;
        if (url.match(re)) {
          return visitedHistory[i];
        }
      }

      return null;
    };
  }]
);
