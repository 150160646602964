app.controller('openDocumentController',
  ['$scope', '$routeParams', 'apiHelper', 'portalHelper', 'authHelper', 'HttpAuth',
    function ($scope, $routeParams, apiHelper, portalHelper, authHelper, HttpAuth) {

      portalHelper.portalReady(function () {
        var file_rel_url = '/api/document/' + $routeParams.id + '.json';
        HttpAuth.get(apiHelper.getApiUrl() + file_rel_url).then(
          function (result) {
            window.location.href = apiHelper.getApiUrl() + '/static/pdfjs/index.html?title='+ encodeURIComponent(result.data.filename) +'&file=' + encodeURIComponent(result.data.file);
          },
          portalHelper.showErrorCommon
        );
      });

    }]
);