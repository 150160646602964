app.directive('clientClarRequestControl', ['portalHelper', 'CONSTANTS', '$ngConfirm', 'apiHelper',
  'HttpAuth', function (portalHelper, CONSTANTS, $ngConfirm, apiHelper, HttpAuth) {
    return {
      scope: {
        question: '=',
        response: '=',
        clarRequests: '=',
        clarResponses: '=',
        loadData: '&',
        surveyDetails: '='
      },
      templateUrl: portalHelper.getUrlRev(
        'html_templates/directives/client_clar_request_control.html'),
      controller: function ($scope) {
        $scope.CONSTANTS = CONSTANTS;
        $scope.selected = {};
        $scope.follow_up_decision = function (clar_request, client_approves) {

          var url = apiHelper.getApiUrl() + '/api/client/survey/clarification/follow_up_decision/' +
            clar_request.id + '.json';

          var data = { 'client_approves': client_approves };
          HttpAuth.put(url, data).then(function (result) {
            $scope.loadData();
          },
          function (result) {
            portalHelper.showErrorCommon(result);
          });
        };

        $scope.approve_by_client = function (clar_response) {
          var url = apiHelper.getApiUrl() + '/api/client/survey/clar_response/approve/' + clar_response.id + '.json';
          HttpAuth.put(url, {}).then(
            function (result) {
              $scope.loadData();
            },
            function (result) {
              portalHelper.showErrorCommon(result);
            }
          );
        };

        $scope.close_request_clarification = function () {
          $scope.show_clar_request = false;
        };

        $scope.submit_request_clarification = function () {
          $scope.selected.clar_error = '';
          if (!$scope.selected.clar_context) {
            $scope.selected.clar_error = 'You must enter a response!';
            return false;
          }

          var data = {
            'context': $scope.selected.clar_context,
            'response': $scope.response.id
          };

          var url = apiHelper.getApiUrl() + '/api/client/survey/' + $scope.surveyDetails.id + '/clar_request.json';
          HttpAuth.post(url, data).then(function (result) {
            portalHelper.showPopupOK(
              'Submitted for review',
              'Your clarification request has been submitted for review',
              function () {
                $scope.loadData();
              }
            );
          },
          function (result) {
            portalHelper.showErrorCommon(result);
          });
        };

        $scope.request_clarification = function () {
          $scope.selected = {};
          $scope.show_clar_request = (!$scope.show_clar_request);
        };
      }
    };
  }]
);
