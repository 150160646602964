app.service('INTERCOM',
  ['SETTINGS', 'CONSTANTS', '$location', function (SETTINGS, CONSTANTS, $location) {
    var _this = this;

    _this.initForAnonym = function () {
      if ($location.path().indexOf('open_document/') !== -1) {
        return;
      }

      if (SETTINGS.INTERCOM_APP_ID) {
        var user_data = {
          alignment: 'left',
          horizontal_padding: 20,
          vertical_padding: 20,
          app_id: SETTINGS.INTERCOM_APP_ID
        };
        window.Intercom('boot', user_data);
      }
    };

    _this.initForUser = function (user, user_type_name) {
      if ($location.path().indexOf('open_document/') !== -1) {
        return;
      }

      if (SETTINGS.INTERCOM_APP_ID) {
        var user_data = {
          alignment: 'left',
          horizontal_padding: 20,
          vertical_padding: 20,
          app_id: SETTINGS.INTERCOM_APP_ID,
          'name': user.first_name + ' ' + user.last_name,
          'user_id': user.id
        };

        if (user.intercom_hash) {
          user_data['user_hash'] = user.intercom_hash;
        }

        if (CONSTANTS.matUserTypes.PAYER === user_type_name) {
          var has_port = ($location.port()) ? (':' + $location.port()) : '';
          var payer_url = $location.protocol() + '://' + $location.host() + has_port + '/#/Admin/Payer/Edit/' + user.id;

          user_data['phone'] = user.telephone;
          user_data['companies'] = [{ 'company_id': 'ZZZMAT001', 'name': 'MAT Payers' }];

          user_data['Payer Country'] = user.country_name;
          user_data['Payer Type'] = user.payer_type_display_name;
          user_data['Payer Status'] = 'Active';
          user_data['Functional Expertise'] = user.functional_expertises_names.join('; ');
          user_data['Experiential Expertise'] = user.experiential_expertises_names.join('; ');
          user_data['View Payer Details'] = payer_url;
          user_data['Mobile Phone Number'] = user.mobile;
        } else if (CONSTANTS.matUserTypes.ADMIN === user_type_name) {
          user_data['companies'] = [{ 'company_id': 'ZZZMAT000', 'name': 'MAT Admin' }];
        } else if (CONSTANTS.matUserTypes.CLIENT === user_type_name) {
          user_data['companies'] = [{ 'company_id': user.client_id, 'name': user.client_name }];
        }

        window.Intercom('boot', user_data);
      }
    };
  }]
);
