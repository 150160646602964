app.directive('mixpanelTrack',['$mixpanel', '$parse', function($mixpanel, $parse) {
  return {
    link: function(scope, element, attrs) {
      element.on(attrs.mixpanelTrack, function(event) {
        var eventName = attrs.mixpanelEvent || 'Click';
        var eventData = scope.$eval(attrs.mixpanelData) || {};
        $mixpanel.track(eventName, eventData);
      });
    }
  };
}]);

