app.directive('confirmOnExit',
  ['$location', 'CONSTANTS', 'confirmOnExitService', function ($location, CONSTANTS, confirmOnExitService) {
    return {
      require: '^form',
      link: function ($scope, formElement, attrs, formCtrl) {
        if (formElement[0].tagName !== 'FORM' && !formElement[0].hasAttribute('ng-form')) {
          throw ('unsavedWarningForm must be inside a form element');
        }

        confirmOnExitService.addForm(formCtrl);

        $scope.$on('$destroy', function () {
          confirmOnExitService.removeForm(formCtrl);
        });
      }
    };
  }]
);
