app.directive('surveyProgress',
  ['$location', 'CONSTANTS', 'portalHelper', function ($location, CONSTANTS, portalHelper) {
    return {
      scope: {
        survey: '=',
        detailedSteps: '@',
        mode: '@'
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/survey_progress.5218d11d.html'),
      controller: function ($scope) {

        $scope.CONSTANTS = CONSTANTS;
        $scope.all_steps = [1, 2, 3, 4, 5, 6];
        $scope.steps_title = {
          '1': 'Scoping',
          '2': 'Question Dev',
          '3': 'Compliance',
          '4': 'Fielding and Analysis',
          '5': 'Follow Up',
          '6': 'Delivered'
        };

        switch ($scope.survey.status) {
          case CONSTANTS.surveyStatuses.SCOPING:
            $scope.cur_step = 1;
            break;
          case CONSTANTS.surveyStatuses.QUESTION_DEVELOPMENT:
            $scope.cur_step = 2;
            break;

          case CONSTANTS.surveyStatuses.COMPLIANCE:
            $scope.cur_step = 3;
            break;

          case CONSTANTS.surveyStatuses.FIELDING_AND_ANALYSIS:
            $scope.cur_step = 4;
            break;

          case CONSTANTS.surveyStatuses.FOLLOW_UP:
            $scope.cur_step = 5;
            break;

          case CONSTANTS.surveyStatuses.DELIVERED:
            $scope.cur_step = 6;
            break;
        }
      }
    };
  }]
);
