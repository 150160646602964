app.service('storageService', ['$window', function ($window) {
  var storageService = this;
  var fake_storage = {};

  storageService.isLocalStorageSupported = function () {
    try {
      var storage = $window.localStorage;
      return !!storage.getItem;
    }
    catch (e) {
      return false;
    }
  };

  storageService.getLocalStorage = function () {
    if (storageService.isLocalStorageSupported()) {
      return $window.localStorage;
    } else {
      return fake_storage;
    }
  };

  storageService.clearLocalStorage = function () {
    if (!storageService.isLocalStorageSupported()) {
      fake_storage = {};
      return;
    }

    var storage = storageService.getLocalStorage();

    for (var key in storage) {
      if (typeof storage[key] !== 'object' && typeof storage[key] !== 'string') {
        continue;
      }

      if (key === 'intercom-state' || key == 'testApiServer') {
        continue;
      }

      try {
        var object = JSON.parse(storage[key]);

        if ('expires_on' in object && new Date(object['expires_on']) > new Date()) {
          continue;
        }
      }
      catch (e) {
      }

      delete storage[key];
    }
  };
}]);
