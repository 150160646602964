app.filter('utcToLocal', function utcToLocal($filter) {
  return function (utcDateString, format) {
    if (!utcDateString) {
      return;
    }

    if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
      utcDateString += 'Z';
    }

    return $filter('date')(utcDateString, format);
  };
});
