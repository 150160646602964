app.service('conversationBoxService', [ 'CONSTANTS', 'portalHelper',
  function (CONSTANTS, portalHelper) {
    var service = this;
    service.USER_TYPE_ENUM = CONSTANTS.matUserTypes;

    service.createThread = function (nameOfUser, timestamp, typeOfUser, text) {
      return {
        name: nameOfUser,
        timestamp: timestamp,
        user_type: typeOfUser,
        text: text
      };
    };
  }
]);
