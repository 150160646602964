app.directive('fileread', [function () {
  return {
    scope: {
      filedata: '=',
      filename: '='
    },
    link: function (scope, element, attributes) {
      element.bind('change', function (changeEvent) {
        var reader = new FileReader();

        function _arrayBufferToBase64(buffer) {
          var binary = '';
          var bytes = new Uint8Array(buffer);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          return window.btoa(binary);
        }

        reader.onload = function (loadEvent) {
          scope.$apply(function () {
            scope.filedata = _arrayBufferToBase64(loadEvent.target.result);
          });
        };

        reader.readAsArrayBuffer(changeEvent.target.files[0]);
        scope.filename = changeEvent.target.files[0].name + '';
      });
    }
  };
}]);
