
app.directive('onlyDecimalDigits', function () {
  return {
    scope: {
      decimalDigitsAllowed: '='
    },
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        if (inputValue == undefined) {
          return '';
        }

        if (attrs.skipdecimaldigits == 'true') {
          return inputValue;
        }

        var filteredInput = inputValue.replace(/[^0-9.]/g, '');
        var regex = new RegExp('^[0-9]+.?[0-9]{0,' + (scope.decimalDigitsAllowed || 2) + '}');
        var match = filteredInput.toString().match(regex);
        var transformedInput = '';

        if (match) {
          transformedInput = match[0];
        }

        if (transformedInput !== inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }

        return transformedInput;
      });
    }
  };
});
